
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import './AppDesign.css';
import Cart from './components/cart/cart';
import Favorites from './components/favorites/favorites';
import Navbar from './components/sidebar/sidebar';

import LoginForm from './components/forms/loginForm';
import NewAccountForm from './components/forms/NewAccountForm';
import ResetPassword from './components/forms/resetPassword';

import Managment from './components/managment/managment';
import Menu from './components/menu/menu';
import UserDetails from './components/user/userDetails';
import Homepage from './components/home/home';
import ProductView from './components/menu/components/productView';
import Confirm from './components/cart/components/confirmPayment';
import UserOrders from './components/user/components/userOrders';
import NewOrderForm from './components/managment/components/NewOrder';
import NewProductForm from './components/managment/components/menu/NewProduct';
import UserCartView from './components/managment/components/user/userCartView';
import MenuEdit from './components/managment/components/menu/menu';
import CategoryEdit from './components/managment/components/menu/category';
import ProductEdit from './components/managment/components/menu/productEdit';
import NewEventForm from './components/managment/components/event/newEvent';
import Office from './components/office/office';
import Bar from './components/bar/bar';
import MenuEventEdit from './components/managment/components/event/menuEvent';
import EventEdit from './components/managment/components/event/eventEdit';
import Worker from './components/worker/worker';
import PayBox from './components/cart/components/PayBox';
import AboutUs from './components/aboutUs/aboutUs';
import NewCategoryForm from './components/managment/components/menu/NewCategory';
import NewStorageForm from './components/managment/components/menu/NewStorage';
import StorageEdit from './components/managment/components/menu/StorageEdit';
import EView from './components/home/components/eventPage';
import BarControl from './components/worker/components/barControl';
import OfficeEventScan from './components/office/officeEventScan';
import homeEvents from './components/home/homeEvents';
import { WeelOfLuck } from './components/weelOfLuck/weelofluck';
import wheelHome from './components/weelOfLuck/wheelHome';
import wheelInfo from './components/weelOfLuck/wheelInfo';
import { useState } from 'react';
import back from './image/back.png'
import { useEffect } from 'react';
import Terms_and_conditions from './components/aboutUs/terms_and_conditions';
import PaymentDialog from './components/cart/components/paymentDialog';

export default function App() {


  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [touchPosition, setTouchPosition] = useState("");
  const handleTouchStart = (e) => {
    const screenWidth = window.innerWidth
    const touchStart = e.touches[0].clientX
    if (touchStart >= (screenWidth / 3)*2) {
      setTouchPosition(touchStart)
    } else {
      setTouchPosition(null)
    }
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 10) {
      showSidebar()
    }
    if (diff < -10) {
      // showSidebar()
    }
    setTouchPosition(null)
  }
  const [isShowNav, setShowNav] = useState(false)
  const [isShowBackE, setShowBackEvent] = useState(false)
  const [isShowBackM, setShowBackMenu] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      let x = String(window.location.pathname)
      setShowNav(!x.includes("managment") && !x.includes("office") && !x.includes("bar"))
      setShowBackEvent(x.includes("/event/"))
      setShowBackMenu(x.includes("/menu/"))
    }, 500);
  }, []);



  return (
    <>
      <Router>
        <Switch>
          <div id="page-master"
            onTouchStart={isShowNav ? handleTouchStart : ""}
            onTouchMove={isShowNav ? handleTouchMove : ""}
          >
            {isShowBackE &&
              <Link to='/events' className='menu-bars-back'>
                <img src={back} className='menu-bars-back-img' width={50} alt="logo" />
                {/* <ArrowBendDoubleUpRight color={"#996633"} /> */}
              </Link>}
            {isShowBackM &&
              <Link to='/menu' className='menu-bars-back'>
                <img src={back} className='menu-bars-back-img' width={50} alt="logo" />
                {/* <ArrowBendDoubleUpRight color={"#996633"} /> */}
              </Link>}

            {isShowNav &&
              <Navbar isOpen={sidebar} />}

            <Route exact path="/"
              component={Homepage} />
            <Route exact path="/home"
              component={Homepage} />
            <Route exact path="/menu"
              component={Menu} />
            <Route exact path="/lucky"
              component={wheelHome} />
            <Route exact path="/luckyhow"
              component={wheelInfo} />
            <Route exact path="/menu/:num"
              component={ProductView}
            />
            <Route exact path="/events"
              component={homeEvents} />
            <Route exact path="/event/:num"
              component={EView}
            />

            <Route exact path="/eventmanage"
              component={OfficeEventScan} />

            <Route exact path="/worker"
              component={Worker} />

            <Route exact path="/worker/openbar"
              component={() => <BarControl type={"open"} />} />

            <Route exact path="/worker/closebar"
              component={() => <BarControl type={"close"} />} />
            <Route exact path="/favorites"
              component={Favorites} />

            <Route exact path="/personal"
              component={UserDetails} />
            <Route exact path="/personal/:num"
              component={UserOrders}
            />

<Route exact path="/cart"
              component={Cart} />

<Route exact path="/payment"
              component={PaymentDialog} />

            <Route exact path="/cart/pay"
              component={Confirm} />
            <Route exact path="/cart/paybox"
              component={PayBox} />
            <Route exact path="/about"
              component={AboutUs} />
                <Route exact path="/terms_and_conditions"
              component={Terms_and_conditions} />

            <Route exact path="/register"
              component={NewAccountForm} />
            <Route exact path="/login"
              component={LoginForm} />
            <Route exact path="/reset"
              component={ResetPassword} />

            <Route exact path="/office"
              component={Office} />
            <Route exact path="/bar"
              component={Bar} />
            <Route exact path="/xmanagment"
              component={Managment} />
            <Route exact path="/xmanagment/newproduct"
              component={NewProductForm} />
            <Route exact path="/xmanagment/newitem"
              component={NewStorageForm} />
            <Route exact path="/xmanagment/newcategory"
              component={NewCategoryForm} />
            <Route exact path="/xmanagment/newevent"
              component={NewEventForm} />
            <Route exact path="/xmanagment/eventedit"
              component={MenuEventEdit} />
            <Route exact path="/xmanagment/eventedit/:num"
              component={EventEdit} />
            <Route exact path="/xmanagment/neworder/:num"
              component={NewOrderForm} />
            <Route exact path="/xmanagment/cart/:num"
              component={UserCartView} />
            <Route exact path="/xmanagment/menuedit"
              component={MenuEdit} />
            <Route exact path="/xmanagment/inventoryedit"
              component={StorageEdit} />
            <Route exact path="/xmanagment/storageedit"
              component={StorageEdit} />
            <Route exact path="/xmanagment/categoryedit"
              component={CategoryEdit} />
            <Route exact path="/xmanagment/menuedit/:num"
              component={ProductEdit} />

          </div>

        </Switch>
      </Router>

    </>
  );
}
