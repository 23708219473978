import React, { useCallback, useState } from 'react'
import { useHistory } from "react-router-dom";
import FavoriresView from './components/menuFavorite';
import '../../App.css'
import CouponsView from './components/menuCoupons';
import CartButton from '../cartButton';
import header from '../../image/favorite.png'
import Navbar from '../sidebar/sidebar';
import { useEffect } from 'react';
import { auth, database } from '../../firebase';
import { onValue, ref } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useMemo } from 'react';
import { RingLoader } from 'react-spinners';
import FavoriteCard from './components/FavoriteCard';
import TicketCard from './components/TicketCard';



function Favorites() {

    const [user, loading] = useAuthState(auth);
    const [menu1, setMenu1] = useState(true);
    const [menu2, setMenu2] = useState(true);
    const history = useHistory();
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [tickets, setTickets] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [loading1, setloading1] = useState(true);
    const [loading2, setloading2] = useState(true);
    const [loading3, setloading3] = useState(true);

    const [touchPosition, setTouchPosition] = useState("");


    useMemo(() => {
        if (user) {
            console.log("******useMemo-Tickets************")
            const query = ref(database, "Tickets");
            return onValue(query, (snapshot) => {
                setTickets([])
                const data = snapshot.val();
                if (snapshot.exists()) {
                    Object.values(data).map((project) => {
                        console.log(project.userUid, user.uid)
                        if (project.userUid === user.uid)
                            setTickets((projects) => [...projects, project]);
                        // return project;
                    });
                }
                console.log(tickets)
                setloading1(false)
            });
        }
    }, [user]);


    useMemo(() => {
        if (user) {
            console.log("******useMemo-Favorite************")
            const query = ref(database, "Favorite");
            return onValue(query, (snapshot) => {
                setFavorites([])
                const data = snapshot.val();
                if (snapshot.exists()) {
                    Object.values(data).map((project) => {
                        console.log(project.uid, user.uid)
                        if (project.uid === user.uid)
                            setFavorites((projects) => [...projects, project]);
                        return project;
                    });
                }
                console.log(tickets)
                setloading2(false)
            });
        }
    }, [user]);


    useMemo(() => {
        if (user) {
            console.log("******useMemo-Coupons************")
            const query = ref(database, "Coupon");
            return onValue(query, (snapshot) => {
                setCoupons([])
                const data = snapshot.val();
                if (snapshot.exists()) {
                    Object.values(data).map((project) => {
                        console.log(project.uid, user.uid)
                        if (project.uid === user.uid)
                            setCoupons((projects) => [...projects, project]);
                        return project;
                    });
                }
                console.log(tickets)
                setloading3(false)
            });
        }
    }, [user]);

    return (
        <>
                <div id="page-wrap" className='top_padding background'>
                    {/* <img src={header} className="header-logo" alt="logo" /> */}
                    <span className="text_title_img text3 " >הפריטים שלי</span>
                    <br />
                    {(loading1 === true) ? (
                        <center id="loading" >
                            <RingLoader id="loading" color={"#a57733"} />
                            <br /><br />
                        </center>
                    ) : (<>
                        <br /><br />
                        {tickets.length > 0 &&
                            <span className="text_center text_dark text3 w300 underline" >כרטיסים לאירועים:</span>
                        }
                        {/* {tickets.length === 0 &&
                            <h3>לא נמצאו כרטיסים</h3>} */}
                        {tickets.map((products, i) => (
                            <TicketCard
                                Edate={products.eventDate}
                                EName={products.eventName}
                                ETicket={products.eventTickets}
                                EUid={products.eventUid}
                                total={products.total}
                                key={products.uid}
                                uid={products.uid}
                                status={products.status}
                                Ticket={products}
                            />
                        ))}
                    </>)}

                    {(loading2 === true) ? (
                        <center id="loading" >
                            <RingLoader id="loading" color={"#a57733"} />
                            <br /><br />
                        </center>
                    ) : (<>
                        <span className="text_center text_dark text3 w300 underline" >מועדפים:</span>
                        {favorites.length === 0 &&
                            <h3>אין מוצרים מועדפים</h3>}
                        {favorites.map((products, i) => (
                            <FavoriteCard
                                id={products.keyUid}
                                idproduct={products.idproduct}
                                des={products.detail}
                                img={products.picture}
                                name={products.pname}
                                price={products.price}
                                amount={products.amount}
                                status="on"
                                title="Favorite"
                            />
                        ))}
                    </>)}

                    {(loading3 === true) ? (
                        <center id="loading" >
                            <RingLoader id="loading" color={"#a57733"} />
                            <br /><br />
                        </center>
                    ) : (<>
                        {coupons.length > 0 &&
                            <span className="text_center text_dark text3 w300 underline" >קופונים:</span>}
                             {coupons.length == 0 &&
                            <><br/><br/></>}
                        {/* {coupons.length === 0 &&
                            <h3>לא נמצאו קופונים</h3>} */}
                        {coupons.map((products, i) => (
                            <FavoriteCard
                                id={products.keyUid}
                                idproduct={products.idproduct}
                                des={products.detail}
                                img={products.picture}
                                name={products.pname}
                                price={products.price}
                                amount={products.amount}
                                status={products.status}
                                title="Coupon"
                            />
                        ))}
                    </>)}
                </div>

        </>
    );
}

export default Favorites
