
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database, db, sendMessage, updateVersion, version } from '../firebase';
import line from '../image/line.png'
import Container from './aboutUs/components/container';
import { CopyrightOutlined } from '@mui/icons-material';
import { onValue, ref } from 'firebase/database';
import { FaFacebook, FaInstagram, FaWaze, FaWhatsapp } from 'react-icons/fa';

function ButtomPage() {
    const [user, loading] = useAuthState(auth);
    const [account, setAccount] = useState("");

    useEffect(() => {
        // updateVersion("2.60")
        async function fetchUserName() {
            const query = ref(database, "Account/" + user.uid);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setAccount(data);
                }
            }, {
                onlyOnce: true
            });
        };
        if (loading) return;
        if (user) {
            fetchUserName();
        }
    }, [user, loading]);

    const onSubmit = (event) => {
        console.log("send message");
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage(account.uid, account.name, account.email, account.phone, title, des);
        }
    };
    const onSubmitNew = (event) => {
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        let name = (event.target.username.value);
        let email = (event.target.useremail.value);
        let phone = (event.target.userphone.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage("NoAccount", name, email, phone, title, des);
        }
    };

    return (
        <>

            <img src={line} className="line_png" alt="logo" />

            <div id="content">
                <div id="right">
                    <div className='text_brown text_sub text_rtl text1_3 w300'>
                    <a className=' text2 w300 text_title'>
                        יש לכם רעיון?
                    </a>

                        <br />
                        רוצים להציע לנו משהו, או סתם התגעגעתם?
                    </div>
                    <div className='text_brown text_rtl text2 w600'>
                        כתבו לנו!
                    </div>
                </div>
                <div id="left">

                    {user &&
                        <Container triggerText={"כתבו לנו"} onSubmit={onSubmit}></Container>
                    }
                    {!user &&
                        <Container kind="new" triggerText={"כתבו לנו"} onSubmit={onSubmitNew}></Container>
                    }

                </div>
            </div>
            <img src={line} className="line_png" alt="logo" />
            <br />
            <div className='text_brown text_eng text2 w300 p3'>
                follow us on social media!
            </div>
            <a href="https://chat.whatsapp.com/BlpNfOeHCEh7o8J7QH4UGN" target="_blank" rel="noreferrer" className='link_site'>
                <FaWhatsapp /></a>
            <a href="https://instagram.com/pub_givon?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" className='link_site'>
                <FaInstagram /></a>
                <a href="https://www.facebook.com/profile.php?id=100076230830457" target="_blank" rel="noreferrer" className='link_site'>
                <FaFacebook /></a>
                <a href="https://www.waze.com/live-map/directions?to=ll.31.8487%2C35.157645" target="_blank" rel="noreferrer" className='link_site'>
                <FaWaze /></a>

            <img src={line} className="line_png" alt="logo" />
            <div className='text_brown text_eng text1 padding10'>
                copyright <CopyrightOutlined fontSize='15' /> 2022 Pub Givon | v.{version}
            </div>
            <br />
        </>
    )
}

export default memo(ButtomPage)
