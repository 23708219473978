
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { addCoupon, auth, database, db, sendMessage, signInWithGoogle, upladNewUser, version } from '../../firebase';
import line from '../../image/line.png'
import Navbar from '../sidebar/sidebar';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import Container from '../aboutUs/components/container';
import MenuSmall from '../menu/menuSmall';
import EBox from './components/eventsHandler';
import pic24_7 from '../../image/icon247.png'
import picone from '../../image/iconone.png'
import ButtomPage from '../buttomPage';
import { onValue, ref } from 'firebase/database';
import { Button } from '@material-ui/core';
import { BiLeftArrow } from 'react-icons/bi';
import { ArrowLeft } from 'phosphor-react';
import ContributionBox from './components/contributionBox';
import EPage from './components/eventsPage';

function HomeEvent() {
    const history = useHistory();
    const [user, loading] = useAuthState(auth);
    const [account, setAccount] = useState([]);
    const [status, setStatus] = useState("");
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [touchPosition, setTouchPosition] = useState("");
    const [flag, setFlag] = useState(false);


    let nicepic = "";
    var randomIndex = (Math.random())
    if (randomIndex < 0.3)
        nicepic = "nicepic1"
    else if (randomIndex > 0.6)
        nicepic = "nicepic2"
    else
        nicepic = "nicepic3"
    useEffect(() => {
        localStorage.clear()
    }, []);

    useEffect(() => {
        if (loading) return;
        if (!user) return;
        const query = ref(database, "Account/" + user.uid);
        return onValue(query, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                console.log(data)
                console.log(data.uid)
                if (!data.uid)
                    setFlag(true)
                else
                    setAccount(data);
            }
            else setFlag(true)
        })
    }, [user, loading]);

    useEffect(() => {
        async function fetchUserName() {
            try {
                const q = query(collection(db, "Users"), where("uid", "==", user?.uid));
                const doc = await getDocs(q);
                if (doc.docs.length > 0) {
                    const data = doc.docs[0].data();
                    if (flag)
                        setAccount(data);
                }
                else {
                    alert("מכניס חדש למערכת")
                    // addCoupon("צ'ייסר", 0, 1, user.uid, "מתנה לנרשמים חדשים", "", "off")
                    await addDoc(collection(db, "Users"), {
                        uid: user.uid,
                        name: user.displayName,
                        authProvider: "homePage",
                        email: user.email,
                        birthDate: "",
                        adress: "",
                        phone: "",
                        account: "newAccount",
                        tax: "false",
                        payBox: "false",
                        discount: 0,
                    }).then(addCoupon("צ'ייסר", 0, 1, user.uid, "מתנה לנרשמים חדשים", "", "off")).then
                        (alert("מחכה לך קופון באיזור הפריטים שלי"));
                }
            } catch (err) {
                console.log(err);
            }
        };
        if (loading) return;
        if (user && flag) {
            fetchUserName();
        }
    }, [flag]);


    useEffect(() => {
        if (flag === true) {
            upladNewUser(account);
        }
    }, [account])

    const onSubmitWelcome = (event) => {
        console.log("onSubmitWelcome", event);
        if (event === "givon") {
            history.push("login")
            console.log(event);
            window.scrollTo(0, 0)
        }
        if (event === "visit") {
            history.push("register")
            console.log(event);
            window.scrollTo(0, 0)
        }
        if (event === "google") {
            if (!user) {

                console.log(event);
                window.scrollTo(0, 0)
                signInWithGoogle()
            }
        }


    };



    const toMenu = () => {
        history.push("/menu")
        window.scrollTo(0, 0)
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
        if (diff > 10) {
            showSidebar()
        }
        if (diff < -5) {
            // showSidebar()
        }
        setTouchPosition(null)
    }

    useEffect(() => {
        const query = ref(database, "bar");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            setStatus(data.status)
        }, {
            onlyOnce: true
        });
    }, []);

    return (
        <>
                <div id="page-wrap" className='background_white top_padding'>
                    {/*
                    {/* <img src={wallpaper} className="welcome_image" alt="logo" /> */}

                    {/* <div className="div_master"> */}
                    {/* <img src={header} className="width70" alt="logo" /> */}
                    {/* </div> */}
                    {!user &&
                        <Container triggerText={"Welcome"} onSubmit={onSubmitWelcome} kind={"welcome"}></Container>

                        // <div className="home_button">
                        //     {/* <div id="right_welcome"> */}
                        //     <br /> <br />
                        //     <button className="welcome_button"
                        //         onClick={register}>הרשמה
                        //     </button>
                        //     <br />
                        //     <br />
                        //     <button className="welcome_button"
                        //         onClick={login}>כניסה
                        //     </button>
                        //     <br /> <br />

                        //     <div className='text_black text1_5 w500'>התחבר באמצעות גוגל   </div>

                        //     <button className="welcome_button_google"
                        //         onClick={signInWithGoogle}> <AiOutlineGoogle />
                        //     </button>

                        // <br /> <br />
                        // </div>
                    }
                    <center>
                        <EPage />
                    </center>

                    {/* <img src={line} className="line_png" alt="logo" />
                    <br />
                    <div className='split_div padding10'>
                        <div className='left_div'>
                            <img className="logo_home_bottom" src={picone} />
                            <div className='text_brown text1_5 w600 p3'>מספר 1</div>
                            <div className='text_brown text_sub text1_2 w400 p1 '>הפאב הכי טוב בגבעון</div>
                        </div>
                        <div className='right_div'>
                            <img className="logo_home_bottom" src={pic24_7} />
                            <div className='text_brown text1_5 w600 p3'>24/7 תמיכה</div>
                            <div className='text_brown text_sub text1_2 w400 p1'>אנחנו תמיד כאן בשביל בשבילכם</div>
                        </div>
                    </div> */}
                    <br /><br />
                    <ButtomPage />
                </div>
        </>
    )
}

export default memo(HomeEvent)
