import React, { memo, useEffect, useState } from 'react';
import '../../forms/form.css';
import { Link, useHistory } from "react-router-dom";
import { auth, database, db, sendMessage, updateUserPayBox, updateUserTax, uploadPaymentToCheck } from '../../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import RingLoader from "react-spinners/RingLoader";
import payboxlogo from '../../../image/payBox.png';
import givonlogo from '../../../image/givonlogo.png';
import MessagesButton from './MessagesButton';
import { onValue, ref, update } from 'firebase/database';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import Container from '../../aboutUs/components/container';
import { BiCoin } from 'react-icons/bi';
import { BsCoin } from 'react-icons/bs';
import { CoinVertical } from 'phosphor-react';
import { GiPointySword, GiTwoCoins } from 'react-icons/gi';
import  Gcoin  from "../../../image/Gcoin1.png";
import Wheelbutton from '../../weelOfLuck/wheelbutton';

function DetailsForm() {

  const history = useHistory();

  const [payBox, setPayBox] = useState("");
  const [tax, setTax] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adress, setAdress] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [balance, setBalance] = useState(0);
  const [points, setPoints] = useState(0);
  const [balanceAdd, setBalanceAdd] = useState(0);
  const [change, setChange] = useState(true);
  const [loading1, setLoading1] = useState(true);

  const UpdateUserInfo = async () => {
    var reference = ref(database, 'Account/' + user.uid);
    update(reference, {
      name: name,
      adress: adress,
      phone: phone,
      birthDate: birthDate,
      tax: "false",
      account: "newAccount",
    }).then(alert("פרטיך עודכנו במערכת, החשבון הועבר לבדיקה"));
  }

  useEffect(() => {
    if (loading) return;

    async function fetchUserName() {
      const query = ref(database, "Account/" + user.uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data)
          setName(data.name);
          setEmail(data.email);
          setPhone(data.phone);
          setAdress(data.adress);
          setBirthDate(data.birthDate);
          setUid(data.uid);
          setPayBox(data.payBox);
          setTax(data.tax);
          if (data.accountBalance) {
            setBalance(data.accountBalance)
            data.accountBalance > 0 ? setBalanceAdd(data.accountBalance) : setBalanceAdd(data.accountBalance * -1)
          }
          setPoints(data.points ? data.points : 0);
          setLoading1(false);
        }
      });
    };
    if (user)
      fetchUserName();
  }, [user, loading, change]);

  const update2 = () => {
    handleClose3()
    UpdateUserInfo(uid, name, birthDate, adress, phone)
  }
  const watch = () => {

    history.push("personal/" + (user.uid))

  }
  // const deleteLocalStorage = () => {
  //   localStorage.clear();
  //   alert("זכרון נמחק")
  // }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = useState(false);

  const handleClickOpen3 = () => {
    if (!name) alert("לא הוכנס שם");
    else if (!phone) alert("לא הוכנס טלפון");
    else if (!adress) alert("לא הוכנסה כתובת");
    else if (!birthDate) alert("לא הוכנס תאריך לידה");
    else
      setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const payboxx = () => {
    updateUserPayBox(user.uid, "block");
    setChange(!change)
    handleClose2();
    handleClose1();
  };
  const taxx = () => {
    updateUserTax(user.uid, "block");
    setChange(!change)
    handleClose2();
    handleClose1();
  };

  const onApproval = () => {
    // removeCommand();
    console.log("amount", balanceAdd)
    if (balanceAdd > 0) {
      uploadPaymentToCheck(user.uid, name, email, parseInt(balanceAdd))
      window.location.assign('https://payboxapp.page.link/tMUCWvfnFRsiFeHV9');
    }
    else
      alert("הוזן סכום לא תקין")
    handleClose();
  };

  const onSubmit = (event) => {
    console.log("send message");
    event.preventDefault(event);
    let title = (event.target.messagetitle.value);
    let des = (event.target.messagetext.value);
    if (title === "" || des === "") {
      alert("אחד הפרטים ריק")
    }
    else {
      sendMessage(user.uid, name, email, phone, title, des);
    }
  };


  return (
    <center>
      <br />
      <div id="personal-wrap">
        {loading1 === true &&
          <center>
            <RingLoader id="loading" color={"#a57733"} />
            <h3>יש להתחבר תחילה למערכת</h3>
          </center>}
        {loading1 === false &&
          <>

            <MessagesButton />
            <br />

            {points >= 0 && <div id="personal-wrap">
              <a className="text text2 w500">נקודות:  <a className="text_brown">{points.toFixed(0)} 
              <img src={Gcoin} className="gcoin" alt="logo" />
              </a> | גלגל המזל: <Wheelbutton /></a>
              <br />
            </div>
            }

            {balance < 0 && <>
              <a className="text text2 w500">יתרת חשבון אישי:  <a className="text_red">{balance.toFixed(2)} ₪  <br />נא להסדיר את התשלום בפייבוקס<br /></a></a>
              <center>
                {/* <a href="https://payboxapp.page.link/tMUCWvfnFRsiFeHV9" target="_blank" rel="noreferrer"> */}
                <button id="paybox-title" className=" text1_5 w400 p4 " onClick={handleClickOpen}>מעבר לאפליקציית
                  <img src={payboxlogo} width={100} alt="logo" /></button><br /></center>
              <br />
            </>
            }
            {balance >= 0 && payBox === "true" && <>
              <a className="text text2 w500">יתרת חשבון אישי: <a className="text_green">{balance.toFixed(2)} ₪ </a> <a className=" text1_5"><br />תרצה להוסיף סכום ליתרה האישית?<br /></a></a>
              <center>
                {/* <a href="https://payboxapp.page.link/tMUCWvfnFRsiFeHV9" target="_blank" rel="noreferrer"> */}
                <button id="paybox-title" className="__textBoxProductChosesClick text1_5 w400 p4 " onClick={handleClickOpen}>מעבר לאפליקציית
                  <img src={payboxlogo} width={100} alt="logo" /></button><br /></center>
              <br />
            </>
            }
            {/* <button className="__btn" onClick={deleteLocalStorage}>
            מחיקת זכרון(למפתח בלבד)
          </button> */}
            {/* {payBox==="true" && <center>
            <a href="https://payboxapp.page.link/tMUCWvfnFRsiFeHV9" target="_blank" rel="noreferrer">
              <button id="paybox-title">מעבר לאפליקציית
                <img src={payboxlogo} width={100} alt="logo" /></button><br /></a></center>
          } */}
            {payBox === "false" && tax == "false" && <>
              <div id="personal-wrap" >
                <a className="text_black text1_5 w600">לתשומת הלב! <br />בחשבונך לא נמצאו אופציות לתשלום</a><br />

                <div id="content">
                  <div id="right">
                    <div className='text_black_rtl text1_3 w300'>
                      רוצה לשלם באמצעות מיסי היישוב?
                    </div>
                    <div className='text_rtl text2 w600'>
                      כתבו לנו!
                    </div>
                  </div>
                  <div id="left_div3">

                    {user &&
                      <Container triggerText={"כתבו לנו"} onSubmit={onSubmit}></Container>
                    }

                  </div>
                </div>
                <Link style={{ textDecoration: 'none' }} to={{
                  pathname: 'cart/paybox',
                  state: {
                    uid: user.uid,
                    phone: phone
                  }
                }}>
                  <div id="paybox-title" className=' text_center w500'>הסדר תשלום באמצעות אפליקציית
                    <img src={payboxlogo} width={100} alt="logo" /> </div></Link>
              </div>
            </>}
            {!(tax == "true" && payBox == "true") && <>
              {tax == "true" && <>
                <a className="text text1_5 w400">אמצעי תשלום מקושר לחשבון זה:</a><br />

                <button className="__textBoxProductChosesClick text_white text2 height30x width40x" >מיסי הישוב <img src={givonlogo} width={"100%"} alt="logo" /></button>
                <br /><br />

              </>}</>}


            {tax == "true" && payBox == "true" && <>
              <a className="text text1_5 w400">אמצעי תשלום מקושר לחשבון זה:</a><br />
              <a className="text_red text1_5 w400">בלחיצה, ניתן לבטל אחד מסוגי התשלום</a><br />
              <div className='split_div'>
                <button className="__textBoxProductChosesClick text_white text2  height30x width40x background" onClick={handleClickOpen1}>תשלום בפייבוקס<br />
                  <img src={payboxlogo} width={"100%"} alt="logo" /></button>
                <button className="__textBoxProductChosesClick text_white text2  height30x width40x background" onClick={handleClickOpen2}>תשלום במיסי הישוב<br />
                  <img src={givonlogo} width={"100%"} alt="logo" /></button>
              </div>
              <br /><br />
            </>}

            <a className="__text">אימייל: </a>
            <input

              type="null"
              className="__textBoxEmail"
              value={email}

              onChange={(e) => setEmail(e.target.value)}
              placeholder={email}
            /><br />

            <a className="__text">שם מלא: </a>
            <input
              type="text"
              className="__textBox"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={name}

            />
            <br />
            <a className="__text">טלפון: </a>
            <input
              type="phone"
              className="__textBox"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={phone}
            />
            <br />



            <a className="__text">כתובת: </a>
            <input
              type="text"
              className="__textBox"
              value={adress}
              onChange={(e) => setAdress(e.target.value)}
              placeholder={adress}
            /><br />


            <a className="__text">תאריך לידה: </a>

            <input
              type="date"
              className="__textBox"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              placeholder={birthDate}
            /><br />
            <button className="__btn" onClick={handleClickOpen3}>
              שינוי פרטים אישיים
            </button>
            <br />
            <button className="__btn" onClick={watch}>
              צפייה בהיסטורית ההזמנות שלי
            </button>



            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              className='background'
            >
              <div className='text_center p3 background_white'>
                <div className='text_rtl text1_5 w300'>

                  <div className='text_center text2 w300'>נא לבחור סכום להעברה</div>
                  <center className='row_div_space p3'>
                    <Button
                      style={{ margin: "5px" }}
                      variant="outlined"
                      onClick={(e) => setBalanceAdd(100)}
                      className='cancel_button1'
                    >100₪</Button>
                    <Button
                      style={{ margin: "5px" }}
                      variant="outlined"
                      onClick={(e) => setBalanceAdd(200)}
                      className='cancel_button1'
                    >200₪</Button>
                    <Button
                      style={{ margin: "5px" }}
                      variant="outlined"
                      onClick={(e) => setBalanceAdd(300)}
                      className='cancel_button1'
                    >300₪</Button>
                  </center>
                </div>
                <DialogContent>
                  <div className='text_center'>
                    <input
                      type="text"
                      className="__textBox text3"
                      value={balanceAdd + " ₪"}
                      onChange={(e) => setBalanceAdd(String(e.target.value).split(" ").join("").split("₪").join(""))}
                      placeholder={balanceAdd + " ₪"}
                    />
                  </div>
                </DialogContent>

                <Button
                  // className='text_center'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={onApproval}
                  color="primary"
                  className='primary_button1 text2'
                >
                  מעבר לאפליקציית התשלום
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={open1}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <div className='text_center p3 background_white'>
                <br />
                <div className='text_center text_dark text2_5 w500 p3'>חסימת אמצעי תשלום</div>
                <br />
                <div className='text_center text_brown text1_5 w300 p3'>האם לחסום את השימוש ביתרה האישית? </div>
                <br />
                <Button
                  className='cancel_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={handleClose1}
                  color="primary"
                >
                  סגירה
                </Button>
                <Button
                  className='primary_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={() => payboxx()}
                  color="secondary"
                >
                  חסימת פייבוקס
                </Button>
              </div>
            </Dialog>

            <Dialog
              open={open2}
              onClose={handleClose2}
              aria-labelledby="form-dialog-title"
            >
              <div className='text_center p3 background_white'>
                <br />
                <div className='text_center text_dark text2_5 w500 p3'>חסימת אמצעי תשלום</div>
                <br />
                <div className='text_center text_brown text1_5 w300 p3'>האם לחסום את השימוש במיסי הישוב? </div>
                <br />
                <Button
                  className='cancel_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={handleClose2}
                  color="primary"
                >
                  סגירה
                </Button>
                <Button
                  className='primary_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={() => taxx()}
                  color="secondary"
                >
                  חסימת מיסי הישוב
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={open3}
              onClose={handleClose3}
              aria-labelledby="form-dialog-title"
            >
              <div className='text_center p3 background_white'>
                <br />
                <div className='text_center text_dark text2_5 w500 p3'>שינוי פרטים אישיים</div>
                <br />
                <div className='text_center text_brown text1_5 w300 p3'>
                  האם לשנות את הפרטים האישיים?</div>
                <br />
                <Button
                  className='cancel_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={handleClose3}
                  color="primary"
                >
                  סגירה
                </Button>
                <Button
                  className='primary_button1'
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  variant="outlined"
                  onClick={() => update2()}
                  color="secondary"
                >
                  שינוי פרטים אישיים
                </Button>
                <br />
              </div>
            </Dialog>

          </>
        }
      </div>
    </center>
  );
}

export default memo(DetailsForm)
