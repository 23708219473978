import React, { memo, useEffect, useMemo, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './SideBar.css';
import { IconContext } from 'react-icons';
//import icons from react icons
import { BsCartCheckFill, BsStars } from "react-icons/bs";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import Logo from '../../image/pub_g_icon.png';
import header from '../../image/logo.png'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, database, db, fetchVersion, version } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SidebarDataUser } from './SidebarDataUser';
import { ArrowBendDoubleUpRight } from 'phosphor-react';
import Container from '../aboutUs/components/container';
import { onValue, ref } from 'firebase/database';
import { Iron } from '@mui/icons-material';

function Navbar(props) {
  const [messagesData, setMessagesData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const currentURL = window.location.href;
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("אורח");
  const [account, setAccount] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState(0);
  const [sum, setSum] = useState(0);
  const [sumFade, setSumFade] = useState(false);
  const pageurl = window.location.pathname;
  const history = useHistory()

  const killLocale = () => {
    console.log("********************")
    localStorage.clear();
    localStorage.setItem("watchEvents", false)
    localStorage.setItem("watchproducts", false)
  }

  useMemo(() => {
    console.log("**********useEffect**********props.isOpen**************")
    console.log(props.isOpen)
    showSidebar()
  }, [props.isOpen]);


  const [touchPosition, setTouchPosition] = useState("");

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 5) {
      // showSidebar()
    }
    if (diff < -5) {
      showSidebar()
    }
    setTouchPosition(null)
  }

  const logOut = () => {
    console.log("*********loguot*******")
    localStorage.clear();
    auth.signOut();
  }

  const fetchMessages = async () => {
    try {
      const q = query(collection(db, "Messages"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const logs = [];
      doc.forEach(data => {
        const data1 = {
          id: data.id,
          title: data.data().title,
          description: data.data().message,
          amount: data.data().amount,
          price: data.data().price,
          picture: data.data().picture,
          kind: data.data().kind,
          date: data.data().date,
          time: data.data().time,
        };
        logs.push(data1);

      });
      console.log("************fetchMessages*************")
      console.log(messagesData)
      setMessagesData([]);
      setMessagesData(logs);

    } catch (err) {
      console.log(err);
    }
  };

  const fetchProducts = async () => {
    try {
      const q = query(collection(db, "Cart"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const logs = [];
      let sum1 = 0;
      doc.forEach(data => {
        let price = parseInt(data.data().price);
        sum1 = sum1 + price;
        const data1 = {
          id: data.id,
          name: data.data().name,
          description: data.data().detail,
          amount: data.data().amount,
          price: data.data().price,
        };
        logs.push(data1);
      });
      setMenuData([]);
      setMenuData(logs);
      // if (sum1 !== sum)  setSumFade(!sumFade)
      setSum(sum1)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setSumFade(!sumFade)
  }, [sum]);

  useEffect(() => {
    console.log("************sidebar useEffect*************")
    console.log("user", user)
    fetchVersion();
    if (loading) return;
    else if (!user || user === null) setName("אורח");
    else {
      // fetchProducts();
      // fetchMessages();
      const query = ref(database, "Account/" + user.uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data.accountBalance);
          setBalance(data.accountBalance);
          setName(data.name);
          setAccount(data.account);
        }
      });
    }
    // }, [user, loading, name, balance]);
  }, [user]);

  useEffect(() => {
    const query = ref(database, "bar");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setStatus(data.status)
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      const query = ref(database, "Cart");
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        const logs = [];
        var sum = 0
        if (snapshot.exists()) {
          Object.values(data).filter(x => x.uid === user.uid).map((project) => {
            sum = sum + project.amount;
            const data1 = {
              uid: project.keyUid,
              id: project.id,
              name: project.name,
              description: project.detail,
              amount: project.amount,
              price: project.price,
              picture: project.picture,
              kind: project.kind
            };
            logs.push(data1);
          });
        }
        setMenuData(logs);
        setSum(sum);
      });
    }
  }, [user]);

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >{sidebar &&
      <aside
        tag="aside"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        className="modal-cover"
        onClick={showSidebar}
      ></aside>}
      {balance < 0 && !pageurl.includes("personal") &&
        <Container triggerText={"החשבון שלך במינוס!"} kind={"welcomeminus"}></Container>}
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>

          <div className='app_bar'>
            <FaIcons.FaBars className='menu-bar-open' onClick={showSidebar} />

            <Link to='/home'>
              <img src={header} className="paddingbarlogo" alt="logo" />
            </Link>
            {sum > 0 &&
              <Link to='/cart'>
                <b className={sumFade ? 'fade cart_amount_bar ' : 'cart_amount_bar '} onAnimationEnd={() => setSumFade(false)}
                > {sum}</b>
                <BsCartCheckFill className='menu-bar-left' />
              </Link>}
            {sum === 0 &&
              <>
                {!user &&
                  <>
                    <Link to='/login'>
                      <FaIcons.FaUserCircle className='menu-bar-left' />
                    </Link>
                  </>}
                {user &&
                  <>
                    <Link to='/personal'>
                      <FaIcons.FaUserCircle className='menu-bar-left' />
                    </Link>
                  </>}
              </>}
          </div>
          <br />

        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>

          <ul className='nav-menu-items' onClick={showSidebar}>

            <li className='navbar-toggle'>

              <Link to='#' className='menu-icon'>
                <AiIcons.AiOutlineClose className='menu-icon' color={"#996633"} />
              </Link>
              <p onClick={() => history.push("/home")}><img src={Logo} className="sidebar_logo" /></p>
            </li>
            <br />
            <div className="nav-space">שלום, {name}</div>

            {name !== "אורח" && name !== "" && <>
              {SidebarDataUser.map((item, index) => {
                return (
                  <li key={index} className={item.cName} onClick={() => killLocale()}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li >
                );
              })}
              {messagesData.length > 0 &&
                <center className="nav-text-a">הודעות נכנסות: {messagesData.length}</center>}
            </>}
            {account === "manager" && name !== "אורח" &&
              <li className="nav-text">
                <Link to="/xmanagment">
                  <BsStars className='menu-icon' color={"#996633"} />
                  <span>ניהול</span>
                </Link>
              </li>}
            {account === "office" && name !== "אורח" &&
              <li className="nav-text">
                <Link to="/office">
                  <BsStars className='menu-icon' color={"#996633"} />
                  <span>משרד</span>
                </Link>
              </li>}
            {(account === "worker" || account === "bar") && name !== "אורח" &&
              <li className="nav-text">
                <Link to="/bar">
                  <BsStars className='menu-icon' color={"#996633"} />
                  <span>מסך בר</span>
                </Link>
              </li>}
            {account === "worker" && name !== "אורח" &&
              <li className="nav-text">
                <Link to="/worker">
                  <BsStars className='menu-icon' color={"#996633"} />
                  <span>מלצר</span>
                </Link>
              </li>}
            {account === "event" && name !== "אורח" &&
              <li className="nav-text">
                <Link to="/eventmanage">
                  <BsStars className='menu-icon' color={"#996633"} />
                  <span>אירוע</span>
                </Link>
              </li>}
            {(name === "אורח" || name === "") && <>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li >
                );
              })}</>}
            <div className="nav-space" />
            {(name !== "אורח" && name !== "") &&
              <li className="nav-text">
                <Link to="/home" onClick={logOut}>
                  <BiLogOut className='menu-icon' color={"#996633"} />
                  <span>התנתקות</span>
                </Link>
              </li>

            }
            {(name === "אורח" || name === "") &&
              <li className="nav-text">
                <Link to="/login">
                  <BiLogIn className='menu-icon' color={"#996633"} />
                  <span>התחברות</span>
                </Link>
              </li>

            }

            <li className="nav-text">
              <Link to="/about">
                <FaIcons.FaPhone className='menu-icon' color={"#996633"} />
                <span>צור קשר</span>
              </Link>
            </li>

            <li className="nav-text">
              <Link to="/terms_and_conditions">
                <FaIcons.FaPhone className='menu-icon' color={"#996633"} />
                <span>תנאי השירות</span>
              </Link>
            </li>

            <br />
            <div className="nav-space">גרסה: {version}</div>

          </ul>
        </nav>
      </IconContext.Provider>
    </div>
  );
}

export default memo(Navbar);