import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";


const SumOrders = () => {
  let newDate = new Date();
  let thismonth = newDate.getMonth() + 1;
  let thisyear = newDate.getFullYear();
  const [loading1, setLoading1] = useState(true);
  const [search, setSearch] = useState("");
  const [sum, setSum] = useState(0);
  const [year, setYear] = useState(thisyear);
  const [month, setMonth] = useState(thismonth);
  const [type, setType] = useState("");
  const [comingOrders, setComingOrders] = useState([]);
  const tableRef = useRef(null);



  const fetchOrders = async () => {
    try {
      // const q = query(collection(db, "CompletedOrders"), where("year", "==", year), where("month", "==", month));
      
      let q;
      if (month <= 12) {
        // Query with both year and month
        q = query(collection(db, "CompletedOrders"),
          where("year", "==", year),
          where("month", "==", month));
      } else {
        // Query with only year (ignoring month)
        q = query(collection(db, "CompletedOrders"),
          where("year", "==", year));
      } 
      const doc = await getDocs(q);
      // const q = collection(db, "CompletedOrders");
      // const doc = await getDocs(q);
      const logs = [];

      doc.forEach(data => {

        const data1 = {

          amount: data.data().amount,
          name: data.data().name,
          email: data.data().email,
          kind: data.data().kind,
          year: data.data().year,
          month: data.data().month,
          tip: data.data().tip,

        };
        logs.push(data1);



      });
      var result = [];
      logs.reduce(function (res, value) {
        if (!res[value.email + value.name + value.kind + value.year + value.month]) {
          res[value.email + value.name + value.kind + value.year + value.month] = {
            key: value.email + value.name + value.kind + value.year + value.month,
            amount: value.amount,
            name: value.name,
            kind: value.kind,
            year: value.year,
            month: value.month,
            email: value.email,
            amount: 0,
            tip: 0
          };
          result.push(res[value.email + value.name + value.kind + value.year + value.month])
        }
        res[value.email + value.name + value.kind + value.year + value.month].amount += value.amount;
        res[value.email + value.name + value.kind + value.year + value.month].tip += value.tip;
        return res;
      }, {});
      console.log(result)
      setComingOrders(result);


    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    console.log(year, month)
    fetchOrders();
  }, [year, month]);

  const DisplayData = comingOrders.map(
    (info) => {
      return (
        (info.name.includes(search) && info.kind.includes(type) &&
          <tr>
            <td>{String(info.month).padStart(2, '0')}/{info.year}</td>
            <td>{info.name}</td>
            <td>{info.amount.toFixed(2)}₪</td>
            <td>{info.tip.toFixed(2)}₪</td>
            <td>{info.kind}</td>
            <td>{info.email}</td>
          </tr>)
      )
    }
  )
  const total = comingOrders.reduce((prev, current) => {
    if (current.year===year && current.month===month && current.kind.includes(type) && current.name.includes(search)) prev += current.amount;
    return prev;
  }, 0);
  const totalTip = comingOrders.reduce((prev, current) => {
    console.log(current)
    if (current.year===year && current.month===month && current.kind.includes(type) && current.name.includes(search)) prev += current.tip;
    return prev;
  }, 0);


  return (

    <>
      <d>שנה:</d>
      <select
        onChange={(e) => setYear(parseInt(e.target.value))}
        className="__textBox"
        value={year}
      >
        <option value={thisyear - 1}>{thisyear - 1}</option>
        <option value={thisyear}>{thisyear}</option>
        <option value={thisyear + 1}>{thisyear + 1}</option>

      </select>



      <d>חודש:</d>
      <select
        onChange={(e) => setMonth(parseInt(e.target.value))}
        className="__textBox"
        value={month}
      >
        <option value={1}>ינואר</option>
        <option value={2}>פברואר</option>
        <option value={3}>מרץ</option>
        <option value={4}>אפריל</option>
        <option value={5}>מאי</option>
        <option value={6}>יוני</option>
        <option value={7}>יולי</option>
        <option value={8}>אוגוסט</option>
        <option value={9}>ספטמבר</option>
        <option value={10}>אוקטובר</option>
        <option value={11}>נובמבר</option>
        <option value={12}>דצמבר</option>
        <option value={13}>שנתי</option>

      </select>

      <d>אופן תשלום:</d>
      <select
        onChange={(e) => setType(e.target.value)}
        className="__textBox"
        value={type}
      >
        <option value="">הכל</option>
        <option value="מיסי הישוב">מיסי הישוב</option>
        <option value="יתרת חשבון">יתרת חשבון</option>
        <option value="מזומן">מזומן</option>
        <option value="PayPal">PayPal</option>
        <option value="PayBox">PayBox</option>
      </select>

      <d>
        סה"כ = {total.toFixed(2)} ₪
        <br/>
        טיפים = {totalTip.toFixed(2)} ₪
      </d>

      <br />
      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש לפי שם"
      /><br />
      {loading1===true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-wrap">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>תאריך</th>
                <th>שם לקוח</th>
                <th>סכום</th>
                <th>טיפים</th>
                <th>אופן התשלום</th>
                <th>אימייל</th>


              </tr>
            </thead>
            <tbody>


              {DisplayData}

            </tbody>
          </table>

        </div>
      )}
    </>
  );



}
export default SumOrders