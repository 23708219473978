import React, { useEffect, useState } from 'react';
import './form.css';
import { Link, useHistory } from "react-router-dom";
import { app, auth, registerWithEmailAndPassword, signInWithGoogle } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FcGoogle } from 'react-icons/fc'
import Logo from '../../image/logo.png';
import Navbar from '../sidebar/sidebar';
import { TextField } from '@material-ui/core';
import { textAlign } from '@mui/system';
import { AiOutlineGoogle } from 'react-icons/ai';
import ButtomPage from '../buttomPage';

function NewAccountForm() {
  const [currentUser, setCurrentUser] = useState(null);
  const [name, setName] = useState("");
  const [birthDate, setbirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [adress, setAdress] = useState("");
  const [phone, setPhone] = useState("");
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  const register = () => {
    if (password !== rpassword) alert("הוקלדו ססמאות שונות");
    else if (!name) alert("לא הוכנס שם");
    else if (!phone) alert("לא הוכנס טלפון");
    else
      registerWithEmailAndPassword(name, email, password, birthDate, adress, phone);
  };
  useEffect(() => {
    if (loading) return;
    if (user) {
      history.replace("/");
    };
  }, [user, loading]);



  return (
    <>
        <div id="page-wrap" className="background top_padding">

          <div className="p3 ">
            <img
              className='width70'
              src={Logo}
              alt="logo"
            />
            <div className='text_brown text2 w500 p5 '> טופס הרשמה</div>
            <input
              type="text"
              className="__textBoxEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            /><br />
            <input
              type="password"
              className="__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="סיסמא"
            /><br />
            <input
              type="password"
              className="__textBox"
              value={rpassword}
              onChange={(e) => setRPassword(e.target.value)}
              placeholder=" סיסמא שנית"
            /><br />
            <input
              type="text"
              className="__textBox"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="שם מלא"
              required={false}
            />
            <br />
            <input
              type="phone"
              className="__textBox"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="מספר טלפון"
            />
            <br />
            <br />
            <button className="welcome_button cancel_button1" onClick={register}>
              צור חשבון חדש
            </button>
            <br />
            <br />
            <div className='text_brown text1_5 w500 '>הרשמה באמצעות גוגל   </div>
            <button className="welcome_button_google primary_button1"
              onClick={signInWithGoogle}> <AiOutlineGoogle />
            </button>
            <br /><br />
            <div className='text_center text_brown w400 p3 text_sub'>
              יש לך חשבון במערכת? <Link to="/login" className='text_center text_brown w400 text_sub'>התחבר</Link> עכשיו
            </div>
          </div>
          <ButtomPage />
        </div>
    </>
  );

}

export default NewAccountForm
