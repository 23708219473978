import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";
import { database, db } from "../../firebase";
import React, { useEffect, useState } from 'react'
import Navbar from "../sidebar/sidebar";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import AllEvents from "../managment/components/event/allEvents";
import { onValue, ref } from "firebase/database";
import WorkerFormEdit from "../managment/components/work/barCheckListEdit";
import BarControl from "./components/barControl";

const theme = createTheme({
    direction: "rtl",
    palette: {
        primary: { main: "#a57733" },
        secondary: { main: "#f8e7ce" },
    },
});

function Worker() {
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState(0);
    const [status, setStatus] = useState("");
    const [userName, setUserName] = useState("");
    const history = useHistory();

    const fetchUsers = async () => {
        try {
            const q = collection(db, "Users");
            const doc = await getDocs(q);
            const logs = [];
            doc.forEach(data => {
                const data1 = {
                    value: data.data().uid,
                    label: data.data().name,
                };
                logs.push(data1);
            });
            setUsers(logs);
        } catch (err) {
            console.log(err);
            alert(err);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);


    useEffect(() => {
        const query = ref(database, "bar");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            setStatus(data.status == "פתוח" ? ("close") : ("open"))
        });
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleNameChange = (newValue1) => {
        setUserName(newValue1);
        console.log(newValue1);
    };
    const storageToBar = () => {
        history.push({
            pathname: '/xmanagment/storageedit',
            state: { kind: 'storagetobar' }
        });
    }

    const newOrder = () => {
        if (userName !== "") history.push("/xmanagment/neworder/" + (userName.value))
    }
    const viewPersonal = () => {
        if (userName !== "") history.push("/personal/" + (userName.value))
    }
    const viewPersonalCart = () => {
        if (userName !== "") history.push("/xmanagment/cart/" + (userName.value))
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ padding: "00px" }}>
                        <Typography component={"span"}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <div id="page-wrap" className="top_padding background">
                    <h1>מסך מלצרים</h1>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label="פעולות" {...a11yProps(0)} />
                            {/* <Tab label="נרשמים לאירוע" {...a11yProps(1)} /> */}
                            <Tab label={status} {...a11yProps(1)} />
                            <Tab label="אפשרויות לקוח" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <button className="button_sp"
                            onClick={storageToBar}> הוצאה מהמחסן
                        </button>
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                            <AllEvents />
                        </TabPanel> */}
                    <TabPanel value={value} index={1}>
                        <BarControl type={status} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div id="product-title">בחירת לקוח</div>
                        <Select
                            value={userName}
                            onChange={handleNameChange}
                            options={users}
                            placeholder="הקלד או בחר לקוח"
                        />
                        <button className="button_sp"
                            onClick={newOrder}>הוסף הזמנה ידני
                        </button>
                        <button className="button_sp"
                            onClick={viewPersonalCart}>צפייה בעגלת הקניות של הלקוח
                        </button>
                        <br />
                        <button className="button_sp"
                            onClick={viewPersonal}>צפייה בהזמנות של לקוח זה
                        </button>
                    </TabPanel>
                </div>
            </ThemeProvider>
        </>
    )
}

export default Worker
