import { ArrowRight, CloseOutlined, ElectricScooterRounded } from "@mui/icons-material";
import { onValue, ref } from "firebase/database";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../../../firebase";
import EventCard from "./eventRow";
import line from '../../../image/line.png'
import EventDate from "./eventDate";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMemo } from "react";



const EPage = () => {
  const [user, loading] = useAuthState(auth);
  const [touchPosition, setTouchPosition] = useState("");
  const [events, setEvents] = useState([]);
  const [top, settop] = useState([]);
  const [bottom, setbottom] = useState([]);

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);


  useMemo(() => {
    const query = ref(database, "Events");
    var today = new Date();
    return onValue(query, (snapshot) => {
      setEvents([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        var x = Object.values(data).sort((a, b) => {
          var x = String(a.date).split("-");
          var datetocheckx = x[1] + "/" + x[2] + "/" + x[0]
          var datex = new Date(datetocheckx);

          var y = String(b.date).split("-");
          var datetochecky = y[1] + "/" + y[2] + "/" + y[0]
          var datey = new Date(datetochecky);
          // console.log(datex.getTime() - datey.getTime())
          return (datex.getTime() - datey.getTime())

        }).map((project) => {
          var x = String(project.date).split("-");
          var datetocheck = x[1] + "/" + x[2] + "/" + x[0]
          var datex = new Date(datetocheck);
          var Difference_In_Time = datex.getTime() - today.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          if (Difference_In_Days > -1)
            setEvents((projects) => [...projects, project]);
          return project;
        });
        if (x.length === 1) {
          settop([26])
          setbottom([26])
        }
        else if (x.length === 2) {
          settop([20, 40])
          setbottom([5, 50])
        }
        else if (x.length === 3) {
          settop([15, 20, 50])
          setbottom([5, 50, 26])
        }
        else {
          settop([15, 15, 50, 50])
          setbottom([5, 50, 5, 50])
        }
      }
    });
  }, []);

 

  return (
    events.length > 0 &&
    <>

      {/* {events.length === 1 &&
        <>
          <br />
          {events.map((event, i) => (
            <EventCard
              key={event.uid}
              id={event.uid}
              des={event.description}
              img={event.picture}
              name={event.pname}
              price={event.price}
              date={event.date}
              time={event.time}
              amount={event.amount}
            />
          ))}
        </>} */}

      {events.length >= 1 &&
        <>

          <div>

            <span className=" text_title_img text3  " >
              <span className=" text3  p3" >אירועים</span>
            </span>
            <br /><br />

            {/* <MenuFood id={props.id} close={watch} /> */}

            <div id="page-menu">
              {events.map((event, i) => (
                <EventCard
                  key={event.uid}
                  id={event.uid}
                  des={event.description}
                  img={event.picture}
                  name={event.pname}
                  price={event.price}
                  date={event.date}
                  time={event.time}
                  amount={event.amount}
                />
              ))}
            </div>

          </div>
        </>}
      {/* <img src={line} className="line_png" alt="logo" /> */}
    </>
  );
};

export default memo(EPage);
