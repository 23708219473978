

import { useEffect, useState } from "react";
import { doc as doc1, deleteDoc} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "../../../firebase";

const MessageCard = (props) => {

  const history = useHistory();



  const remove = async () => {

    try {
      const docRef = doc1(db, "Message", props.id);
      await deleteDoc(docRef);



    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }

  };

  const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link className="product_button"
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

  return (
    <>

      <div id="product-master">
        <span className="product_name">הודעה מאת: {props.name}</span>
        <span className="nav-space" >נושא: {props.title} </span>
        <span className="product_price" >{props.message} </span>





        <button className="product_button" onClick={remove}> מחיקה מהמערכת</button>
        {props.email &&
           <ButtonMailto  label={"החזר תשובה למייל: "+props.email} mailto={"mailto:"+props.email} />}
          
        {props.phone &&
          <a href={"https://wa.me/+972"+props.phone} target="_blank" rel="noreferrer">
            <button className="product_button">שלח וואטסאפ: {props.phone}
             </button><br /></a>}
             
        {/* 
        <button className="product_button_multi" onClick={orderConfirm}>אישור הזמנה</button>
 */}


      </div>










    </>
  );
};

export default MessageCard;
