import React, { useState, useEffect } from 'react';

const days = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];
const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

function DigitalClock() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    const day = time.getDate();
    const month = months[time.getMonth()];
    const year = time.getFullYear();
    const dayHebrew = days[time.getUTCDay()];
    const formatHours = hours < 10 ? `0${hours}` : hours;
    const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return (
        <>

            <l className="text0_5 text_black">
                {`${day} ${month} ${year}`} ,
                יום {`${dayHebrew}`} , שעה:{` `}
         
            <l className="text1 digital-clock">
                {`${formatHours}:${formatMinutes}:${formatSeconds}`}
            </l>
            </l>
        </>)
}

export default DigitalClock;
