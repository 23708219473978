import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { auth, db, deleteOrderFromBar } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import { Button, Label } from "semantic-ui-react";
import { Dialog } from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";


const AllOrders = () => {
  let newDate = new Date();
  const [user, loading, error] = useAuthState(auth);
  let thismonth = newDate.getMonth() + 1;
  let thisyear = newDate.getFullYear();
  const [loading1, setLoading1] = useState(true);
  const [click, setClick] = useState(false);
  const [search, setSearch] = useState("");
  const [year, setYear] = useState(thisyear);
  const [month, setMonth] = useState(thismonth);
  const [type, setType] = useState("");
  const [comingOrders, setComingOrders] = useState([]);
  const tableRef = useRef(null);



  const fetchOrders = async () => {
    try {
      let q;
      if (month <= 12) {
        // Query with both year and month
        q = query(collection(db, "CompletedOrders"),
          where("year", "==", year),
          where("month", "==", month));
      } else {
        // Query with only year (ignoring month)
        q = query(collection(db, "CompletedOrders"),
          where("year", "==", year));
      } const doc = await getDocs(q);
      // const q = collection(db, "CompletedOrders");
      // const doc = await getDocs(q);
      const logs = [];

      doc.forEach(data => {
        const data1 = {
          id: data.id,
          amount: data.data().amount,
          bartender: data.data().bartender,
          discount: data.data().discount,
          name: data.data().name,
          email: data.data().email,
          uid: data.data().id,
          kind: data.data().kind,
          year: data.data().year,
          month: data.data().month,
          day: data.data().day,
          time: data.data().hour + ":" + data.data().minute,
          tip: data.data().tip,
          products: data.data().products,
          click: false,
        };
        logs.push(data1);
      });
      setComingOrders(logs);
    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [year, month]);

  const handleClick = (user) => () => {
    console.log(user)
    console.log(user.click)
    user.click = !user.click;
    // do whatever you want with the clicked user here.
    console.log("********************")
    console.log(user.click)
    console.log(user)
  }

  const DisplayData = comingOrders.sort((a, b) => b.day - a.day).map(
    (info) => {
      return (
        (info.name.includes(search) && info.year === year && info.month === month && info.kind.includes(type) && <>
          <tr onClick={handleClick(info)}>
            <td>{info.day}/{info.month}/{info.year}</td>
            <td>{info.time}</td>
            <td>{info.name}</td>
            <td>{parseFloat(info.amount).toFixed(2)}₪</td>
            <td>{info.tip > 0 ? (parseFloat(info.tip).toFixed(2)) + "₪" : ""}</td>
            <td>{info.kind}</td>
            <td>{info.email}</td>
            <td>{info.bartender}</td>
            <td>{info.discount}%</td>
          </tr>
          {click && <>
            <table>
              <tr>
                <th>שם פריט</th>
                <th>כמות</th>
                <th>מחיר לשורה</th>
              </tr>
              {info.products.map(
                (info) => {
                  return (
                    <>
                      <tr>
                        <td>{info.name}</td>
                        <td>{info.amount}</td>
                        <td>{info.price}</td>
                      </tr>
                    </>
                  )

                })}
            </table>
          </>}
        </>
        )


      )
    }
  )

  const total = comingOrders.reduce((prev, current) => {
    if (current.year === year && current.month === month && current.kind.includes(type) && current.name.includes(search)) prev += parseFloat(current.amount);
    return prev;
  }, 0);
  const totalHome = comingOrders.reduce((prev, current) => {
    let flag = current.kind == "חשבון הבית"
    if (flag && current.year === year && current.month === month && current.kind.includes(type) && current.name.includes(search)) prev += parseFloat(current.amount);
    return prev;
  }, 0);
  const totalTip = comingOrders.reduce((prev, current) => {
    if (current.year === year && current.month === month && current.kind.includes(type) && current.name.includes(search)) prev += parseFloat(current.tip);
    return prev;
  }, 0);

  return (

    <>
      <d>שנה:</d>
      <select
        onChange={(e) => setYear(parseInt(e.target.value))}
        className="__textBox"
        value={year}
      >
        <option value={thisyear - 1}>{thisyear - 1}</option>
        <option value={thisyear}>{thisyear}</option>
        <option value={thisyear + 1}>{thisyear + 1}</option>

      </select>



      <d>חודש:</d>
      <select
        onChange={(e) => setMonth(parseInt(e.target.value))}
        className="__textBox"
        value={month}
      >
        <option value={1}>ינואר</option>
        <option value={2}>פברואר</option>
        <option value={3}>מרץ</option>
        <option value={4}>אפריל</option>
        <option value={5}>מאי</option>
        <option value={6}>יוני</option>
        <option value={7}>יולי</option>
        <option value={8}>אוגוסט</option>
        <option value={9}>ספטמבר</option>
        <option value={10}>אוקטובר</option>
        <option value={11}>נובמבר</option>
        <option value={12}>דצמבר</option>
        <option value={13}>שנתי</option>

      </select>

      <d>אופן תשלום:</d>
      <select
        onChange={(e) => setType(e.target.value)}
        className="__textBox"
        value={type}
      >
        <option value="">הכל</option>
        <option value="מיסי הישוב">מיסי הישוב</option>
        <option value="יתרת חשבון">יתרת חשבון</option>
        <option value="מזומן">מזומן</option>
        <option value="חשבון הבית">חשבון הבית</option>
      </select>
      <div>
        סה"כ = {total.toFixed(2)} ₪
        <br />
        חשבון הבית = {totalHome.toFixed(2)} ₪
        <br />
        טיפים = {totalTip.toFixed(2)} ₪
      </div>
      <br />
      <button className="button_sp"
        onClick={() => { setClick(!click) }}>{click ? ("סגירת פירוט") : ("פתח פירוט הזמנות")}
      </button>
      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש לפי שם"
      /><br />
      {loading1 === true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-wrap">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>תאריך</th>
                <th>שעה</th>
                <th>שם לקוח</th>
                <th>סכום</th>
                <th>טיפ</th>
                <th>אופן התשלום</th>
                <th>אימייל</th>
                <th>שם מאשר ההזמנה</th>
                <th>הנחה</th>
              </tr>
            </thead>
            <tbody>
              {DisplayData}
            </tbody>
          </table>

        </div>
      )}
    </>
  );



}
export default AllOrders