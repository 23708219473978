import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage, uploadCategory } from '../../../../firebase';
import { Label } from 'semantic-ui-react';
import Navbar from '../../../sidebar/sidebar';


function NewCategoryForm() {

  const [title, setTitle] = useState("");
  const [pimg, setPimg] = useState("");
  const [sub, setSub] = useState("");
  const [location, setLocation] = useState("");


  const history = useHistory();

  const handleImg = (e) => {
    const file = e.target.files[0];
    uploadImg(file);
  };

  const uploadImg = (file) => {
    if (!file) {
      alert("Empty Image");
    } else {
      const storageRef = ref(storage, "/category_images/" + file.name);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on("state_changed", (snapshot) => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setPimg(url);

        });
      });
    }
  };



  // function to update state of name with
  // value enter by user in form

  const managment = () => {
    history.push("/xmanagment")
  }

  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    e.preventDefault();
    if (
      title === "" ||
      sub === "" ||
      pimg === "" ||
      location === ""
    ) alert("מידע חסר");
    else {
      uploadCategory(title, sub, pimg, location)
    }
  };


  return (
    <>

        <div id={"page-wrap"} >
          <div id="page">
            <h3> הוספת קטגוריה </h3>
            <Label>בחירת תמונה</Label>
            <input
              className="__textBox"
              type="file"
              placeholder="Enter Image"
              name="pimg"
              defaultValue={pimg}
              onChange={handleImg}
            /><br />

            <Label>תצוגת תמונה</Label>
            <img src={pimg} width={300} />

          
            <Label>כותרת</Label>
            <input
              type="text"
              className="__textBox"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="כותרת"
            />
            <br />
            <Label>תת-כותרת</Label>
            <textarea
              placeholder="sub-title"

              className="__textBox"
              value={sub}
              onChange={(e) => setSub(e.target.value)}
            /><br />
               <Label>מיקום</Label>
            <textarea
              placeholder="מיקום"
              type="number"
              className="__textBox"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            /><br />
           
            <br />
            <input className='button' type="Button" value="הוסף קטגוריה" onClick={Submit} />
            <br />

            <input className='button' type="Button" value="חזור לתפריט מנהל" onClick={managment} />
          </div>
        </div>
    </>
  );
}

export default NewCategoryForm
