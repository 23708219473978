import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../firebase";
import Navbar from "../sidebar/sidebar";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useHistory();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading]);
  return (
    <>
        <div className="center">

          <div className="__container">
            <input
              type="text"
              className="__textBoxEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="כתובת אי-מייל"
            />
            <button
              className="__btn"
              onClick={() => sendPasswordReset(email)}
            >
              שלח שחזור ססמא למייל
            </button>
            <div className="right">
              אין לך משתמש? <Link to="/register">פתיחת חשבון חדש</Link>
            </div>
          </div>
        </div>
    </>
  );
}
export default ResetPassword;