

import { Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth, deletaFromRealTimeDb } from "../../../../firebase";
import EventEdit from "./eventEdit";
import NewEventForm from "./newEvent";

const EventCardManager = (props) => {

  const history = useHistory();
  const [user, loading] = useAuthState(auth);
  const [click, setClick] = useState(false);
  useEffect(() => {
    if (loading) return;

  }, [user, loading]);


  const watch = () => {
    history.push("/event/" + (props.id));

  }

  const remove = async () => {
    handleClose()
    const x = deletaFromRealTimeDb(props.id, "Events")
    if (x) {
      props.onChange();
    }

    // try {
    //   const docRef = doc1(db, "Events", props.id);
    //   await deleteDoc(docRef).then(history.goBack());

    // } catch (err) {
    //   console.log(err);
    //   alert("אירעה שגיאה");
    // }

  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = useState(false);

  const handleClickOpen1 = (id) => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };


  return (
    <>
      <div id="event-master" className={click ? 'fade' : ''}
        onAnimationEnd={() => setClick(false)}
      >
        <div className="row_display">
          <img className="event_pic" src={props.img} alt="" />
          <div className="product-master">
            <center>
              <span className="text_black text3 w600 underline"> {props.name}</span>
              <br />
              <span className="text_black text2 w500">{props.des}</span>
              <br />
              <span className="text_black text1_5 w500">תאריך: {String(props.date).split("-").reverse().join("/")}</span>
              <br />
              <span className="text_black text1_5 w500">שעה: {props.time}</span>
              <br />
              {/* <span className="text_black text1_5 w500" >מחיר כניסה: {props.price} ₪</span> */}
            </center>
          </div>
        </div>
        <>

          <button className="event_button_new text1_5" onClick={handleClickOpen1}>עריכת אירוע</button>
          <button className="event_button_new text1_5" onClick={handleClickOpen}>מחיקה</button>


        </>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <br />
        <div className='text_rtl text1_5 w300'>

          <div className='text_center text2 w300'>האם אתה בטוח למחוק? </div>

        </div>
        <Button
          className='text_center'
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="outlined"
          onClick={handleClose}
          color="primary"
        >
          ביטול
        </Button>
        <Button
          className='text_center'
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="outlined"
          onClick={remove}
          color="secondary"
        >
          מחק
        </Button>
      </Dialog>

      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
      >
        <br />
        <NewEventForm event={props} />
      </Dialog>


    </>
  );
};

export default EventCardManager;
