import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { BsCalendarDateFill } from 'react-icons/bs';

export const SidebarData = [
  {
    title: 'בית',
    path: '/home',
    icon: <AiIcons.AiFillHome className='menu-icon' color={"#a57733"}/>,
    cName: 'nav-text'
  },  
  {
    title: 'אירועים',
    path: '/events',
    icon: <BsCalendarDateFill className='menu-icon' color={"#a57733"}/>,
    cName: 'nav-text'
  },
  {
    title: 'תפריט',
    path: '/menu',
    icon: <IoIcons.IoIosPaper className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },
  // {
  //   title: 'תשלום',
  //   path: '/cart',
  //   icon: <FaIcons.FaCartPlus className='menu-icon' color={"#a57733"}/>,
  //   cName: 'nav-text'
  // },
 
 
];