import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { uploadProduct } from '../../../firebase';
import Menu from './user/menuUser';
import Navbar from '../../sidebar/sidebar';

function NewOrderForm() {



  return (
    <>

        <div id={"page-wrap"} >
          <div id="page">
            <h3> הכנס הזמנה </h3>
            <Menu />

          </div>
        </div>
    </>
  );
}

export default NewOrderForm
