import { collection, getDocs, query, where } from "firebase/firestore";
import { memo, useEffect, useState } from "react";
import { checkInventory, database, db } from "../../../firebase";
import ProductCardCart from "./ProductCard";
import RingLoader from "react-spinners/RingLoader";
import { onValue, ref } from "firebase/database";
import { BlindsClosedSharp, CloseOutlined, CloseSharp } from "@mui/icons-material";
import { ArrowLeft, ArrowRight, ArrowUp } from "phosphor-react";
import CloseButton from "./closeButton";


const Food = (props) => {
  const [loading1, setLoading1] = useState(true);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const query = ref(database, "Products");
    return onValue(query, (snapshot) => {
      setMenuData([]);
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).sort((a, b) => parseInt(a.price) - parseInt(b.price)).map((project) => {
          if (String(project.category).includes(props.id))
            setMenuData((projects) => [...projects, project]);
        });
      }
      setLoading1(false);
    });
  }, []);



  return (
    <>
      {loading1 === true ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#914d03"} />
        </center>
      ) : (
        <div className="top_padding">
          <span onClick={props.close} className=" text_title_img text3  " >
            <span className=" text3 w700 p3" ><CloseOutlined /></span>
            <span className=" text3 p3" >{props.title}  </span>
          </span>
          <div
            id="page-products"
          >
            <br />

            {menuData.length === 0 &&
              <h3>כנראה שאין מוצרים מתאימים במלאי</h3>}
            {menuData.map((products) => (
              <ProductCardCart
                key={products.uid}
                id={products.uid}
                des={products.description}
                img={products.picture}
                name={products.pname}
                price={products.price}
                amount={products.amount}
                picture={products.picture}
                items={products.items}
                discount={products.discount}
              />
            ))}
          </div>
          {/* <CloseButton close={props.close}/> */}
        </div>
      )}

    </>
  );



}
export default memo(Food)