
import { useEffect, useState } from "react";
import { database } from "../../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import Navbar from "../../../sidebar/sidebar";
import { onValue, ref } from "firebase/database";
import InventoryCard from "./inventoryCard";
import StorageCard from "./storageCard";
import InventoryPull from "./inventoryPull";
import InventoryAdd from "./inventoryAdd";


const StorageEdit = (props) => {
  const kind = props.location.state.kind
  // console.log("***********StorageEdit******",props.location.state.kind)
  const [search, setSearch] = useState("");
  const [loading1, setLoading1] = useState(true);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const query = ref(database, "Storage");
    return onValue(query, (snapshot) => {
      setMenuData([]);
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).sort((a, b) => parseInt(a.location) - parseInt(b.location)).map((project) => {
          setMenuData((projects) => [...projects, project]);
        });
      }
      setLoading1(false);
    });
  }, []);

  return (
    <>
      <div id={"page-wrap"} >
        <div id="page">
          {kind === "all" &&
            <h3> ערוך מחסן </h3>}
          {kind === "bar" &&
            <h3> עדכון מלאי בר </h3>}
          {kind === "storage" &&
            <h3> עדכון מלאי מחסן </h3>}
          {kind === "storagetobar" &&
            <h3> הוצאה מהמחסן</h3>}
          {kind === "storageadd" &&
            <h3> הכנסה למחסן</h3>}

          <input
            type="text"
            className="__textBox"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="חיפוש פריט"
          />
          <br />
          {loading1 === true || menuData.length === 0 ? (
            <center id="loading" >
              <RingLoader id="loading" color={"#a57733"} />
            </center>
          ) : (<>
            {kind === "all" &&
              <StorageCard
                key={"xxx"}
                location={"מיקום"}
                id={"xxx"}
                name={"שם פריט"}
                amount={"כמות במחסן"}
                amountbar={"כמות בבר"}
              />}
            <div className="storage_layout">
              {menuData.map((products, i) => (
                products.name.includes(search) &&
                <>
                  {kind === "storagetobar" &&
                    <InventoryPull
                      location={products.location}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amount}
                      amountBar={products.amountBar}
                      kind={kind}
                    />}
                  {kind === "storageadd" &&
                    <InventoryAdd
                      location={products.location}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amount}
                      amountBar={products.amountBar}
                      kind={kind}
                    />}
                  {kind === "all" &&
                    <StorageCard
                      location={products.location}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amount}
                      amountbar={products.amountBar}
                      amountbarmax={products.amountBarMax}
                      amountmax={products.amountMax}
                    />}
                  {kind === "bar" &&
                    <InventoryCard
                      location={products.location}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amountBar}
                      kind={kind}
                    />}
                  {kind === "storage" &&
                    <InventoryCard
                      location={products.location}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amount}
                      kind={kind}
                    />}
                  {kind === "barView" &&
                    <InventoryCard
                      location={"מיקום"}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amountBarMax - products.amountBar}
                      kind={kind}
                    />}
                  {kind === "storageView" &&
                    <InventoryCard
                      location={"מיקום"}
                      key={products.uid}
                      id={products.uid}
                      name={products.name}
                      amount={products.amountMax - products.amount}
                      kind={kind}
                    />}
                </>
              ))}
            </div>
          </>
          )}
        </div>
      </div>
    </>
  );
}
export default StorageEdit