import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import Navbar from "../../sidebar/sidebar";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { CloseOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";


const UserOrders = () => {
  const uid = window.location.pathname.replace("/personal/", "");
  let newDate = new Date();
  let thismonth = newDate.getMonth() + 1;
  let thisyear = newDate.getFullYear();
  const [loading1, setLoading1] = useState(true);
  const [click, setClick] = useState(false);
  const [year, setYear] = useState(thisyear);
  const [month, setMonth] = useState(thismonth);
  const [comingOrders, setComingOrders] = useState([]);
  const tableRef = useRef(null);



  const fetchOrders = async () => {



    try {
      const q = query(collection(db, "CompletedOrders"), where("year", "==", year), where("month", "==", month), where("id", "==", uid));
      const doc = await getDocs(q);
      const logs = [];

      doc.forEach(data => {
        console.log(data)
        const data1 = {
          id: data.id,
          amount: data.data().amount,
          bartender: data.data().bartender,
          discount: data.data().discount,
          name: data.data().name,
          email: data.data().email,
          uid: data.data().id,
          kind: data.data().kind,
          year: data.data().year,
          month: data.data().month,
          day: data.data().day,
          time: data.data().hour + ":" + data.data().minute,
          tip: data.data().tip,
          products: data.data().products,
        };
        logs.push(data1);
      });
      setComingOrders(logs);

    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [month, year]);
  const history = useHistory()
  const watch = () => {
    history.goBack()
  }

  const DisplayData = comingOrders.map(
    (info) => {
      return (
        <>
          <tr>
            <td>{info.day}/{info.month}/{info.year}</td>
            <td>{info.time}</td>
            <td>{info.amount}</td>
            <td>{info.kind}</td>
          </tr>

          {click && <>
            <table>
              <tr>

                <th>שם פריט</th>
                <th>כמות</th>
                <th>מחיר לשורה</th>
              </tr>
              {info.products.map(
                (info) => {
                  return (
                    <>
                      <tr>
                        <td>{info.name}</td>
                        <td>{info.amount}</td>
                        <td>{info.price}</td>
                      </tr>
                    </>
                  )

                })}
            </table>
          </>}
        </>
      )
    }
  )
  return (
    <>
        <div id={"page-wrap"} className=" background top_padding" >
          <span onClick={watch} className=" text_title_img p3" >
            <span className=" text1_5 p8" >היסטורית ההזמנות שלי </span>
          </span>
          <br/><br/>
          <div id="page95" className="text_sub">
            <d className="__text">שנה:</d>
            <select
              onChange={(e) => setYear(parseInt(e.target.value))}
              className="__textBox"
              value={year}
            >
              <option value={thisyear - 1}>{thisyear - 1}</option>
              <option value={thisyear}>{thisyear}</option>
              <option value={thisyear + 1}>{thisyear + 1}</option>
            </select>
            <br />
            <d className="__text">חודש:</d>
            <select
              onChange={(e) => setMonth(parseInt(e.target.value))}
              className="__textBox"
              value={month}
            >
              <option value={1}>ינואר</option>
              <option value={2}>פברואר</option>
              <option value={3}>מרץ</option>
              <option value={4}>אפריל</option>
              <option value={5}>מאי</option>
              <option value={6}>יוני</option>
              <option value={7}>יולי</option>
              <option value={8}>אוגוסט</option>
              <option value={9}>ספטמבר</option>
              <option value={10}>אוקטובר</option>
              <option value={11}>נובמבר</option>
              <option value={12}>דצמבר</option>

            </select>

            <button className="menu_button primary_button text_sub text1_2"
              onClick={() => { setClick(!click) }}> {click ? <IoMdCloseCircle /> : <BsInfoCircleFill />}{click ? " סגירת פירוט" : " פתח פירוט הזמנות"}

            </button>

            <br />

            {loading1 === true ? (
              <center id="loading" >
                <RingLoader id="loading" color={"#a57733"} />
              </center>
            ) : (
              <div id="page-wrap" className="text_sub"> 

                <table ref={tableRef}>
                  <thead>
                    <tr>
                      <th>תאריך</th>
                      <th>שעה</th>
                      <th>סכום</th>
                      <th>אופן התשלום</th>





                    </tr>
                  </thead>
                  <tbody>


                    {DisplayData}

                  </tbody>
                </table>

              </div>
            )}
          </div>
        </div>
    </>
  );



}
export default UserOrders