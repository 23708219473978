import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { database, db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import UserCard from "./userCard";
import { onValue, ref } from "firebase/database";


const AllUsers = (props) => {

  const [loading1, setLoading1] = useState(true);
  const [search, setSearch] = useState("");
  const [comingOrders, setComingOrders] = useState([]);


  // const fetchOrders = async () => {



  //   try {
  //     const q = collection(db, "Users");
  //     const doc = await getDocs(q);
  //     const logs = [];

  //     doc.forEach(data => {

  //       const data1 = {
  //         id: data.id,
  //         account: data.data().account,
  //         adress: data.data().adress,
  //         phone: data.data().phone,
  //         name: data.data().name,
  //         email: data.data().email,
  //         birthDate: data.data().birthDate,
  //         uid: data.data().uid,
  //         tax: data.data().tax,
  //         payBox: data.data().payBox

  //       };
  //       logs.push(data1);



  //     });


  //     setComingOrders(logs);

  //   } catch (err) {
  //     console.log(err);
  //     alert(err);
  //   }
  //   setLoading1(false);
  // };

  useEffect(() => {
    console.log("******useEffect-allUsers************")
    const query = ref(database, "Account");
    return onValue(query, (snapshot) => {
      setComingOrders([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          setComingOrders((projects) => [...projects, project]);
        });
        console.log(comingOrders)
        setLoading1(false);
      }
    });
  }, []);

  return (
    <>
      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="תיבת חיפוש"
      /><br />
      {loading1 === true ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-menu">
          {comingOrders.map((products, i) => (
            products.name && products.name.includes(search) &&
            // (products.name.includes(search) || products.email.includes(search) || products.phone.includes(search) || products.adress.includes(search) || products.account.includes(search)) &&
            < UserCard
              admin={props.admin}
              id={products.uid}
              name={products.name}
              account={products.account}
              adress={products.adress}
              birthDate={products.birthDate}
              email={products.email}
              phone={products.phone}
              uid={products.uid}
              tax={products.tax}
              payBox={products.payBox}
              balance={products.accountBalance}
            />
          ))}
        </div>
      )}
    </>
  );



}
export default AllUsers