

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, database, db, sendMessage } from "../../firebase";
import Navbar from "../sidebar/sidebar";
import { useAuthState } from "react-firebase-hooks/auth";
import Logo from "../../image/logo.png"
import "./style.css"
import ButtomPage from "../buttomPage";
import { onValue, ref } from "firebase/database";
import { WeelOfLuck } from "../weelOfLuck/weelofluck";
import Wheelbutton from "../weelOfLuck/wheelbutton";

const AboutUs = () => {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const [account, setAccount] = useState("");

    useEffect(() => {
        async function fetchUserName() {
            const query = ref(database, "Account/" + user.uid);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setAccount(data);
                }
            }, {
                onlyOnce: true
            });
        };
        if (loading) return;
        else if (!user) setName("אורח");
        else {
            fetchUserName();
        }
    }, [user, name]);

    const onSubmit = (event) => {
        console.log("send message");
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage(account.uid, account.name, account.email, account.phone, title, des);
        }
    };

    const onSubmitNew = (event) => {
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        let name = (event.target.username.value);
        let email = (event.target.useremail.value);
        let phone = (event.target.userphone.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage("NoAccount", name, email, phone, title, des);
        }
    };

    return (
        <>
                <div id="page-wrap" className='background top_padding' >
                    <center>
                        <br/>
                        <div id="personal-wrap">
                            <h id="product-title">
                                <span className="text text3 w700 " >יקב מאיר</span>
                                <span className="text text1_5 w700 " > ע"ש מאיר שוראקי ז"ל</span>
                                {/* <h id="title"> יקב מאיר  </h> <h id="title2"> ע"ש מאיר שוראקי ז"ל  </h> */}
                                <br />
                            </h>
                            <br />
                            <img
                                width={"100%"}
                                src={Logo}
                                alt="logo"
                            />
                            <br />
                            <br />
                            <span className="text text1_5 w700 " >
                                פאב קהילתי בישוב גבעון החדשה
                                <br />
                                לתושבי הישוב ואורחיהם
                                <br />
                                גילאי 18+
                                <br />
                            </span>
                            <span className="text text1 w700 " >
                                מוזמנים לכתוב לנו כל מה שתרצו
                            </span><br />
                        </div>
                    </center>
                    <br /><br />
                    <ButtomPage />
                    {/* <Wheelbutton/> */}
                </div>

        </>
    );



}
export default AboutUs