import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useState } from 'react'
import AllOrders from "../managment/components/allOrders";
import SumOrders from "../managment/components/sumOrders";
import AllUsers from "../managment/components/allUsers";
import Navbar from "../sidebar/sidebar";
import AllEvents from "../managment/components/event/allEvents";
import Greeding from "./greeding";
import AllOrdersFood from "../managment/components/allOrdersFood";
import AllUsersTable from "../managment/components/allUsersTable";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: { main: "#a57733" },
    secondary: { main: "#f8e7ce" },
  },
});


function Office() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>

      <ThemeProvider theme={theme}>
          <div id="page-wrap">

            <Greeding text="שרונה" />

            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="סיכום ההזמנות" {...a11yProps(0)} />
                <Tab label="כל ההזמנות" {...a11yProps(1)} />
                <Tab label="סיכום שורות" {...a11yProps(2)} />
                <Tab label="טבלת לקוחות" {...a11yProps(3)} />
                <Tab label="אירועים" {...a11yProps(4)} />
              </Tabs>
            </AppBar>



            <TabPanel value={value} index={0}>
              <SumOrders />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllOrders />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AllOrdersFood />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AllUsersTable/>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AllEvents />
            </TabPanel>




          </div>
      </ThemeProvider>
    </>
  )
}

export default Office
