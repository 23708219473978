import React, { useEffect, useState } from 'react'


function Greeding(props) {
    var text = props.text
    const time = new Date().getHours()
    var timetext
    if (time >= 19)
        timetext = "לילה טוב"
    else if (time >= 12)
        timetext = "צהריים טובים"
    else if (time >= 5)
        timetext = "בוקר טוב"
    else timetext = "לילה טוב"


    return (
        <>
            {text === "אורח" &&
                <span className="text text2 w500">ברוך הבא, </span>
            }
            <span className="text text2 w500">{timetext}</span>
            {text !== "אורח" &&
                <span className="text text2 w500">, {text}</span>
            }


        </>
    )
}

export default Greeding
