import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import NewEventForm from "./components/event/newEvent";
import EventsEditView from "./components/event/eventsEdit";
import AllUsersBalance from "./components/users/allUserBalance";
import ComingPayments from "./components/comingPayments";
import ComingUsers from "./components/comingUsers";
import AllUsers from "./components/allUsers";
import Select from "react-select";
import { CreateMessage, database } from "../../firebase";
import Container from "../aboutUs/components/container";
import { onValue, ref } from "firebase/database";
import UserConfig from "./components/user/userConfig";
import AllUsersTable from "./components/allUsersTable";

function ManageUsers() {
  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [userName, setUserName] = useState("");

  const [users, setUsers] = useState([]);
  const newOrder = () => {
    if (userName !== "") history.push("/xmanagment/neworder/" + (userName.value))
  }
  const handleNameChange = (newValue1) => {
    setUserName(newValue1);
    console.log(newValue1);
  };
  useEffect(() => {
    console.log("******useEffect-managmentView************")
    const query = ref(database, "Account");
    return onValue(query, (snapshot) => {
      setUsers([])
      const data = snapshot.val();
      const logs = [];
      if (snapshot.exists()) {
        Object.keys(data).map((key, i) => {
          const data1 = {
            value: Object.values(data)[i].uid,
            label: Object.values(data)[i].name,
            email: Object.values(data)[i].email,
            tax: Object.values(data)[i].tax,
            accountBalance: Object.values(data)[i].accountBalance,
          };
          if (Object.values(data)[i].name)
            logs.push(data1);
          console.log(logs)
        });
        setUsers(logs);
      }
    });
  }, []);




  return (
    <>
      <ThemeProvider theme={theme}>
          <div className="page-wrap">

            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="פעולות" {...a11yProps(0)} />
                <Tab label="לקוחות חדשים" {...a11yProps(1)} />
                <Tab label="כל הלקוחות" {...a11yProps(2)} />
                <Tab label="כל הלקוחות" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div id="product-title">בחירת לקוח</div>
              <Select
                value={userName}
                onChange={handleNameChange}
                options={users}
                placeholder="הקלד או בחר לקוח"
              />
              {userName && <>
                <UserConfig userUid={userName.value} />
              </>}

              <br />

            </TabPanel>
            <TabPanel value={value} index={1}>
              <ComingUsers />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AllUsers />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AllUsersTable manager={true} />
            </TabPanel>
          </div>
      </ThemeProvider>
    </>
  )
}
export default ManageUsers
