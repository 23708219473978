import React, { useState } from 'react';
import { GiBubbles } from 'react-icons/gi';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import leftBubble from '../../../image/leftBubble.png'
import rightBubble from '../../../image/rightBubble.png'
import { GoogleLogo } from 'phosphor-react';
import { AiOutlineGoogle } from 'react-icons/ai';

export const FormWelcome = ({ onSubmit, onClose }) => {

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    localStorage.setItem("hide", event.target.checked)
  };

  return (
    < >
      <div className='background_white'>
        <Box sx={{
          minWidth: '100%',
          bgcolor: "#272614",
          marginTop: "0 0 0 0",
          padding: '40px 40px 40px 40px'
        }}>
          <div className='text_center text3 w300'>
            <img src={rightBubble} width={"40vw"} alt="logo" /> ברוך הבא! <img src={leftBubble} alt="logo" width={"40vw"} />
          </div>
        </Box>

        <div id="content">
          <div id="left">
            {/* אני תושב הישוב */}
            <button className="welcome_button cancel_button1"
              onClick={() => {
                onSubmit("givon");
                onClose();
              }}>התחברות</button>
          </div>
          <br /><br />
          <div className="text3 text w300">
            |
          </div>
          <div id="right">
            {/* אני אורח / משכיר */}
            <button className="welcome_button cancel_button1"
              onClick={() => {
                onSubmit("visit");
                onClose();
              }}
            >הרשמה</button>
          </div>
        </div>
        <div className='text_black text1_5 w500'>התחבר באמצעות גוגל   </div>

        <button className="welcome_button_google primary_button1 width90"
          onClick={() => {
            onSubmit("google");
            onClose();
          }}
        ><AiOutlineGoogle />
        </button>
        <br />  <br />

        {/* 
      <div >
        <button className="welcome_button"  onClick={onClose}>
          הזכר לי מאוחר יותר
        </button>
      </div>
      <br/> */}

        {/* <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} color={"#fff"} />}
        label="אל תציג הודעה זו שוב"
      /> */}
      </div>
    </>
  );
};
export default FormWelcome;
