//import useState hook to create menu collapse state
import React, { memo, useEffect, useMemo, useState } from "react";

//import icons from react icons
import { GiSpinningWheel } from "react-icons/gi";
import { Link, useHistory } from "react-router-dom";
import wheelLogo from "./wheel-of-fortune.png";


const WheelButton = () => {

    const history = useHistory();


    //   useEffect(() => {
    //     if (loading) return;
    //     fetchProducts();
    //   }, [user, loading, menuData]);



    return (
        <>
            <Link to="/lucky" className="button_lucky">
                <img
                    width={"10%"}
                    src={wheelLogo}
                    alt="logo"
                />
                {/* <div id="cart-button"  >

                    <img
                        width={"10%"}
                        src={wheelLogo}
                        alt="logo"
                    />
                </div> */}
            </Link>

        </>
    );
};

export default memo(WheelButton);
