import React, { memo, useMemo, useState } from 'react'
import '../../App.css'
import CartButton from '../cartButton';
import { RingLoader } from 'react-spinners';
import { onValue, ref } from "firebase/database";
import { database } from '../../firebase';
import CategoryCard from './components/CategoryCard';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';


function MenuSmall(props) {

    const [categories, setCategories] = useState([]);
    const [menuData, setMenuData] = useState([]);
    const [loading, setloading] = useState(false);

    useMemo(() => {
        console.log("******useMemo-menu-Category************")
        const query = ref(database, "Category");
        return onValue(query, (snapshot) => {
            setCategories([])
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).sort((a, b) => parseInt(a.location) - parseInt(b.location)).map((project) => {
                    setCategories((projects) => [...projects, project]);
                    return project;
                });
            }
        });
    }, []);

    useMemo(() => {
        const query = ref(database, "Products");
        return onValue(query, (snapshot) => {
            setMenuData([]);
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((project) => {
                    setMenuData((projects) => [...projects, project]);
                    return project;
                });
                setloading(true)
            }
        });
    }, []);

    var randomIndex = (Math.random())
    if (randomIndex < 0.3)
        randomIndex = 0
    else if (randomIndex > 0.6)
        randomIndex = 2
    else
        randomIndex = 1

    const history = useHistory()
    const goTo = (x) => {
        window.scrollTo(0, 0)
        localStorage.setItem(x, true)
        history.push("/menu");
    }
    return (<>
        <AliceCarousel
            activeIndex={categories.length}
            mouseTracking items={categories.reverse().map((category, i) => (<>

                {/* <div class="container">
                    <div class="box"></div>
                    <div class="box stack-top" ></div>
                </div> */}

                <div className="item " data-value={i}>
                    <img className="home_gallery_pic " src={category.picture} onClick={() => goTo(category.uid)} />
                </div>
                <div className="pic_home_text_botttom2">
                    <div className='text_brown white_border text2_5 w600 '>
                        מגוון {category.title}
                    </div>
                </div>

                {/*  מעבר ל -  */}

            </>))}
            infinite={true}
            animationDuration={1000}
            autoPlay={true}
            autoPlayDirection="rtl"
            autoPlayInterval={2000}
            disableButtonsControls={true}
            paddingLeft={33}
            paddingRight={33}


        // animationType="fadeout" 

        />

    </>

        // <div>
        //     {/* <div ref={scrollInto}></div> */}
        //     {loading ? <>
        //         <div id="page-menu">
        //             {categories.map((category, i) => (
        //                 (i === 0 || i === 1 || i === 2) &&
        //                 <CategoryCard
        //                     index={i === randomIndex ? -1 : 0}
        //                     key={category.uid}
        //                     id={category.uid}
        //                     subtitle={category.subtitle}
        //                     img={category.picture}
        //                     title={category.title}
        //                     menuData={menuData}
        //                 />
        //             ))}
        //         </div>
        //     </>
        //         :
        //         <center>
        //             <br /><br />
        //             <RingLoader id="loading" color={"#a57733"} />
        //         </center>}
        //     {/* <CartButton /> */}
        // </div>
    );
}

export default memo(MenuSmall)
