
import React, { memo } from 'react'
import DetailsForm from './components/detailsForm';
import header from '../../image/personal.png'
import Navbar from '../sidebar/sidebar';
import ButtomPage from '../buttomPage';


function UserDetails() {


    return (
        <>
            <div id="page-wrap" className='background top_padding' >
                <span className="text_title_img text3 " >איזור אישי</span>
                <DetailsForm />
                <ButtomPage />
            </div>
        </>
    )
}

export default memo(UserDetails)
