import React, { Component, useEffect, useState } from "react";

import WheelComponent from "./weel";
// import "react-wheel-of-prizes/dist/index.css";
import "./styles.css";

import { Button, Dialog } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { addToCart, database, updateUserPoints } from "../../firebase";
import { CurtainsOutlined } from "@mui/icons-material";
// import Confetti from "react-confetti";

export class WeelOfLuck extends Component {
    constructor(props) {
        console.log("props", props)
        super(props);
        this.state = {
            points: props.points,
            show: false,
            showf: false,
            userUid: props.uid,
        };
    }

    render() {
        // console.log(this.props.list)
        // const colors = [];
        // const segments = [];
        // this.props.list.map(x => {
        //     let name = x.product.pname
        //     segments.push(name)
        //     let color = x.color
        //     colors.push(color)
        //     // console.log(x)
        // })


        // let objIndex = {
        //   "קוקטייל": 1, 
        //   "בירה מהחבית": 2, 
        //   "ציפס": 3, 
        //   "טבעות בצל": 4, 
        // }


        // const weelColors = () => {
        //     let arr = [];
        //     // segments.forEach((el) => {
        //     //     let color = colors.shift();
        //     //     arr.push(color);
        //     //     colors.push(color);
        //     // });

        //     return arr;
        // };
        // const segColors = weelColors();

        // const rand = () => {
        //   return setTimeout(() => {
        //     return segments[Math.floor(Math.random() * segments.length)];
        //   }, 5000);
        // };
        const onFinished = (winner) => {

            console.log(winner)
            console.log(winner)
            console.log(winner)
            this.setState({ winner: winner });
            if (this.state.points >= 7) {
                this.setState({ show: winner });
            }
            else {
                this.setState({ showf: winner });
            }

        };
        const addToCart1 = () => {
            let product = []
            this.props.list.map(x => {
                let name = x.product.pname
                if (name == this.state.show) {
                    console.log(x)
                    product = x.product
                }
                // console.log(x)
            })
            let p = (this.state.points) - 7
            updateUserPoints(this.state.userUid, p)
            addToCart(product.uid, product.pname, 0, 1, this.state.userUid, "", "גלגל המזל", product.picture, "Coupon", product.uid)
            this.setState({ show: false, points: p });

        };

        const shuffle = (array) => {
            let newArray = []

            let randomIndex = Math.floor(Math.random() * array.length)

            for (let i = 0; i < array.length; i++) {
                if (randomIndex + i >= array.length)
                    randomIndex = randomIndex - array.length
                newArray.push(array[randomIndex + i])
            }
            return newArray
        }


        return (
            <div
                // id="pankaj"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    // paddingTop: "50px",
                    // paddingBottom: "50px",
                    //   background: `url(${IMAGES.background})`
                }}
            >
                {/* {this.state.show && <Confetti width={1600} height={1019} />} */}
                {/* {!this.state.show && !this.state.showf && */}
                    <WheelComponent
                        list={shuffle(this.props.list)}
                        // segments={shuffleArray(segments)}
                        // segColors={shuffle(segColors)}
                        // winningSegment={"8"}
                        onFinished={(winner) => onFinished(winner)}
                        primaryColor="#a57733"
                        contrastColor="#FDEACE"
                        buttonText="סובב"
                        isOnlyOnce={true}
                    />
                <Dialog
                    open={this.state.show}
                    onClose={() => this.setState({ show: false })}
                    aria-labelledby="form-dialog-title"
                >
                    <center className='text_center p3 background_white '>
                        <br />

                        <div className='text_center text_dark text2_5 w500 p3'>גלגל המזל</div>
                        <div className='text_rtl text_brown text1_5 w300 p3'>   מזל טוב!!!<br /> זכית ב- {this.state.show} !!! </div>

                        <br />

                        <div className="row_div_space ">

                            <Button
                                className='cancel_button1'
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                                variant="outlined"
                                onClick={() => this.setState({ show: false })}
                                color="primary"
                            >
                                סגירה
                            </Button>
                            <Button
                                className='primary_button1'
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                                variant="outlined"
                                onClick={() => addToCart1()}
                                color="secondary"
                            >
                                הוסף לעגלת הקניות
                            </Button>

                        </div>
                    </center>
                </Dialog>
                <Dialog
                    open={this.state.showf}
                    onClose={() => this.setState({ showf: false })}
                    aria-labelledby="form-dialog-title"
                >
                    <div className='text_center p3 background_white '>
                        <br />

                        <div className='text_center text_dark text2_5 w500 p3'>גלגל המזל</div>
                        <div className='text_rtl text_brown text1_5 w300 p3'>  הגלגל עצר על {this.state.showf}  </div>
                        <div className='text_rtl text_brown text1_5 w300 p3'> חזור שוב כשיהיו לך יותר נקודות</div>

                        <br />
                    </div>
                </Dialog>


            </div>
        );
    }
}
