import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { auth, db, deleteOrderFromBar } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import { Button, Label } from "semantic-ui-react";
import { Dialog } from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";


const AllOrdersFood = () => {
  let newDate = new Date();
  const [user, loading, error] = useAuthState(auth);
  let thismonth = newDate.getMonth() + 1;
  let thisyear = newDate.getFullYear();
  const [loading1, setLoading1] = useState(true);
  const [click, setClick] = useState(false);
  const [search, setSearch] = useState("");
  const [year, setYear] = useState(thisyear);
  const [month, setMonth] = useState(thismonth);
  const [type, setType] = useState("");
  const [comingOrders, setComingOrders] = useState([]);
  const tableRef = useRef(null);



  const fetchOrders = async () => {
    try {
      let q;
      if (month <= 12) {
        // Query with both year and month
        q = query(collection(db, "CompletedOrders"), 
                  where("year", "==", year), 
                  where("month", "==", month));
      } else {
        // Query with only year (ignoring month)
        q = query(collection(db, "CompletedOrders"), 
                  where("year", "==", year));
      }
      // const q = query(collection(db, "CompletedOrders"), where("year", "==", year), where("month", "==", month));
      const doc = await getDocs(q);
      // const q = collection(db, "CompletedOrders");
      // const doc = await getDocs(q);
      const logs = [];
      doc.forEach(data => {
        let flag = data.data().kind == "חשבון הבית"
        data.data().products.forEach(x => {
          const data1 = {
            id: x.id ? x.id : "",
            amount: x.amount ? x.amount : 0,
            price: flag ? 0 : (x.price ? x.price : 0),
            name: x.name ? x.name : "",
            kind: x.kind ? x.kind : "",
          };
          logs.push(data1);
        })
      });
      var result = [];
      logs.reduce(function (res, value) {
        if (!res[value.id + value.name + value.kind]) {
          res[value.id + value.name + value.kind] = {
            key: value.id + value.name + value.kind,
            id: value.id,
            name: value.name,
            kind: value.kind,
            price: 0,
            amount: 0
          };
          result.push(res[value.id + value.name + value.kind])
        }
        res[value.id + value.name + value.kind].amount += parseFloat(value.amount);
        res[value.id + value.name + value.kind].price += parseFloat(value.price);
        return res;
      }, {});
      console.log(result)
      setComingOrders(result);
    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [year, month]);

  const handleClick = (user) => () => {
    console.log(user)
    console.log(user.click)
    user.click = !user.click;
    // do whatever you want with the clicked user here.
    console.log("********************")
    console.log(user.click)
    console.log(user)
  }

  const DisplayData = comingOrders.sort((a, b) => b.amount - a.amount).map(
    (info) => {
      return (
        (info.name.includes(search) && info.kind.includes(type) && <>
          <tr>
            <td>{info.name}</td>
            <td>{info.amount}</td>
            <td>{info.price.toFixed(2)}₪</td>
            <td>{(info.price/info.amount).toFixed(2)}₪</td>
            {info.kind == "Products" && <>
              <td>מוצר</td>
            </>}
            {info.kind === "Event" && <>
              <td>אירוע</td>
            </>}
            {info.kind === "Coupon" && <>
              <td>קופון</td>
            </>}
            {info.kind === "Refund" && <>
              <td>זיכוי</td>
            </>}
            {info.kind !== "Products" && info.kind !== "Refund" && info.kind !== "Coupon" && info.kind !== "Event" && <>
              <td>אחר</td>
            </>}
          </tr>
        </>
        )


      )
    }
  )

  const total = comingOrders.reduce((prev, current) => {
    if (current.kind.includes(type) && current.name.includes(search)) prev += parseFloat(current.price);
    return prev;
  }, 0);
  const totalAmount = comingOrders.reduce((prev, current) => {
    if (current.kind.includes(type) && current.name.includes(search)) prev += parseFloat(current.amount);
    return prev;
  }, 0);

  return (

    <>
      <d>שנה:</d>
      <select
        onChange={(e) => setYear(parseInt(e.target.value))}
        className="__textBox"
        value={year}
      >
        <option value={thisyear - 1}>{thisyear - 1}</option>
        <option value={thisyear}>{thisyear}</option>
        <option value={thisyear + 1}>{thisyear + 1}</option>

      </select>



      <d>חודש:</d>
      <select
        onChange={(e) => setMonth(parseInt(e.target.value))}
        className="__textBox"
        value={month}
      >
        <option value={1}>ינואר</option>
        <option value={2}>פברואר</option>
        <option value={3}>מרץ</option>
        <option value={4}>אפריל</option>
        <option value={5}>מאי</option>
        <option value={6}>יוני</option>
        <option value={7}>יולי</option>
        <option value={8}>אוגוסט</option>
        <option value={9}>ספטמבר</option>
        <option value={10}>אוקטובר</option>
        <option value={11}>נובמבר</option>
        <option value={12}>דצמבר</option>
        <option value={13}>שנתי</option>

      </select>

      <d>סוג פריט:</d>
      <select
        onChange={(e) => setType(e.target.value)}
        className="__textBox"
        value={type}
      >
        <option value="">הכל</option>
        <option value="Products">מוצר</option>
        <option value="Event">אירוע</option>
        <option value="Coupon">קופון</option>
        <option value="Refund">זיכוי</option>
        <option value="Charge">חיוב</option>
      </select>
      <div>

        סה"כ = {total.toFixed(2)} ₪
        <br />
        סה"כ = {totalAmount} מוצרים
        <br />
      </div>
      <br />

      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש לפי שם"
      /><br />
      {loading1 === true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-wrap">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>שם פריט</th>
                <th>כמות</th>
                <th>סה"כ</th>
                <th>ממוצע</th>
                <th>סוג</th>
              </tr>
            </thead>
            <tbody>
              {DisplayData}
            </tbody>
          </table>

        </div>
      )}
    </>
  );



}
export default AllOrdersFood