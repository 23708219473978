

import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth } from "../../../firebase";
import MenuFood from "./menuFood";

const CategoryCard = (props) => {
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [transition, setTransition] = useState("");

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);

  useEffect(() => {
    const interval = setInterval(() => {
        if (localStorage.getItem(props.id) === "true")
          setClick(true)
        else {
          setClick(false)
          setTransition("transition7")
        }
    }, 100);
  }, []);


  const watch = () => {
    localStorage.setItem(props.id, !click)
    setClick(!click)
    // scrollToTop()
  }

  const [touchPosition, setTouchPosition] = useState("");

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 10) {
      // close()
    }
    if (diff < -10) {
      if (click)
        watch()
    }
    setTouchPosition(null)
  }

  const randomIndex = Math.floor(Math.random() * 1000)

  return (
    <>

      {/* <div id="product-master"> */}
      {/* <div id="product-master-img" className={randomIndex%3==1?"rotate5":(randomIndex%4==1?"rotate-10":"")} */}

      <div id="product-master-img" className={props.index === 0 ? "" : (randomIndex % 11 === 0 ? "rotate7" : (randomIndex % 12 === 0 ? "rotate-7" : (props.index === -1 ? "rotate7" : "")))}>

        <div onClick={watch} >
          {/* <img className="product_pic" src={props.img}  /> */}
          {/* <img className="product_pic_new" src={Gimage}  /> */}
          <img className="product_pic_new" src={props.img} />
          <br />
          <span className="text_center text1_3 w600 textrotate">{props.title}</span>
          {/* <br />
          <span className="text text1 w200" >{props.subtitle}</span> */}

        </div>

      </div>
      {/* {click && */}
      <div className={click ? "menu-layout  " + transition : "menu-layout no-active transition7"}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}>
        <MenuFood id={props.id} close={watch} title={props.title} />
      </div>
      {/* } */}


    </>
  );
};

export default memo(CategoryCard);
