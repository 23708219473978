import { getDoc, doc as doc1, updateDoc } from "firebase/firestore";
import RingLoader from "react-spinners/RingLoader";
import Select from "react-select";
import { useEffect, useState } from "react";

import { db, updateEvent } from "../../../../firebase";
import { getStorage, ref, listAll } from "firebase/storage";
import { Label } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import Navbar from "../../../sidebar/sidebar";


const EventEdit = (props) => {

  const uid = props.event.id;
  const storage = getStorage();
  const history = useHistory();
  const [image, setImage] = useState("");
  const [src, setSrc] = useState("");
  const images = [];
  const [picture, setPic] = useState(props.event.img);
  const [loading1, setLoading1] = useState(true);
  const [newName, setnewName] = useState(props.event.name);
  const [newprice, setnewPrice] = useState(props.event.price);
  const [newtime, setnewTime] = useState(props.event.time);
  const [newdate, setnewDate] = useState(props.event.date);
  const [newamount, setnewAmount] = useState(props.event.amount);
  const [id, setId] = useState('');
  const [newdescription, setnewDes] = useState(props.event.des);

  const fetchImages = async () => {
    // setImage(picture.replace("https://firebasestorage.googleapis.com/v0/b/pubcheck-9f89b.appspot.com/o/event_images%2F", "").replace("?alt=media&token=2f0df878-5c0f-420f-9a91-918204ffbaf7", ""));
    const listRef = ref(storage, 'event_images');
    let result = listAll(listRef).then((res) => {
      let i = 0;
      const data1 = {
        value: "",
        label: " יש לבחור תמונה",
      };
      images.push(data1);
      res.items.forEach((itemRef) => {
        const data1 = {
          value: itemRef.name,
          label: itemRef.name,
        };
        images.push(data1);
      });
    }).catch((error) => {
      console.log(error)
      // Uh-oh, an error occurred!
    });
  }

  useEffect(() => {
    fetchImages();
  }, []);
  // useEffect(() => {
  //   fetchProduct();
  // }, []);
  const update = () => {
    if (src === "") {
      updateEvent(uid, picture, newName, newdescription, newprice, newamount, newdate, newtime);
      props.event.onChange()
    }
    else {
      updateEvent(uid, src, newName, newdescription, newprice, newamount, newdate, newtime);
      props.event.onChange()
    }
  }

  const handleNameChange = (newValue) => {
    setImage(newValue);

    setSrc("https://firebasestorage.googleapis.com/v0/b/pubcheck-9f89b.appspot.com/o/event_images%2F" + newValue.value + "?alt=media&token=2f0df878-5c0f-420f-9a91-918204ffbaf7");
    if (newValue.value === "")
      setSrc("")
  };

  return (
    <>
        <div id={"page-wrap"} className="width50" >
          <Label>שם אירוע</Label>
          <input
            type="text"
            className="__textBox"
            value={newName}
            onChange={(e) => setnewName(e.target.value)}
            placeholder="שם מוצר"
          />
          <br />
          <Label>פירוט</Label>
          <textarea
            placeholder="Product Description"
            className="__textBox"
            value={newdescription}
            onChange={(e) => setnewDes(e.target.value)}
          /><br />
          <Label>מחיר</Label>
          <input
            type="number"
            placeholder="Enter Price"
            className="__textBox"
            value={newprice}
            onChange={(e) => setnewPrice(e.target.value)}
          /><br />
          <Label>כמות כרטיסים שנותרו</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={newamount}
            onChange={(e) => setnewAmount(e.target.value)}
          />
          <br />
          <Label>תאריך</Label>
          <input
            type="date"
            placeholder="Enter amount"
            className="__textBox"
            value={newdate}
            onChange={(e) => setnewDate(e.target.value)}
          />
          <br />
          <Label>שעת פתיחה:</Label>
          <input
            type="text"
            placeholder="Enter amount"
            className="__textBox"
            value={newtime}
            onChange={(e) => setnewTime(e.target.value)}
          />
          <br />
          <button className="product_button_view" onClick={update}> עדכון פרטי אירוע</button>
          <div id="product-title">לשינוי התמונה:</div>
          <img className="product_image" src={picture} width={300} />
          <div id="product-title">בחירת תמונה חדשה (לא חובה)</div>
          <Select
            value={image}
            onChange={handleNameChange}
            options={images}
            placeholder="בחר תמונה"
          />
          {src !== "" &&
            <div id="product-title">תצוגת התמונה שנבחרה:</div>}
          <img className="product_image" src={src} width={200} />
        </div>
    </>
  )


}
export default EventEdit
