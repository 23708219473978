import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import MessageCard from "./messageCard";
import EventOrderCard from "./eventOrderCard ";
import TicketCard from "../../favorites/components/TicketCard";
import { database } from '../../../firebase';
import { onValue, ref } from 'firebase/database';


const ComingEventOrders = () => {

  const [loading1, setLoading1] = useState(true);
  const [loading2, setloading2] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [comingOrders, setComing] = useState([]);

  useMemo(() => {
    console.log("******useMemo-Tickets************")
    const query = ref(database, "Tickets");
    return onValue(query, (snapshot) => {
      setTickets([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          if (project.userUid === "Managment")
            setTickets((projects) => [...projects, project]);
          // return project;
        });
      }
      console.log(tickets)
      setloading2(false)
    });
  }, []);




  const fetchOrders = async () => {

    try {
      const q = collection(db, "EventOrder");
      const doc = await getDocs(q);
      const logs = [];

      doc.forEach(data => {

        const data1 = {
          id: data.id,
          eventDate: data.data().eventDate,
          eventName: data.data().eventName,
          eventUid: data.data().eventUid,
          phoneNumber: data.data().phoneNumber,
          paymentMethod: data.data().paymentMethod,
          fullName: data.data().fullName,
          tickets: data.data().tickets,
          totalPrice: data.data().totalPrice
        };
        logs.push(data1);

      });

      setComing(logs);

    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [comingOrders]);


  return (
    <>

      {loading1 == true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-menu">

          {comingOrders.map((eOrders, i) => (
            <EventOrderCard
              id={eOrders.id}
              totalPrice={eOrders.totalPrice}
              tickets={eOrders.tickets}
              fullName={eOrders.fullName}
              paymentMethod={eOrders.paymentMethod}
              phoneNumber={eOrders.phoneNumber}
              eventUid={eOrders.eventUid}
              eventName={eOrders.eventName}
              eventDate={eOrders.eventDate}
            />
          ))}
        </div>
      )}


      {(loading2 === true) ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
          <br /><br />
        </center>
      ) : (<>
        <br /><br />
        {tickets.length > 0 &&
          <span className="text_center text_dark text3 w300 underline" >כרטיסים לאירועים:</span>
        }
        {/* {tickets.length === 0 &&
                            <h3>לא נמצאו כרטיסים</h3>} */}
        {tickets.map((products, i) => (
          <TicketCard
            Edate={products.eventDate}
            EName={products.eventName}
            ETicket={products.eventTickets}
            EUid={products.eventUid}
            total={products.total}
            key={products.uid}
            uid={products.uid}
            status={products.status}
            Ticket={products}
          />
        ))}
      </>)}
      <br/>
      <br/>
    </>
  );



}
export default ComingEventOrders