

import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth } from "../../../firebase";
import EView from "./eventPage"

const EventCard = (props) => {

  const history = useHistory();
  const [user, loading] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [transition, setTransition] = useState("");
  
  useEffect(() => {
    if (loading) return;
  }, [user, loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem(props.id) === "true")
        setClick(true)
      else {
        setClick(false)
        setTransition("transition7")
      }
    }, 100);
  }, []);


  const [touchPosition, setTouchPosition] = useState("");

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 10) {
      // close()
    }
    if (diff < -10) {
      if (click)
        watch()
    }
    setTouchPosition(null)
  }


  const watch = () => {
    if (props.location == "home") {

      history.push("/event/" + (props.id));
    }
    else {
      localStorage.setItem(props.id, !click)
      setClick(!click)
    }
    // scrollToTop()
  }

  // history.push("/event/" + (props.id));


  return (
    <>
      <div id="event-master" onClick={() => parseInt(props.amount) <= 1 ? "" : watch()}
      >
        <div className="split_div background" >
          <img className="event_pic" src={props.img} alt="" />

          <div className={parseInt(props.amount) <= 1 ? "right_div p3 disabled" : "right_div p3"}>
            <center>
              <span className="text_black text2_5 w600"> {props.name}</span>
              <br />
              {/* <span className="text_black text2 w500">{props.des}</span> */}
              <br />
              <span className="text_black text1_5 w500">תאריך: {String(props.date).split("-").reverse().join("/")}</span>
              <br />
              <span className="text_black text1_5 w500">שעה: {(props.time)}</span>
              <br />
              <span className="text_black text1 w500">{parseInt(props.amount) <= 1 ? "רכישת הכרטיסים לאירוע נגמרה" : " לחצ/י לפרטים נוספים והרשמה"}</span>
              {/* <span  className="text_black text1_5 w500">שעה: {props.time}</span> */}
              <br />
              {/* {props.price !== "0" && */}
              {/* <span  className="text_black text1_5 w500" >מחיר כניסה: {props.price} ₪</span>} */}
              {/* {props.price === "0" && */}
              {/* <span className="text_black text1_5 w600" >כניסה חופשית</span>} */}
            </center>
          </div>
        </div>
        <>
          {/* {props.price !== "0" && */}
          {/* <button className="event_button_new text1_5" onClick={watch}>הזמנת כרטיסים</button> */}
          {/* {props.price === "0" && */}
          {/* <button className="event_button_new text1_5" onClick={watch}>לפרטים נוספים</button> */}

        </>
      </div>
      <div className={click ? "menu-layout  " + transition : "menu-layout no-active transition7"}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}>
        <EView id={props.id} close={watch} title={props.title} />
      </div>
    </>
  );
};

export default EventCard;
