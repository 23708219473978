



import { useState } from "react";
import { updateUserDiscount, updateUserKind, updateUserManager, updateUserTax } from "../../../firebase";


const UserCard = (props) => {


  const ok = async () => {

    try {
      updateUserManager(props.id, "User")


    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }

  };
  const tax = async () => {

    try {
      updateUserTax(props.id, "true")
      updateUserDiscount(props.id, 20)
      updateUserKind(props.id, 1)
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }

  };


  return (
    <>
      {props.account === "newAccount" &&
        <div id="product-master">
          <button className="product_button" onClick={ok}>OK</button>
          <br />
          <span className="product_name">שם: {props.name}</span>
          <br />

          <span className="product_price" >טלפון: {props.phone} </span>
          <br />
          <span className="product_price" >{props.email} </span>
          <br />
          <span className="product_price" >תשלום מיסים: {props.tax} </span>
          <br />
          <span className="product_price" >תשלום paybox: {props.payBox} </span>
          <br />



          <button className="product_button" onClick={tax}>אישור תשלום מיסים</button>
          <br />
          {props.phone &&
            <a href={"https://wa.me/+972" + props.phone} target="_blank" rel="noreferrer">
              <button className="product_button">שלח וואטסאפ: {props.phone}
              </button><br /></a>}

          <br />


        </div>}










    </>
  );
};

export default UserCard;
