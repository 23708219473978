import { useEffect, useState } from "react";
import { database } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import { onValue, ref } from "firebase/database";
import { Table } from "semantic-ui-react";
import PaymentRow from "./paymentRow";


const ComingPayments = () => {

  const [loading1, setLoading1] = useState(true);
  const [comingPayments, setComingPayments] = useState([]);


  useEffect(() => {
    console.log("******useEffect-comingorders************")
    const query = ref(database, "Payment/waiting");
    return onValue(query, (snapshot) => {
      setComingPayments([])
      const data = snapshot.val();
      // console.log(data)
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          setComingPayments((projects) => [...projects, project]);
        });
        setLoading1(false);
        console.log(comingPayments)
      }
    });
  }, []);


  return (
    <>

      {loading1===true || comingPayments.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#914d03"} />
        </center>
      ) : (
        <div id="page-menu">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>שם</Table.HeaderCell>
                <Table.HeaderCell>סכום</Table.HeaderCell>
                <Table.HeaderCell>פעולות</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {comingPayments.map((payment) => (
                <PaymentRow payment={payment}/>
              ))}

            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );



}
export default ComingPayments