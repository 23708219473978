import React, { memo } from 'react'
import CartView from './components/cartView';
import header from '../../image/cart.png'
import Navbar from '../sidebar/sidebar';


function Cart() {
    return (
        <>
                <div id="page-wrap" className='top_padding'>
                    <span className="text_title_img text3 " >עגלת הקניות</span>
                    <br/><br/>
                    <CartView />
                </div>
        </>
    )
}

export default memo(Cart)
