import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../../firebase";
import RingLoader from "react-spinners/RingLoader";


const AllEvents = () => {
    let newDate = new Date();
    let thismonth = newDate.getMonth() + 1;
    let thisyear = newDate.getFullYear();
    const [loading1, setLoading1] = useState(true);
    const [click, setClick] = useState(false);
    const [search, setSearch] = useState("");
    const [year, setYear] = useState(thisyear);
    const [month, setMonth] = useState(thismonth);
    const [type, setType] = useState("");
    const [sum, setSum] = useState(0);
    const [comingOrders, setComingOrders] = useState([]);
    const [events, setEvents] = useState([]);
    const tableRef = useRef(null);


    const onSet = (eventName) => {
        setType(eventName);
    }


    const fetchOrders = async () => {

        try {
            const q = query(collection(db, "CompletedOrders"), where("bartender", "==", "event"));
            const doc = await getDocs(q);
            const logs = [];

            doc.forEach(data => {
                const data1 = {
                    id: data.id,
                    amount: data.data().amount,
                    bartender: data.data().bartender,
                    EventName: data.data().bartenderEmail,
                    EventDate: data.data().bartenderUid,
                    discount: data.data().discount,
                    name: data.data().name,
                    email: data.data().email,
                    uid: data.data().id,
                    kind: data.data().kind,
                    year: data.data().year,
                    month: data.data().month,
                    day: data.data().day,
                    time: data.data().hour + ":" + data.data().minute,
                    tip: data.data().tip,
                    products: data.data().products,
                    click: false,
                };
                logs.push(data1);

            });
            setComingOrders(logs)

            console.log(logs)
        } catch (err) {
            console.log(err);
            alert(err);
        }
        setLoading1(false);
    };

    useEffect(() => {
        fetchOrders();
    }, []);


    useEffect(() => {
        var result = comingOrders.reduce((x, y) => {
            (x[y.EventName] = x[y.EventName] || []).push(y);
            return x;
        }, {});
        setEvents(result)
    }, [comingOrders]);


    const handleClick = (user) => () => {
        console.log(user)
        console.log(user.click)
        user.click = !user.click;
        // do whatever you want with the clicked user here.
        console.log("********************")
        console.log(user.click)
        console.log(user)
    }

    var x = 0
    const DisplayData = comingOrders.filter(x => x.EventName === type).map(
        (info) => {
            console.log(info.amount)
            x = x + info.amount
            return (
                <>
                    <tr>

                        <td> {String(info.EventDate).split("-").reverse().join("/")}</td>
                        <td>{info.name}</td>
                        <td>
                            {info.products.map(
                                (info1) => {
                                    if (info1.amount > 0)
                                        return (
                                            <>
                                                {info1.name + ":" + info1.amount}
                                                <br/>
                                            </>
                                        )
                                })}
                        </td>
                        <td>{info.amount} ₪</td>


                    </tr>
                </>
            )
        }
    )


    return (
        <>
            <br />

            <input
                type="text"
                className="__textBox"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="שם אירוע"
            /><br />
            {loading1 === true || comingOrders.length === 0 ? (
                <center id="loading" >
                    <RingLoader id="loading" color={"#a57733"} />
                </center>
            ) : (
                <> {Object.keys(events).map(x => {
                    if (x.includes(search))
                        return (
                            <button className="welcome_button" onClick={() => {
                                onSet(x);
                            }}>{x}</button>)
                })}
                    <spam className="text text1_5 w600" >{type}:</spam>
                    <br />
                    <spam className="text text1_5 w600">סה"כ {x} ₪</spam>

                    <br /><br />


                    < div id="page-wrap" >
                        <table ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>תאריך</th>
                                    <th>שם</th>
                                    <th>סוג כרטיס</th>
                                    <th>סה"כ</th>

                                </tr>
                            </thead>
                            <tbody>


                                {DisplayData}

                            </tbody>
                        </table>

                    </div>
                </>
            )
            }
        </>
    );



}
export default AllEvents