import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import UserConfig from "./user/userConfig";


const SumOrdersDaily = () => {

  const [loading1, setLoading1] = useState(true);
  const [infoOpen, setInfo] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [comingOrders, setComingOrders] = useState([]);
  const tableRef = useRef(null);

  const [startDate, setStartDay] = useState(new Date(Date.now() - 4 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDay] = useState(new Date());

  function isBetweenDates(day, month, year) {
    const inputDate = new Date(`${month}/${day}/${year}`);
    return inputDate >= startDate && inputDate <= endDate;
  }

  const fetchOrders = async () => {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth() + 1; // JavaScript months are zero-indexed
    const endMonth = endDate.getMonth() + 1; // JavaScript months are zero-indexed
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    try {

      let q;
        q = query(
          collection(db, "CompletedOrders"),
          where("year", ">=", startYear),
          where("year", "<=", endYear)
        );
      
      const doc = await getDocs(q);
      // const q = collection(db, "CompletedOrders");
      // const doc = await getDocs(q);
      const logs = [];
      doc.forEach(data => {
        // console.log(data.data())
        const data1 = {
          amount: data.data().amount,
          id: data.data().id,
          name: data.data().name,
          email: data.data().email,
          day: data.data().day,
          hour: data.data().hour,
          minute: data.data().minute,
          kind: data.data().kind,
          year: data.data().year,
          month: data.data().month,
          products: data.data().products,
          tip: data.data().tip
        };
        if (isBetweenDates(data.data().day, data.data().month, data.data().year))
          if (String(data.data().email).includes(search) || String(data.data().name).includes(search))
            logs.push(data1);
      });
      var result = [];
      console.log(logs)
      logs.reduce(function (res, value) {
        if (!res[value.email]) {
          res[value.email] = {
            key: value.id,
            amount: 0,
            name: value.name,
            email: value.email,
            tip: 0,
            list: []
          };
          result.push(res[value.email])
        }
        res[value.email].amount += value.amount;
        res[value.email].tip += value.tip;
        res[value.email].list.push(value);
        return res;
      }, {});
      console.log(result)
      setComingOrders(result);


    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate, search]);


  const DisplayData = comingOrders.map(
    (info) => {
      return (
        // (info.name.includes(search) && info.kind.includes(type) &&
        <>
          <tr>
            <td>{info.name}</td>
            <td>{info.amount.toFixed(2)}₪</td>
            <td>{info.tip.toFixed(2)}₪</td>
            <td>{info.email}</td>
          </tr>
          {infoOpen &&
            <tr>
              <td>
                <h1>הזמנות אחרונות:</h1>
                <thead>
                  <tr>
                    <th>תאריך</th>
                    <th>שעה</th>
                    <th>סכום</th>
                    <th>טיפ</th>
                    <th>אופן</th>
                  </tr>
                </thead>
                <tbody>
                  {info.list.map((item1, index1) => (
                    <>
                      <tr key={index1}>
                        <td>{item1.day}/{item1.month}/{item1.year}</td>
                        <td>{item1.hour}:{item1.minute}</td>
                        <td>{item1.amount.toFixed(2)}</td>
                        <td>{item1.tip.toFixed(2)}</td>
                        <td>{item1.kind}</td>
                      </tr>
                      <tr>
                        <thead>
                          <tr>
                            <th>שם</th>
                            <th>כמות</th>
                            <th>סכום</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item1.products.map((item2, index2) => (
                            <>
                              <tr key={index2}>
                                <td>{item2.name}</td>
                                <td>{item2.amount}</td>
                                <td>{item2.price.toFixed(2)}</td>
                              </tr>
                              <tr>
                                {/* {DisplayDataInfo(item1.products)} */}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </tr>
                    </>
                  ))}
                </tbody>
              </td>
              <td>
                <UserConfig userUid={info.key} screen={"smart"} />
              </td>
            </tr>}

        </>

      )
    }
  )



  const total = comingOrders.reduce((prev, current) => {
    if (current.email.includes(search) || current.name.includes(search)) prev += current.amount;
    return prev;
  }, 0);
  const totalTip = comingOrders.reduce((prev, current) => {
    // console.log(current)
    if (current.email.includes(search) || current.name.includes(search)) prev += current.tip;
    return prev;
  }, 0);


  return (

    <>
      {/* <d>שנה:</d>
      <select
        onChange={(e) => setYear(parseInt(e.target.value))}
        className="__textBox"
        value={year}
      >
        <option value={thisyear - 1}>{thisyear - 1}</option>
        <option value={thisyear}>{thisyear}</option>
        <option value={thisyear + 1}>{thisyear + 1}</option>

      </select> */}


      <d>מתאריך: {startDate.toLocaleDateString().split(".").join("/")}
        <button onClick={() => setStartDay(new Date(startDate.getTime() + 1 * 24 * 60 * 60 * 1000))}>
          +
        </button>
        <button onClick={() => setStartDay(new Date(startDate.getTime() - 1 * 24 * 60 * 60 * 1000))}>
          -
        </button>
        <br />
        עד: {endDate.toLocaleDateString().split(".").join("/")}
        <button onClick={() => setEndDay(new Date(endDate.getTime() + 1 * 24 * 60 * 60 * 1000))}>
          +
        </button>
        <button onClick={() => setEndDay(new Date(endDate.getTime() - 1 * 24 * 60 * 60 * 1000))}>
          -
        </button>
        <br /></d>

      <br />

      <d>
        סה"כ = {total.toFixed(2)} ₪
        <br />
        טיפים = {totalTip.toFixed(2)} ₪
      </d>

      <br />
      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש לפי שם"
      /><br />
      <button
        onClick={() => setInfo(!infoOpen)}>
        פתח/סגור פירוט
      </button>

      {loading1 === true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-wrap">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>שם לקוח</th>
                <th>סה"כ</th>
                <th>טיפים</th>
                <th>אימייל</th>


              </tr>
            </thead>
            <tbody>


              {DisplayData}

            </tbody>
          </table>

        </div>
      )}
    </>
  );



}
export default SumOrdersDaily