import { Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import { Button, Dialog } from "@mui/material";
import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from 'react'
import { database } from "../../firebase";
import ComingOrders from "../managment/components/comingOrders";
import SumOrdersDaily from "../managment/components/sumOrdersDaily";
import Navbar from "../sidebar/sidebar";
import BarTips from "./barTips";
import DigitalClock from "./DigitalClock";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: { main: "#a57733" },
    secondary: { main: "#f8e7ce" },
  },
});


function Bar() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [openComing, setOpenComing] = useState(false);

  const handleClickOpenComing = () => {
    setOpenComing(true);
  };

  const handleCloseComing = () => {
    setOpenComing(false);
  };

  return (
    <>

      <ThemeProvider theme={theme}>
        {/* <Navbar /> */}
        <div id="page-wrap" className="background">
          <div className="split_div __textBox" >
            <div className="right_div2_3">
              <DigitalClock />
            </div>
            <div className="right_div_3_right">
              <BarTips admin="null" />
            </div>
            <button onClick={handleClickOpenComing} >
              לקוחות אחרונים
            </button>
          </div>
          <ComingOrders />
        </div>
      </ThemeProvider>

      <Dialog
        open={openComing}
        onClose={handleCloseComing}
        // aria-labelledby="form-dialog-title"
        className='background_white'
      >
        <div className='text_center background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_rtl text_brown text0 w300 p3'>
              <SumOrdersDaily />
            </div>.
          </div>
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleCloseComing}
            color="primary"
          >
            סגירה
          </Button>
        </div>
      </Dialog>
    </>
  )
}

export default Bar
