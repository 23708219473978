import React from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import Select from "react-select";



export const formatChoices = choices => {
    return choices;
};

class EventUserChoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.choices,
            userKind: props.userKind
        };
        console.log("סוג חשבון", props.userKind)
    }

    handleAmountChange = (index, i) => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx || choice.amount + i < 0) return choice;
            return { ...choice, name: choice.name, totalprice: choice.price * (choice.amount + i), amount: choice.amount + i };
        });
        this.setState({ choices: newChoices });

        this.props.handleSubmit(
            formatChoices(this.state.choices)
        )

    };

    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;
        const { userKind } = this.state;

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                {choices.map((choice, index) => (
                    <div key={["choice", index].join("__")} disabled={parseInt(userKind) != parseInt(choice.kind) && parseInt(choice.kind) < 5} className="row_div_space event-ticket-view background p3">
                        {/* <div className="right_div"> */}
                        <spam className="text_brown text2 w500 p3">{choice.name}</spam>
                        {/* </div> */}
                        <div className="row_div_space">
                            {/* <div className="text_center text_brown  text1_5 w600" >כמות:</div> */}
                            <button disabled={parseInt(userKind) != parseInt(choice.kind) && parseInt(choice.kind) < 5} className="text_brown text2 w500 p4 clear_button" onClick={this.handleAmountChange(index, -1)}><FaMinusCircle /></button>
                            <a className="text_brown text2 w500 p3 ">{choice.amount}</a>
                            <button disabled={parseInt(userKind) != parseInt(choice.kind) && parseInt(choice.kind) < 5} className="text_brown text2 w500 p4 clear_button" onClick={this.handleAmountChange(index, 1)}><FaPlusCircle /></button>
                        </div>
                        {/* <div >
                                <button  type="submit" className="text_brown text1_5 w600 clear_button" onClick={this.handleAmountChange(index, -1)}><FaMinusCircle /></button>
                                <spam className="text_brown text1_5 w600 p2"> {choice.amount}</spam>
                                <button  type="submit" className="text_brown text1_5 w600 clear_button" onClick={this.handleAmountChange(index, 1)}><FaPlusCircle /></button>
                            </div> */}
                        {/* </div>
                        <div className=""> */}
                        <spam className="text_brown text2 w500 p3">{choice.totalprice}₪</spam>
                        {/* </div> */}

                        {/* <button
                            className="button"
                            onClick={this.handleRemoveChoice(index)}
                            type="submit" >
                            מחיקה
                        </button> */}
                    </div>
                ))}
                {/* <button className="button" type="button" onClick={this.handleAddChoice}>
                    הוסף כרטיס
                </button>
                <button className="button_b" type="submit">רענן</button> */}
            </form>
        );
    }
}

export default EventUserChoices;
