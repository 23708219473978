import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { database, db } from "../../../../firebase";
import ProductCardCart from "./ProductCard";
import RingLoader from "react-spinners/RingLoader";
import { useHistory } from "react-router-dom";
import Navbar from "../../../sidebar/sidebar";
import { onValue, ref } from "firebase/database";


const MenuEdit = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const uid = window.location.pathname.replace("/xmanagment/neworder/", "");
  const [loading1, setLoading1] = useState(true);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const query = ref(database, "Products");
    return onValue(query, (snapshot) => {
      setMenuData([]);
      const data = snapshot.val();
      const logs = [];
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          const data1 = {
            id: project.uid,
            name: project.pname,
            picture: project.picture,
            description: project.description,
            amount: project.amount,
            price: project.price,
            category: project.category
          };
          logs.push(data1);
        });
      }
      setMenuData(logs);
      setLoading1(false);
    });
  }, []);

  return (
    <>
        <div id={"page-wrap"} >
          <div id="page">
            <h3> ערוך תפריט </h3>
            <input
              type="text"
              className="__textBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="חיפוש פריט"
            />
            <br />
            {loading1===true || menuData.length === 0 ? (
              <center id="loading" >
                <RingLoader id="loading" color={"#a57733"} />
              </center>
            ) : (
              <div id="page-menu">
                {menuData.map((products, i) => (
                  String(products.name).includes(search) &&
                  <ProductCardCart
                    uid={uid}
                    email={"נקלט דרך מערכת עובדים"}
                    id={products.id}
                    des={products.description}
                    img={products.picture}
                    name={products.name}
                    price={products.price}
                    category={products.category}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
    </>
  );
}
export default MenuEdit