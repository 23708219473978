import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { collection, getDocs } from "firebase/firestore";
import { CreateMessage, database, db, updateTip0, updateTipBalance } from "../../firebase";
import ComingMessages from "./components/comingMessages";
import Container from "../aboutUs/components/container";
import { useEffect } from 'react';
import WorkerFormEdit from './components/work/barCheckListEdit';
import ManageEvents from './mangmentEvents';
import { onValue, ref } from 'firebase/database';
import BarTips from '../bar/barTips';
import LuckListManage from '../weelOfLuck/managment/editWheelOfLuck';

function ManagmentLuckWheel() {

  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const newProduct = () => {
    history.push("/xmanagment/newproduct")
  }
  const newItem = () => {
    history.push("/xmanagment/newitem")
  }
  const newCategory = () => {
    history.push("/xmanagment/newcategory")
  }
  const newEvent = () => {
    history.push("/xmanagment/newevent/")
  }


  const menuEdit = () => {
    history.push("/xmanagment/menuedit")
  }

  const storageEdit = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'all' }
    });
  }
  const storageEdit1 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'bar' }
    });
  }
  const storageEdit2 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storage' }
    });
  }
  const storageToBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storagetobar' }
    });
  }
  const storageAdd = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageadd' }
    });
  }
  const storageviewBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'barView' }
    });
  }
  const storageView = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageView' }
    });
  }
  const categoryEdit = () => {
    history.push("/xmanagment/categoryedit")
  }


  const barFunction = (type) => {
    history.push("/worker/" + type)
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="עריכת גלגל"{...a11yProps(0)} />
          <Tab label="קודים"{...a11yProps(1)} />

        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <LuckListManage />

      </TabPanel>
    </ThemeProvider>
  );
}

export default ManagmentLuckWheel
