import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { database, db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import UserCard from "./newuserCard";
import { onValue, ref } from "firebase/database";


const ComingUsers = () => {

  const [loading1, setLoading1] = useState(true);
  const [comingOrders, setComingOrders] = useState([]);



  useEffect(() => {
    console.log("******useEffect-allUsers************")
    const query = ref(database, "Account");
    return onValue(query, (snapshot) => {
      setComingOrders([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          if (project.account === "newAccount")
            setComingOrders((projects) => [...projects, project]);
        });
        console.log(comingOrders)
        setLoading1(false);
      }
    });
  }, []);


  return (
    <>

      {loading1 === true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-menu">

          {comingOrders.map((products, i) => (

            <UserCard
              key={products.uid}
              id={products.uid}
              name={products.name}
              account={products.account}
              adress={products.adress}
              birthDate={products.birthDate}
              email={products.email}
              phone={products.phone}
              uid={products.uid}
              tax={products.tax}
              payBox={products.payBox}
              balance={products.accountBalance}
            />
          ))}
        </div>
      )}
    </>
  );



}
export default ComingUsers