

import { useState } from "react";
import { updateStoragebar } from "../../../firebase";

const InventoryBarControl = (props) => {
  const [click, setClick] = useState(false);
  const [view, setView] = useState(props.view);

  const allOk = () => {
    setClick(true);
    setView(false);
    props.onchange(props.index)

  }
  const updatebarAmount = () => {
    setClick(true);
    let ab = parseFloat(props.amountBar) - 1
    updateStoragebar(props.id, ab)
  }

  return (
    <>
      {view &&
        <div className={click ? 'storage_box fade_out' : 'storage_box'} onAnimationEnd={() => setClick(false)}>
          <span className="text text2 w600 ">{props.name} </span>
          <span className="text text1_3 w400 ">כמות בבר: {parseFloat(props.amountBar).toFixed(2)}  </span>
          <button className="storage_button" onClick={allOk}>תקין</button>
          <button className="storage_button" disabled={click || parseFloat(props.amountBar)- 1 < 0} onClick={updatebarAmount}>הסר 1 לשורה</button>
        </div>}
    </>
  );
};

export default InventoryBarControl;
