import { Button, Dialog } from '@material-ui/core';
import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from "react-router-dom";
import { addEmployeeAction, auth, completeOrderFromBar, CreateMessage, database, updateUserAccountBalance, updateUserAccountPoints, uploadPaymentApprove } from '../../../../firebase';
import Container from '../../../aboutUs/components/container';
import Gcoin from "../../../../image/Gcoin1.png";
import payboxlogo from '../../../../image/payBox.png';
import givonlogo from '../../../../image/givonlogo.png';

function UserConfig(props) {
    const vocab = {
        taxMinus: "חיוב במיסי הישוב",
        payboxMinus: "חיוב יתרת חשבון",
        paybox: "זיכוי ביתרת החשבון",
        tax: "זיכוי במיסי הישוב",
    }

    const history = useHistory();

    const screen = props.screen ? props.screen : ""
    const [user, loading, error] = useAuthState(auth);
    const [account, setAccount] = useState("");
    const [kindOpen, setKindOpen] = useState("");
    const [barName, setbarName] = useState("");
    const [userUid, setUserUid] = useState(props.userUid);
    const [name, setName] = useState("");
    const [note, setNote] = useState("");
    const [sum, setSum] = useState("");

    const newOrder = () => {
        if (userUid !== "") history.push("/xmanagment/neworder/" + (userUid))
    }

    const viewPersonal = () => {
        if (userUid !== "") history.push("/personal/" + (userUid))
    }
    const viewPersonalCart = () => {
        if (userUid !== "") history.push("/xmanagment/cart/" + (userUid))
    }


    const onSubmitMessage = (event) => {
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let message = (event.target.messagetext.value);
        if (message === "" || title === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            CreateMessage(userUid, "message", title, message, "", "", "");
        }
    };

    const onSubmitCoupon = (event) => {
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let message = (event.target.messagetext.value);
        let picture = (event.target.messageimage.value);
        let amount = (event.target.messageamount.value);
        let price = (event.target.messageprice.value);

        if (message === "" || title === "" || amount === "" || price === "") {
            alert("אחד הפרטים ריק")
        }
        else if (picture === "") {
            CreateMessage(userUid, "coupon", title, message, "https://firebasestorage.googleapis.com/v0/b/pubcheck-9f89b.appspot.com/o/product_images%2F6F4FEB51-A293-4984-9058-ABDED8FFFCB9.jpeg?alt=media&token=981d0ead-c738-4c5f-ab3a-661ec258485a", amount, price);
        }
        else {
            CreateMessage(userUid, "coupon", title, message, picture, amount, price);
        }
    }

    const Refund = async () => {
        if (note === "" || sum === "") {
            alert("אחד הפרטים ריק")
            return
        }

        if (kindOpen == "paybox") {
            if (user.email != "fradkintal@gmail.com") {
                addEmployeeAction(userUid, account.email, account.name, "balanceRefund", sum, barName, user.email, user.uid, note)
                alert("נשלח לאישור מנהל")
                handleClose()
                return
            }
            else {
                completeOrderFromBar(userUid, account.name, account.email, [{ name: note, amount: -1, price: sum * -1, kind: "Refund" }], sum * -1, "יתרת חשבון", 0, 0, barName, user.email, user.uid);
                updateUserAccountBalance(userUid, sum * -1)
                uploadPaymentApprove(userUid, account.name, account.email, sum, user)
                handleClose()
                // CreateMessage(userName.value, "coupon", title, message, picture, amount, price);
            }
        }
        if (kindOpen == "payboxMinus") {
            if (user.email != "fradkintal@gmail.com") {
                addEmployeeAction(userUid, account.email, account.name, "balanceCharge", sum, barName, user.email, user.uid, note)
                alert("נשלח לאישור מנהל")
                handleClose()
                return
            }
            else {
                completeOrderFromBar(userUid, account.name, account.email, [{ name: note, amount: 1, price: sum, kind: "Charge" }], sum, "יתרת חשבון", 0, 0, barName, user.email, user.uid);
                updateUserAccountBalance(userUid, sum)
                handleClose()
                // CreateMessage(userName.value, "coupon", title, message, picture, amount, price);
            }
        }
        if (kindOpen == "tax") {
            if (user.email != "fradkintal@gmail.com") {
                addEmployeeAction(userUid, account.email, account.name, "taxRefund", sum, barName, user.email, user.uid, note)
                alert("נשלח לאישור מנהל")
                handleClose()
                return
            }
            else {
                await completeOrderFromBar(userUid, account.name, account.email, [{ name: note, amount: -1, price: sum * -1, kind: "Refund" }], sum * -1, "מיסי הישוב", 0, 0, barName, user.email, user.uid);
                handleClose()
                // CreateMessage(userName.value, "coupon", title, message, picture, amount, price);
            }
        }
        if (kindOpen == "taxMinus") {
            if (user.email != "fradkintal@gmail.com") {
                addEmployeeAction(userUid, account.email, account.name, "taxCharge", sum, barName, user.email, user.uid, note)
                alert("נשלח לאישור מנהל")
                handleClose()
                return
            }
            else {
                await completeOrderFromBar(userUid, account.name, account.email, [{ name: note, amount: 1, price: sum, kind: "Charge" }], sum, "מיסי הישוב", 0, 0, barName, user.email, user.uid);
                handleClose()
                // CreateMessage(userName.value, "coupon", title, message, picture, amount, price);
            }
        }


    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = (x) => {
        setKindOpen(x)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (loading) return;
        else if (user) {
            async function fetchUserName() {
                const query = ref(database, "Account/" + user.uid);
                return onValue(query, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        setbarName(data.name);
                    }
                }, {
                    onlyOnce: true
                });
            };
            fetchUserName();
        }
    }, [user, loading]);

    useEffect(() => {
        const query = ref(database, "Account/" + userUid);
        return onValue(query, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                console.log(data.accountBalance)
                setAccount(data)
            }
        }, {
            onlyOnce: true
        });
    }, []);



    return (
        <>
            {screen == "smart" ? <>
                <h1>{account.name}</h1>
            </> : <>
                <span className="text w700 " >{account.name}</span><br />
            </>}
            {account.payBox == "true" && <>
                <span className={account.accountBalance ? (account.accountBalance).toFixed(2) >= 0 ? "text w700" : "text w700 red" : "text w700"} >יתרה אישית: {account.accountBalance ? (account.accountBalance).toFixed(2) : (0).toFixed(2)}</span><br />
            </>}
            {account.payBox !== "true" && account.accountBalance ? account.accountBalance : 0 != 0 && <>
                <span className="text w700 red" >paybox=false</span><br />
                <span className={account.accountBalance ? (account.accountBalance).toFixed(2) >= 0 ? "text w700" : "text w700 red" : "text w700"} >יתרה אישית: {account.accountBalance ? (account.accountBalance).toFixed(2) : (0).toFixed(2)}</span><br />
            </>}
            <span className="text  w700 " >נקודות: {account.points ? account.points : 0}</span><br />


            {
                screen == "smart" ? <>

                </> : <>
                    <Container triggerText={"כתוב הודעה ללקוח"} onSubmit={onSubmitMessage} kind={"message"}></Container>
                    <Container triggerText={"כתוב שובר ללקוח"} onSubmit={onSubmitCoupon} kind={"coupon"}></Container>
                    <button className="button_sp"
                        onClick={newOrder}>הוסף הזמנה ידני
                    </button>
                    <br />
                    <button className="button_sp"
                        onClick={viewPersonalCart}>צפייה בעגלת הקניות של הלקוח
                    </button>
                    <br />
                </>
            }


            <button className="button_cart2"
                onClick={viewPersonal}>היסטורית הזמנות
            </button>
            <br />
            <br />
            {
                account.tax === "true" && <>
                    <button className="button_sp green"
                        onClick={() => handleClickOpen("tax")}>זיכוי במיסי הישוב <br/><img src={givonlogo} height={60} alt="logo" />
                    </button></>
            }
              {
                account.tax === "true" && <>
                    <button className="button_sp red"
                        onClick={() => handleClickOpen("taxMinus")}>חיוב במיסי הישוב <br/><img src={givonlogo} height={60} alt="logo" />
                    </button> <br/><br/></>
            }
            {
                account.payBox === "true" && <>
                    <button className="button_sp green"
                        onClick={() => handleClickOpen("paybox")}>הוספה ליתרה האישית <br/><img src={payboxlogo} height={60} alt="logo" />
                    </button> </>
            }
             {
                account.payBox === "true" && <>
                    <button className="button_sp red"
                        onClick={() => handleClickOpen("payboxMinus")}>חיוב יתרה האישית <br/><img src={payboxlogo} height={60} alt="logo" />
                    </button> <br/><br/></>
            }

            <button className="button_sp"
                onClick={() => {
                    if (user.email != "fradkintal@gmail.com") {
                        addEmployeeAction(userUid, account.email, account.name, "points", 7, barName, user.email, user.uid, "")
                        alert("נשלח לאישור מנהל")
                    }
                    else
                        updateUserAccountPoints(userUid, 7)
                }}>הוספת 7 נקודות
                  <img src={Gcoin} className="gcoin" alt="logo" />
            </button>
            <button className="button_sp"
                onClick={() => {
                    if (user.email != "fradkintal@gmail.com") {
                        addEmployeeAction(userUid, account.email, account.name, "points", 1, barName, user.email, user.uid, "")
                        alert("נשלח לאישור מנהל")
                    }
                    else
                        updateUserAccountPoints(userUid, 1)
                }}>הוספת נקודה
                
                <img src={Gcoin} className="gcoin" alt="logo" />
            </button>
            <br />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className='background'
            >
                <div className='text_center p3 background_white'>
                    <br />
                    <div className='text_rtl text1_5 w300'>

                        <div className='text_center text_dark text2_5 w500 p3'>{vocab[kindOpen]}</div>
                        <div className='text_rtl text_brown text1_5 w300 p3'>
                            שם פריט/שורה 
                        </div>
                        <input
                            type="text"
                            className="__textBox"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="מה לרשום בחשבונית?"
                            required={false}
                        /><br />
                        <div className='text_rtl text_brown text1_5 w300 p3'>
                            סכום 
                        </div>
                        <input
                            type="number"
                            className="__textBox"
                            value={sum}
                            onChange={(e) => setSum(parseFloat(e.target.value))}
                            placeholder="סכום לזיכוי"
                            required={false}
                        /><br /><br />

                    </div>
                    <Button
                        className='cancel_button1 '
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        סגירה
                    </Button>
                    <Button
                        className='primary_button1 '
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        variant="outlined"
                        onClick={() => Refund()}
                        color="primary"
                    >
                        שלח {vocab[kindOpen]}
                    </Button>
                </div>
            </Dialog>
        </>
    )
}
export default UserConfig
