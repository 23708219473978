import { onValue, ref } from "firebase/database";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { completeFullOrder, database, db } from "../../../../firebase";
import ProductCardCart from "../../../cart/components/CartCard";
import Navbar from "../../../sidebar/sidebar";



const UserCartView = () => {

  const uid = window.location.pathname.replace("/xmanagment/cart/", "");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [discount, setdiscount] = useState(0);
  const [tax, setTax] = useState("");
  const [payBox, setPayBox] = useState("");
  const [change, setChange] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const [menuData, setMenuData] = useState([]);


  let [sum, setSum] = useState(0);
  let [sum1, setSum1] = useState(0);
  let [sumUSD, setSumUSD] = useState(0);
  let [tip, setTip] = useState(0);

  const onChange = () => {
    setSum(0);
    setSum1(0);
    setChange(!change);
  }


  useEffect(() => {
    if (tip > 0) {
      var val1 = parseFloat(sum);
      var val2 = parseFloat(tip);
      var i = val1 + val2;
      setSum1(i);
      setSumUSD((Math.round(i * 1) / 3.5).toFixed(2));
    }
  }, [tip]);

  useEffect(() => {

    async function fetchUserName() {
      const query = ref(database, "Account/" + uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setName(data.name);
          setEmail(data.email);
          setdiscount(data.discount);
          setTax(data.tax);
          setPayBox(data.payBox);
        }
      }, {
        onlyOnce: true
      });
    };

    fetchUserName();
    fetchProducts();
  }, [change]);

  const comleteTax = async () => {
    completeFullOrder(uid, name, email, menuData, sum1, "מיסי הישוב", tip, discount)
  };
  const comletePayBox = async () => {
    completeFullOrder(uid, name, email, menuData, sum1, "יתרה אישית", tip, discount)
  };
  const comleteILS = async () => {
    completeFullOrder(uid, name, email, menuData, sum1, "מזומן", tip, discount)
  };


  const fetchProducts = async () => {

    try {
      const q = query(collection(db, "Cart"), where("uid", "==", uid));
      const doc = await getDocs(q);
      const logs = [];
      doc.forEach(data => {

        let price = parseFloat(data.data().price);
        sum = sum + price;
        setSum(sum);
        setSum1(sum)
        setSumUSD((Math.round(sum * 1) / 3.5).toFixed(2));

        const data1 = {
          id: data.id,
          name: data.data().name,
          description: data.data().detail,
          amount: data.data().amount,
          price: data.data().price,
          kind: data.data().kind,
        };
        logs.push(data1);

      });


      setMenuData(logs);

    } catch (err) {
      console.log(err);
      alert(err);
    }
  };



  return (
    <>
      <div id="page-wrap">
        <h1></h1>
        <div id="product-title" >העגלה של: {name}</div>
        <br />
        <button className="button_cart2"
          onClick={() => onChange()}>רענן עגלה
        </button>

        {menuData.length === 0 ? (
          <center id="p-master" >
            <h2>יכול להיות שהעגלה ריקה</h2>
          </center>
        ) : (

          <center id="page">
            <div id="page-menu">

              {menuData.map((products, i) => (
                <ProductCardCart
                  uid={products.id}
                  des={products.description}
                  name={products.name}
                  price={products.price}
                  amount={products.amount}
                  change={onChange}
                />
              ))}
            </div>




            <h2 >סה"כ: {sum1} ₪</h2>
            <div id="product-title" >  להוסיף טיפ?
              <input
                type="number"
                className="__textBox"
                value={tip}
                onChange={(e) => setTip(e.target.value)}
                placeholder="Tip"
                required={false}
              /><br />
              <button className="button" onClick={() => { setTip(sum * 0.05) }}> 5%</button>
              <button className="button" onClick={() => { setTip(sum * 0.1) }}> 10%</button>
              <button className="button" onClick={() => { setTip(sum * 0.15) }}> 15%</button>
              <button className="button" onClick={() => { setTip(sum * 0.2) }}> 20%</button>


            </div>



            <br />
            {tax === "true" &&
              <Link><div id="payment-title" onClick={() => comleteTax()}
              >   תשלום באמצעות מיסי הישוב
              </div></Link>
            }<br />
            {payBox === "true" &&
              <Link><div id="paybox-title" onClick={() => comletePayBox()}
              >   תשלום באמצעות יתרה אישית</div></Link>
            }<br />
            <Link><div id="payment-title" onClick={() => comleteILS()}
            >   תשלום במזומן</div></Link>
            <br />




          </center>
        )}
      </div>
    </>
  );



}
export default UserCartView