



import { useState } from "react";
import { updateUserFromManager } from "../../../firebase";


const UserCard = (props) => {
  const [code, setCode] = useState(props.account);
  const [tax, setTax] = useState(props.tax);
  const [payBox, setPayBox] = useState(props.payBox);
  const [name, setName] = useState(props.name);
  const account = async () => {
    try {
      updateUserFromManager(props.id, code, tax, name, payBox)
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
  };

  return (
    <>
      <div id="product-master">
        <input
          type="text"
          className="__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="שם מלא"
        />
        <br />
        <span className="product_email" >{props.email} </span>
        <br />
        <span className="product_price" >{props.phone} </span>
        <br />
        <span className="product_price" >{props.adress} </span>
        <br />
        <span className="product_email" >תאריך לידה: {props.birthDate} </span>
        <br />
        <span className="product_email" >יתרת חשבון: {props.balance} </span>
        <br />
        <span className="product_price" >תשלום מיסים: </span>
        <select
          onChange={(e) => setTax(e.target.value)}
          className="__text"
          value={tax}
        >
          <option value="true">כן</option>
          <option value="false">לא</option>
          <option value="block">חסום</option>


        </select>
        <span className="product_price" >תשלום פייבוקס: </span>
        <select
          onChange={(e) => setPayBox(e.target.value)}
          className="__text"
          value={payBox}
        >
          <option value="true">כן</option>
          <option value="false">לא</option>
          <option value="block">חסום</option>

        </select>


        <br />
        {props.admin != "false" && <>
          <span className="product_price" >סוג חשבון: </span>

          <select
            onChange={(e) => setCode(e.target.value)}
            className="__text"
            value={code}
          >
            <option value="">רגיל</option>
            <option value="manager">מנהל</option>
            <option value="office">משרד</option>
            <option value="bar">בר</option>
            <option value="worker">מלצר</option>
            <option value="event">אירוע</option>
          </select>
        </>}
        <br />

        <button className="product_button" onClick={account}>עידכון חשבון</button>


      </div>










    </>
  );
};

export default UserCard;
