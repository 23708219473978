import { useEffect, useState } from "react";
import ProductCardCart from "./ProductCard";
import RingLoader from "react-spinners/RingLoader";
import { useHistory } from "react-router-dom";
import { database } from '../../../../firebase';
import { onValue, ref } from "firebase/database";


const Menu = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const uid = window.location.pathname.replace("/xmanagment/neworder/", "");
  const [loading1, setLoading1] = useState(true);
  const [menuData, setMenuData] = useState([]);

  const viewPersonalCart = () => {
    history.push("/xmanagment/cart/" + (uid))
  }

  useEffect(() => {
    const query = ref(database, "Products");
    return onValue(query, (snapshot) => {
      setMenuData([]);
      const data = snapshot.val();
      const logs = [];
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          const data1 = {
            id: project.uid,
            name: project.pname,
            picture: project.picture,
            description: project.description,
            amount: project.amount,
            price: project.price,
            category: project.category
          };
          logs.push(data1);
        });
      }
      setMenuData(logs);
      console.log(logs)
      setLoading1(false);
    });
  }, []);

  return (
    <>
      <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש פריט"
      />
      <button className="button"
        onClick={viewPersonalCart}>צפייה בעגלת הקניות של הלקוח
      </button>

      <br />
      {loading1===true || menuData.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (
        <div id="page-menu">
          {menuData.map((products, i) => (
            String(products.name).includes(search) &&
            <ProductCardCart
              uid={uid}
              email={"נקלט דרך מערכת עובדים"}
              id={products.id}
              des={products.description}
              img={products.picture}
              name={products.name}
              price={products.price}
              category={products.category}
            />
          ))}
        </div>
      )}
    </>
  );



}
export default Menu