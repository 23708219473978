import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import useSound from 'use-sound';
import sound from '../../../sound/Deskbell.mp3';


const ComingOrders = () => {

  const [loading1, setLoading1] = useState(true);
  const [comingOrders, setComingOrders] = useState([]);
  const [length, setLength] = useState(0);
  const [newLength, setNewLength] = useState(0);
  const [play] = useSound(sound);

  const fetchOrders = async () => {



    try {
      const q = collection(db, "ComingOrder");
      const doc = await getDocs(q);
      const logs = [];

      doc.forEach(data => {
        console.log("data",data.data())
        const data1 = {
          id: data.id,
          name: data.data().name,
          products: data.data().products,
          amount: data.data().amount,
          email: data.data().email,
          uid: data.data().id,
          kind: data.data().kind,
          tip: data.data().tip,
          discount: data.data().discount,
          note: data.data().note,
        };
        logs.push(data1);

      });

      setNewLength(logs.length)
      setComingOrders(logs);

    } catch (err) {
      console.log(err);
      alert(err);
    }
    setLoading1(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [comingOrders]);

  useEffect(() => {
    if (newLength > length) {
      play();
      setLength(newLength);
    }
    else if (newLength < length) {
      setLength(newLength);
    }
  }, [newLength]);


  return (
    <>

      {loading1 === true || comingOrders.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#914d03"} />
        </center>
      ) : (
        <div id="page-menu">
          {comingOrders.map((products, i) => (
            <OrderCard
              id={products.id}
              uid={products.uid}
              name={products.name}
              product={products.products}
              price={products.amount}
              email={products.email}
              kind={products.kind}
              tip={products.tip}
              note={products.note}
              discount={products.discount}
            />
          ))}
        </div>
      )}
    </>
  );



}
export default ComingOrders