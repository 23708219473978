import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import NewEventForm from "./components/event/newEvent";
import EventsEditView from "./components/event/eventsEdit";
import TicketsEditView from "./components/event/ticketsEdit";
import AllEvents from "./components/event/allEvents";
import EventScan from "./components/event/EventsScan";

function ManageEvents() {
  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const newCar = () => {
    history.push("/xmanagment/newcustomer")
  }
  const empty = () => {
    history.push("/xmanagment/empty")
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="page-wrap">
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >

              <Tab label="כל הכרטיסים" {...a11yProps(0)} />
              <Tab label="עריכת אירועים" {...a11yProps(1)} />
              <Tab label="הוספת אירוע" {...a11yProps(2)} />
              <Tab label="סיכום אירועים" {...a11yProps(3)} />
              <Tab label="סריקת ברקודים" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <TicketsEditView />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EventsEditView />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NewEventForm />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AllEvents />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EventScan />
          </TabPanel>
        </div>
      </ThemeProvider>
    </>
  )
}
export default ManageEvents
