import { ArrowRight, CloseOutlined } from "@mui/icons-material";
import { onValue, ref } from "firebase/database";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../../../../firebase";
import EventCard from "../../../home/components/eventRow";
import EventCardManager from "./EventCard";

const EventsEditView = () => {
  const [user, loading] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [touchPosition, setTouchPosition] = useState("");
  const [events, setEvents] = useState([]);
  const [change, setChange] = useState(false);

  useEffect(() => {
    console.log("******useMemo-events************")
    const query = ref(database, "Events");
    return onValue(query, (snapshot) => {
      setEvents([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          setEvents((projects) => [...projects, project]);
          return project;
        });
      }
    }, {
      onlyOnce: true
    });
    }, [change]);

  const watch = () => {
    setClick(!click)
    // scrollToTop()
  }
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 10) {
      // close()
    }
    if (diff < -10) {
      watch()
    }
    setTouchPosition(null)
  }

  const onChange = () => {
    setChange(!change);
  }



  return (
    <>

      {events.map((event, i) => (
        <EventCardManager
          key={event.uid}
          id={event.uid}
          des={event.description}
          img={event.picture}
          name={event.pname}
          price={event.price}
          date={event.date}
          time={event.time}
          coupon={event.coupon}
          amount={event.amount}
          onChange={onChange}
        />
      ))}
    </>
  );
};

export default memo(EventsEditView);
