

import { useEffect, useRef, useState } from "react";
import { getDoc, doc as doc1, deleteDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiBasket, BiGift } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { addCoupon, auth, db, deletaFromRealTimeDb } from "../../../firebase";

const ProductCardCart = (props) => {

  const objectRef = useRef(null);
  const [click, setClick] = useState(false);
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;

  }, [user, loading]);


  const remove = async () => {
    setClick(true);
    const docRef = doc1(db, "Cart", props.uid);
    console.log(props)
    try {
      if (props.kind === "Coupon") {
        addCoupon(props.name, props.price, props.amount, user.uid, props.des, props.picture, "off")
        //await deleteDoc(docRef);

      }
      //wait here 2 sec

      // deletaFromRealTimeDb(props.uid, "Cart")
      // await deleteDoc(docRef);
      // props.change()
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
  };



  const removeB = async () => {
    try {
      deletaFromRealTimeDb(props.uid, "Cart")
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
    setClick(false);
  };

  if (objectRef.current) {
    objectRef.current.style.top = objectRef.current.offsetTop + "px";
    objectRef.current.style.left = objectRef.current.offsetLeft + "px";
  }

  return (
    <>
      {click &&
        <div id="product-master-new0" ref={objectRef}
      >
        <div >
          <div className="split_div">
            {/* <img className="product_pic" src={props.img} />
            <br /> */}
            {/* {props.id !== "Coupon" && */}
            {!(props.kind === "Coupon") ?
              <button disabled={click} className="product_button_watch text1_3" > הסר <BiBasket /></button>
              :
              <button disabled={click} className="product_button_watch text1_3"> קופון <BiGift /></button>
            }
            {/* } */}
            <div className="right_div">
              {/* <span className="text_black text2 w500">{props.des}</span> */}
              <span className="product_p1 text_black text2 w600">{props.name}</span>
              <div className="split_div">
                <span className="product_p2 text_black text1_5 w600">כמות: {props.amount} </span>
                <span className="product_p2 text_black text1_5 w500" > - מחיר: {props.price} ₪</span>
              </div>
              {props.des !== "" &&
                <span className="product_p1 text_black text1_5 w500" >הערות: {props.des}</span>}
              {/* <button disabled={click} className="product_button_new text1" onClick={update} >הוסף לסל <BiBasket /></button> */}
            </div>
          </div>
        </div>
      </div>
      }

      {/* <div id="product-master" className={click ? 'fade_out' : ''} onAnimationEnd={() => setClick(false)}>
        {props.picture &&
          <img className="product_pic" src={props.picture} />}
        <span className="product_name">{props.name}</span>
        <span className="product_price">כמות: {props.amount}</span>
        <span className="product_price" >מחיר: {props.price} ₪</span>
        {props.des !== "" &&
          <span className="product_price" >הערות: {props.des}</span>}
        {props.id !== "Coupon" &&
          <button disabled={click} className="product_button" onClick={remove}> הסר מ-<BiBasket /></button>
        }
      </div> */}
    
      <div id="product-master-new" ref={objectRef} className={click ? 'swipe-right' : ''}
        onAnimationEnd={() => removeB()}
      >
        <div >
          <div className="split_div">
            {/* <img className="product_pic" src={props.img} />
            <br /> */}
            {/* {props.id !== "Coupon" && */}
            {!(props.kind === "Coupon") ?
              <button disabled={click} className="product_button_watch text1_3" onClick={remove}> הסר <BiBasket /></button>
              :
              <button disabled={click} className="product_button_watch text1_3" onClick={remove}> קופון <BiGift /></button>
            }
            {/* } */}
            <div className="right_div">
              {/* <span className="text_black text2 w500">{props.des}</span> */}
              <span className="product_p1 text_black text2 w600">{props.name}</span>
              <div className="split_div">
                <span className="product_p2 text_black text1_5 w600">כמות: {props.amount} </span>
                <span className="product_p2 text_black text1_5 w500" > - מחיר: {props.price} ₪</span>
              </div>
              {props.des !== "" &&
                <span className="product_p1 text_black text1_5 w500" >הערות: {props.des}</span>}
              {/* <button disabled={click} className="product_button_new text1" onClick={update} >הוסף לסל <BiBasket /></button> */}
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ProductCardCart;
