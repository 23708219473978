import React from 'react';
import TextField from '@material-ui/core/TextField';

export const FormNewUser = ({ onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className=" background p5" >
      <label className="__text" htmlFor="username">שם מלא:</label>
      <input
        type="text"
        className="__textBox"
        id="username"
        placeholder="שם מלא"
      />
      <br />
      <label className="__text" htmlFor="userphone">טלפון:</label>
      <input
        type="phone"
        className="__textBox"
        id="userphone"
        placeholder="טלפון"
      />
      <br />
      <label className="__text" htmlFor="useremail">אימייל:</label>
      <input
        type="email"
        className="__textBox"
        id="useremail"
        placeholder="מייל"
      />
      <br />
      <label className="__text" htmlFor="messagetitle">נושא:</label>
      <input
        className="__textBoxTitle"
        id="messagetitle"
        placeholder="נושא"
      />

      <br />
      <label className="__text" htmlFor="messagetext">הודעה:</label>
      <br/>
      <textarea
        type="text"
        className="__textBox"
        id="messagetext"
        placeholder="ההודעה שלי"
      />
      <div className="form-group">
        <button className="menu_button primary_button" type="submit">
          שלח
        </button>
      </div>
    </form>
  );
};
export default FormNewUser;
