import { Checkbox } from '@mui/material';
import { onValue, ref } from 'firebase/database';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import { database, updatebarStatus } from '../../../firebase';
import StorageBarCheck from './StorageCheck';

function BarControl(props) {
  var type = props.type == "open" ? ("פתיחת בר") : ("סגירת בר")
  var text = "המשך " + type
  const [index, setIndex] = useState(0)
  const history = useHistory();
  const [list, setList] = useState([]);

  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    if (index < 2)
      setIndex(index + 1)
    else {
      props.type == "open" ? updatebarStatus("פתוח") : updatebarStatus("סגור")
      history.goBack()
    }

  };
  const [loading1, setLoading1] = useState(true);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    const query = ref(database, "list/" + props.type + "/check_list");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const logs = [];

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          logs.push(project);
        });
        setList(logs)
        setLoading1(false);
      }
    });
  }, []);

  const handleChangeBar = () => {
    setOk(true);
  };

  return (
    <>
      <div id="page-wrap">
        {loading1 == true ?
          (
            <center id="loading" >
              <RingLoader id="loading" color={"#a57733"} />
            </center>
          )
          :
          (
            <>
              {/* change orde between 0 and 1 in open and close bar */}
              {((props.type === "open" && index === 0) || (props.type == "close" && index == 2)) && <>
                <span className="text_black text2 w400 underline">
                  {type}: </span>
                <br />
                {list.length !== undefined && list.length > 0 &&
                  list.map(item => {
                    return (
                      <>
                        <Checkbox color={'primary'} />
                        <span className="text_black text1_5 w300"> {item}</span>
                        <br />
                      </>
                    )
                  })
                }
                <br />
                <input className='button_sp' type="Button" value={text} onClick={Submit} />
                <br />
              </>}
              {((props.type === "open" && index === 1) || (props.type == "close" && index == 0)) &&
                <>
                  <span className="text_black text2 w400 underline">
                    {type}: </span>
                    {!ok &&
                    <input className='button_sp' type="Button" value={"דלג על שלב זה"} onClick={Submit} />}
                  <br />
                  <br />
                  <StorageBarCheck kind="bar" ok={handleChangeBar} />
                  {ok &&
                    <input className='button_sp' type="Button" value={text} onClick={Submit} />}
                 
                </>}
              {((props.type === "open" && index === 2) || (props.type == "close" && index == 1)) &&
                <>
                  <span className="text_black text2 w400 underline">
                    {type}: </span>
                  <input className='button_sp' type="Button" value={text} onClick={Submit} />
                  <br />
                  <StorageBarCheck kind="storage" />
                  <br />
                </>}
            </>
          )}
      </div>
    </>
  );
}

export default BarControl
