import React from 'react';
import { BiMessage, BiPhone } from 'react-icons/bi';
import { BsPhone } from 'react-icons/bs';
import contact from '../../../image/contactUs.png'

const Trigger = ({ triggerText, buttonRef, showModal }) => {
  return (
    <img src={contact} alt="logo"
      className='contact_us_pic'
      onClick={showModal}
    ></img>

    // <button
    //   className="button_new_account"
    //   ref={buttonRef}
    //   onClick={showModal}
    // >
    //   {triggerText}   
    //   <BiMessage className='icon'/>
    // </button>
  );
};
export default Trigger;
