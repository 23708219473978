import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  updateDoc,
  doc,
  deleteDoc
} from "firebase/firestore";
import {
  ref,
  onValue,
  push,
  update,
  remove,
  getDatabase
} from 'firebase/database';
const firebaseConfig = {
  apiKey: "AIzaSyBMXF4FD_5YKpSbti18qo_eEeCcxJ_SGKk",
  authDomain: "pubcheck-9f89b.firebaseapp.com",
  databaseURL: "https://pubcheck-9f89b-default-rtdb.firebaseio.com",
  projectId: "pubcheck-9f89b",
  storageBucket: "pubcheck-9f89b.appspot.com",
  messagingSenderId: "391332670398",
  appId: "1:391332670398:web:e77634239ffa648e9067ef",
  measurementId: "G-LHWE1YPYFG",
};
let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();
let hour = newDate.getHours();
let minute = newDate.getMinutes();
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);
// Get a database reference to our blog
const referenceProducts = ref(database, 'Products');
const referenceEvents = ref(database, 'Events');
const referenceCart = ref(database, 'Cart');
const referenceCategory = ref(database, 'Category');
const referenceItem = ref(database, 'Storage');
const referenceFavorite = ref(database, 'Favorite');
const referenceCoupon = ref(database, 'Coupon');
const referenceTickets = ref(database, 'Tickets');
const referencePaymentToApprove = ref(database, 'Payment/waiting');
// const referencePaymentApproved = ref(database, 'Payment/approved');
// const referencePaymentDeleted = ref(database, 'Payment/deleted');


const googleProvider = new GoogleAuthProvider();
const version = "3.02";
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const query = ref(database, "Account/" + user.uid);
    return onValue(query, (snapshot) => {
      if (!snapshot.exists()) {
        // addCoupon("צ'ייסר", 0, 1, user.uid, "מתנה לנרשמים חדשים", "", "off")
        //בוטל כי כל החשבונות צריכים לעבור העתקה
        var reference = ref(database, 'Account/' + user.uid);
        update(reference, {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          birthDate: "",
          adress: "",
          phone: "",
          account: "newAccount",
          tax: "false",
          payBox: "false",
          discount: 0,
          kind: 0,
        });
      }
    }, {
      onlyOnce: true
    });
  } catch (err) {
    console.log(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.log(err);
    if (String(err).includes("temporarily disabled")) {
      alert("החשבון נחסם באופן זמני, עקב מספר הקלדות ססמא שגויים")
    }
    else if (String(err).includes("password")) {
      alert("סיסמא לא נכונה")
    }
    else if (String(err).includes("user")) {
      alert("לא נמצא חשבון עם מייל זה")
    }
    else
      alert("תקלת תקשורת, נא לבדוק את חיבור האינטרנט ולנסות שוב")
  }
};
const registerWithEmailAndPassword = async (name, email, password, birthDate, adress, phone) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    var reference = ref(database, 'Account/' + user.uid);
    update(reference, {
      uid: user.uid,
      name: name,
      authProvider: "local",
      email: user.email,
      birthDate: birthDate,
      adress: adress,
      phone: phone,
      account: "newAccount",
      tax: "false",
      payBox: "false",
      discount: 0,
      kind: 0,
    });
    addCoupon("צייסר", 0, 1, user.uid, "מתנה למתחברים", "", "off").then
      (alert("מחכה לך קופון באיזור הפריטים שלי"))
  } catch (err) {
    console.log(err);
    if (String(err).includes("invalid-email")) {
      alert("אימייל לא תקין")
    }
    else if (String(err).includes("weak-password")) {
      alert("אורך סיסמא מינימלי הוא: 6")
    }
    else if (String(err).includes("email-already")) {
      alert("כתובת האימייל נמצאת בשימוש")
    }
    else if (String(err).includes("missing-email")) {
      alert("לא הוכנסה כתובת מייל")
    }
    else {
      alert(err)
      alert("תקלת תקשורת, נא לבדוק את חיבור האינטרנט ולנסות שוב")
    }
  }
};


const removeFromDb = async (category, id) => {
  try {
    const docRef = doc(db, category, id);
    await deleteDoc(docRef);
    // window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

const uploadProduct = async (pic, cat, name, des, price, amount, choices, primary, storage, discount) => {
  try {

    var p = push(referenceProducts);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      pname: name,
      price: price,
      category: cat,
      description: des,
      amount: amount,
      picture: pic,
      choices: choices,
      primary: primary,
      discount: discount,
      storage: storage,
    }).then(alert("מוצר התווסף בהצלחה"));;

  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const uploadItem = async (location, pname, price, priceTax, amount, amountBar, amountMax, amountBarMax) => {
  try {

    var p = push(referenceItem);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      location: location,
      name: pname,
      price: price,
      priceTax: priceTax,
      amount: amount,
      amountBar: amountBar,
      amountBarMax: amountBarMax,
      amountMax: amountMax,
    }).then(alert("פריט התווסף בהצלחה"));;
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const uploadCategory = async (title, sub, pic, location) => {
  try {

    var p = push(referenceCategory);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      title: title,
      subtitle: sub,
      picture: pic,
      location: location,
    }).then(alert("קטגוריה התווספה בהצלחה"));;


  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateCategory = async (key, title, pic, sub, location) => {
  try {
    var reference = ref(database, 'Category/' + key);
    update(reference, {
      uid: key,
      title: title,
      subtitle: sub,
      picture: pic,
      location: location,
    }).then(alert("קטגוריה עודכנה בהצלחה"));;

  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const deletaFromRealTimeDb = (uid, location) => {
  console.log("**********deletaFromRealTimeDb*********")
  console.log(uid, location)
  try {
    const query = ref(database, location + '/' + uid);
    remove(query)
  } catch (err) {
    console.log(err);
    alert(err)
    return false
  }
  return true
};

// console.log("**********checkInventory*********")
// try {
//   const query = ref(database, 'Products/' + uid);
//   return onValue(query, (snapshot) => {
//     const data = snapshot.val();
//     if (snapshot.exists()) {
//       if (data.items) {
//         var items = (data.items);
//         if (data.items.length > 0) {
//           items.map(item => {
//             const query2 = ref(database, 'Storage/' + item.name);
//             var amountNeed = item.amount;
//             return onValue(query2, (snapshot) => {
//               const data1 = snapshot.val();
//               if (snapshot.exists()) {
//                 console.log("**********amount in bar********", data1.amountBar)
//                 console.log("**********amount for product********", amountNeed)
//                 if (data1.amountBar > amountNeed) {
//                   console.log("**********checkInventory*********", "all ok")
//                   return true
//                 }
//                 else {
//                   console.log("**********checkInventory*********", "NOT OK")
//                   return false
//                 }
//               }
//               else {
//                 return false
//               }
//             }, {
//               onlyOnce: true
//             });
//           })
//         }
//       }
//     }
//   }, {
//     onlyOnce: true
//   });
// } catch (err) {
//   console.log(err);
//   alert(err)
// }
// return true


const removeFromStorage = (uid, amount, kind) => {

  console.log("**********removeFromStorage*********")
  try {
    const query = ref(database, kind + '/' + uid);
    onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        var items = (data.items);
        if (items.length > 0) {
          items.map(item => {
            const query2 = ref(database, 'Storage/' + item.name);
            onValue(query2, (snapshot) => {
              const data = snapshot.val();
              if (snapshot.exists()) {
                console.log("Storage Change To", data.amountBar - (amount * item.amount))
                update(query2, { amountBar: data.amountBar - (amount * item.amount) })
              }
            }, {
              onlyOnce: true
            });
            return true;
          })
        }

      }
    }, {
      onlyOnce: true
    });
  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const removeFromStorageTickets = (uid, amount) => {

  console.log("**********removeFromStorageTickets*********")
  console.log(uid, amount)
  try {
    const query = ref(database, 'Events/' + uid);
    onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        var newAmount = parseInt(data.amount) - parseInt(amount);
        updateEventAmount(uid, newAmount)
      }
    }, {
      onlyOnce: true
    });
  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateInventory = (kind, uid, amount) => {
  console.log("**********updateInventory*********")
  try {
    const query = ref(database, 'Products/' + uid);
    if (kind === "storage")
      update(query, { storage: amount })
    else
      update(query, { amount: amount })
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const updateStorage = (uid, amount, amountBar, amountMax, amountBarMax, location) => {
  console.log("**********updateInventory*********")
  try {
    const query = ref(database, 'Storage/' + uid);
    update(query, {
      amount: amount,
      amountBar: amountBar,
      amountMax: amountMax,
      amountBarMax: amountBarMax,
      location: location
    })
  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateStoragebar = (uid, amount) => {
  console.log("**********updateInventory*********")
  try {
    const query = ref(database, 'Storage/' + uid);
    update(query, {
      amountBar: amount
    })
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const updateStorageAmount = (uid, amount) => {
  console.log("**********updateInventory*********")
  try {
    const query = ref(database, 'Storage/' + uid);
    update(query, {
      amount: amount
    })
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const updateTicketUser = (uid, text) => {
  console.log("**********updateInventory*********")
  try {
    const query = ref(database, 'Tickets/' + uid);
    update(query, {
      note: text,
      status: 1
    })
  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateProduct = async (key, pic, cat, name, des, price, amount, choices, primary, items, discount) => {
  // console.log(items)
  try {
    var reference = ref(database, 'Products/' + key);
    update(reference, {
      uid: key,
      pname: name,
      price: price,
      category: cat,
      description: des,
      amount: amount,
      picture: pic,
      choices: choices,
      primary: primary,
      items: items,
      discount: discount,
    }).then(alert("מוצר עודכן בהצלחה"));


  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateEventComing = async (key, comingList) => {
  try {
    var reference = ref(database, 'Events/' + key);
    update(reference, {
      coming: comingList,
    })
    return true
    // .then(alert("יתרת החשבון החדשה : " + newBalance + " שח"));;
  } catch (err) {
    console.log(err);
    alert(err);
    return false;
  }
};



const updateEvent = async (key, pic, name, des, price, date, time, coupon, amount) => {
  try {
    var reference = ref(database, 'Events/' + key);
    update(reference, {
      uid: key,
      pname: name,
      price: price,
      date: date,
      time: time,
      description: des,
      picture: pic,
      coupon: coupon,
      amount: amount,
    }).then(alert("אירוע עודכן בהצלחה"));;
    return true;

  } catch (err) {

    console.log(err);
    alert(err);
    return false;
  }
};

const updateEventAmount = async (key, amount) => {
  try {
    var reference = ref(database, 'Events/' + key);
    update(reference, {
      amount: amount,
    })
    return true;
  } catch (err) {

    console.log(err);
    alert(err);
    return false;
  }
};

const uploadEvent = async (pic, date, time, name, des, price, coupon, amount) => {
  let image;
  if (pic === "") {
    //צריך להחליף תמונה ואז לוודא שאפשר להעלות ללא תמונה
    image = "https://firebasestorage.googleapis.com/v0/b/pubcheck-9f89b.appspot.com/o/menu_images%2F%D7%A7%D7%95%D7%A4%D7%95%D7%9F%20%D7%9E%D7%AA%D7%A0%D7%94.png?alt=media&token=3c7051f4-4438-4632-8674-859b58ef5000"
  }
  else {
    image = pic;
  }
  try {
    var p = push(referenceEvents);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      pname: name,
      price: price,
      date: date,
      time: time,
      description: des,
      picture: image,
      coupon: coupon,
      amount: amount,
    }).then(alert("אירוע התווסף בהצלחה"));;

  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const addToCart = async (key, name, price, amount, uid, email, des, img, kind, id) => {
  // try {
  //   await addDoc(collection(db, "Cart"), {
  //     key: key,
  //     uid: uid,
  //     kind: kind,
  //     email: email,
  //     name: name,
  //     price: price,
  //     amount: amount,
  //     detail: des,
  //     picture: img,
  //     id: id
  //   }).then();
  // } catch (err) {
  //   console.log(err);
  //   alert(err)
  // }


  try {
    var p = push(referenceCart);
    var keyX = p.key
    console.log(keyX)
    update(p, {
      keyUid: keyX,
      key: key,
      uid: uid,
      kind: kind,
      email: email,
      name: name,
      price: price,
      amount: amount,
      detail: des,
      picture: img,
      id: id
    })

  } catch (err) {
    console.log(err);
    alert(err)
  }


};

const addToFavorite = async (name, price, amount, uid, email, des, pic, id) => {
  try {
    var p = push(referenceFavorite);
    var keyX = p.key
    console.log(keyX)
    update(p, {
      keyUid: keyX,
      uid: uid,
      email: email,
      pname: name,
      price: price,
      amount: amount,
      detail: des,
      picture: pic,
      id: id
    })
  } catch (err) {
    console.log(err);
  }
};

const addCoupon = async (name, price, amount, uid, des, pic, status) => {
  try {
    let image;
    if (pic === "") {
      image = "https://firebasestorage.googleapis.com/v0/b/pubcheck-9f89b.appspot.com/o/menu_images%2F%D7%A7%D7%95%D7%A4%D7%95%D7%9F%20%D7%9E%D7%AA%D7%A0%D7%94.png?alt=media&token=3c7051f4-4438-4632-8674-859b58ef5000"
    }
    else {
      image = pic;
    }
    var p = push(referenceCoupon);
    var keyX = p.key
    console.log(keyX)
    update(p, {
      keyUid: keyX,
      uid: uid,
      status: status,
      pname: name,
      price: price,
      amount: amount,
      detail: des,
      picture: image,
      id: "Coupon",
    });
  } catch (err) {
    console.log(err);
  }
};

const CreateMessage = async (uid, kind, title, des, pic, amount, price) => {
  try {
    await addDoc(collection(db, "Messages"), {
      uid: uid,
      kind: kind,
      title: title,
      message: des,
      picture: pic,
      amount: amount,
      price: price,
      date: day + "/" + month + "/" + year,
      time: hour + ":" + minute
    }).then
      (alert("הודעה נשלחה"));
  } catch (err) {
    console.log(err);
  }
};

function showAlert(amount, kind) {
  if (kind === "PayBox") {
    alert("סכום לתשלום: " + amount + "₪");
    window.location.assign('https://payboxapp.page.link/tMUCWvfnFRsiFeHV9');
  }
  else
    alert("הזמנה נשלחה בהצלחה");
}


const completeFullOrder = async (id, name, email, products, amount, kind, tip, discount, note) => {
  try {
    // products.forEach(element => {
    //   removeFromDb("Cart", element.id)
    // });
    await addDoc(collection(db, "ComingOrder"), {
      id: id,
      name: name,
      products: products,
      email: email,
      kind: kind,
      amount: amount,
      tip: tip,
      discount: discount,
      note: note,
    })
  } catch (err) {
    console.log(err);
    alert("תקלת תקשורת, ההזמנה איננה נשלחה בהצלחה");
    return false
  }
  return true
}

const upladNewUser = async (user) => {
  try {
    var reference = ref(database, 'Account/' + user.uid);
    update(reference, {
      uid: user.uid,
      name: user.name,
      authProvider: user.authProvider,
      email: user.email,
      birthDate: user.birthDate,
      adress: user.adress,
      phone: user.phone,
      account: user.account,
      tax: user.tax,
      payBox: user.payBox,
      discount: user.discount,
    });

  } catch (err) {
    console.log(err);
    alert(err)
  }
};


const updateUserInfo = async (uid, name, birthDate, adress, phone) => {
  try {
    var reference = ref(database, 'Account/' + uid);
    update(reference, {
      name: name,
      adress: adress,
      birthDate: birthDate,
      phone: phone,
    }).then(alert("החשבון עודכן בהצלחה"));;

  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const updateUserManager = async (uid, account) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    account: account,
  });
  console.log(uid, account)
};

const updateUserPoints = async (uid, points) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    points: points,
  });
  console.log(uid, points)
};

const uploadPaymentToCheck = async (id, name, email, amount) => {
  try {
    var p = push(referencePaymentToApprove);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      userUid: id,
      userName: name,
      userEmail: email,
      amountToCheck: amount
    }).then(alert("נא להעביר את הסכום המדוייק: " + amount + " ₪"));;
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const uploadTicket = async (eventuid, eventName, date, eventTickets, id, name, email, total, payment) => {
  try {
    var p = push(referenceTickets);
    var key = p.key
    console.log(key)
    update(p, {
      uid: key,
      eventUid: eventuid,
      eventName: eventName,
      eventDate: date,
      eventTickets: eventTickets,
      userUid: id,
      userName: name,
      userEmail: email,
      total: total,
      payment: payment,
      status: 0,
      note: ""
    })
    return key
  } catch (err) {
    console.log(err);
    alert(err)
    return ""
  }
};

const updateUserAccountBalance = async (uid, amount) => {
  try {
    const query = ref(database, "Account/" + uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      const x = parseFloat(data.accountBalance ? data.accountBalance : 0)
      const y = parseFloat(amount)
      const newBalance = x - y;
      var reference = ref(database, 'Account/' + uid);
      update(reference, {
        accountBalance: newBalance,
      })
    }, {
      onlyOnce: true
    });

    // .then(alert("יתרת החשבון החדשה : " + newBalance + " שח"));;
  } catch (err) {
    console.log(err);
    alert(err);
    return false;
  }
};

const updateUserAccountPoints = async (uid, amount) => {
  try {
    const query = ref(database, "Account/" + uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      const x = data.points ? data.points : 0
      const y = parseInt(amount)
      const newBalance = x + y;
      var reference = ref(database, 'Account/' + uid);
      update(reference, {
        points: newBalance,
      })
      // .then(alert("נוספו לחשבון "+amount+" נקודות, מספר הנקודות החדש: "+newBalance +" נקודות"))
    }, {
      onlyOnce: true
    });

    // .then(alert("יתרת החשבון החדשה : " + newBalance + " שח"));;
  } catch (err) {
    console.log(err);
    alert(err);
    return false;
  }
};


const updateTipBalance = async (tip) => {
  try {
    const query = ref(database, "bar");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      const x = parseFloat(data.tips)
      const y = parseFloat(tip)
      const newTips = y + x;
      var reference = ref(database, "bar");
      update(reference, {
        tips: newTips,
      })
    }, {
      onlyOnce: true
    });

    // .then(alert("יתרת החשבון החדשה : " + newBalance + " שח"));;
  } catch (err) {
    console.log(err);
    alert(err);
    return false;
  }
};


const updateTip0 = async () => {
  try {
    var reference = ref(database, "bar");
    update(reference, {
      tips: 0,
    })
    // .then(alert("יתרת החשבון החדשה : " + newBalance + " שח"));;
  } catch (err) {
    console.log(err);
    alert(err);
    return false;
  }
};

const updateUserFromManager = async (uid, account, tax, name, payBox) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    name: name,
    account: account,
    tax: tax,
    payBox: payBox,
  });

};
const updateUserAccount = async (uid, x) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    account: x,
  });

};

const updateUserDiscount = async (uid, x) => {
  var discount = parseInt(x)
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    discount: discount,
  });

};

const updateUserTax = async (uid, x) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    tax: x,
  });

};
const updateUserKind = async (uid, x) => {
  //kind 1==תושב
  // kind 0==אורח
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    kind: x,
  });

};

const updateUserPayBox = async (uid, x) => {
  var reference = ref(database, 'Account/' + uid);
  update(reference, {
    payBox: x,
  })
};

const fetchVersion1 = async () => {
  try {
    const q = query(collection(db, "Version"));
    const doc = await getDocs(q);
    const info = doc.docs[0].data();
    console.log("version: " + version)
    console.log("version_allowed: " + info.version)
    if (!version.includes(info.version)) {
      console.log("refreshing_page")
      alert("גירסה איננה מעודכנת")
      window.location.assign('https://master.dryi50n2djwtc.amplifyapp.com/');
      //updateVersion("0.9")
    }
  } catch (err) {
  }
  return (version)
};


const updateVersion = async (x) => {
  console.log("updateVersion", x)
  var reference = ref(database, 'version');
  update(reference, {
    vers: x,
  })
};

const fetchVersion = async () => {
  try {
    const query = ref(database, "version");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const x = parseFloat(data.vers)
      const y = parseFloat(version)
      if (y < x) {
        console.log("refreshing_page")
        alert("גירסה איננה מעודכנת")
        window.location.assign('https://master.dryi50n2djwtc.amplifyapp.com/');
      }
    }, {
      onlyOnce: true
    });
  } catch (err) {
  }
  return (version)
};


const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email).then(
      alert("לינק לשינוי הססמא נשלח בהצלחה, נא לבדוק את תיקיית הספאם"));
  } catch (err) {
    console.log(err);
    alert("קרתה תקלה, לינק לא נשלח");
  }
};

const logout = () => {
  signOut(auth);
};

const completeOrderFromBar = async (id, name, email, products, amount, kind, tip, discount, barName, emailbar, idbar) => {

  try {
    await addDoc(collection(db, "CompletedOrders"), {
      id: id,
      name: name,
      products: products,
      email: email,
      kind: kind,
      discount: discount,
      amount: amount,
      tip: tip,
      bartender: barName,
      bartenderEmail: emailbar,
      bartenderUid: idbar,
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute
    })
    return true;
  } catch (err) {
    console.log(err);
    alert(err.message);
    return false;
  }
};


const addEmployeeAction = async (uid, email, name, action, amount, nameX, emailx, uidX, note) => {

  try {
    await addDoc(collection(db, "EmployeeAction"), {
      timeStampe: new Date().getTime(),
      uid: uid,
      name: name,
      email: email,
      action: action,
      amount: amount,
      barName: nameX,
      barEmail: emailx,
      barUid: uidX,
      note: note
    })
    return true;
  } catch (err) {
    console.log(err);
    alert(err.message);
    return false;
  }
};

const deleteOrderFromBar = async (id, name, email, products, amount, kind, tip, discount, barName, emailbar, idbar) => {
  try {
    await addDoc(collection(db, "DeletedOrders"), {
      id: id,
      name: name,
      products: products,
      email: email,
      kind: kind,
      discount: discount,
      amount: amount,
      tip: tip,
      bartender: barName,
      bartenderEmail: emailbar,
      bartenderUid: idbar,
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute
    }).then
      (
    );
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

const uploadPaymentApprove = async (id, name, email, amount, manager) => {
  try {
    await addDoc(collection(db, "PaymentsApproved"), {
      timeStampe: new Date().getTime(),
      userUid: id,
      userName: name,
      userEmail: email,
      amountToApprove: amount,
      managerUid: manager.uid,
      managerEmail: manager.email
    }).then(alert("תשלום על סך: " + amount + " אושר בהצלחה על ידי:" + manager.email))

  } catch (err) {
    console.log(err);
    alert(err)
  }
};
const uploadPaymentDelete = async (id, name, email, amount, manager) => {
  try {
    await addDoc(collection(db, "PaymentsDeleted"), {
      timeStampe: new Date().getTime(),
      userUid: id,
      userName: name,
      userEmail: email,
      amountToApprove: amount,
      managerUid: manager.uid,
      managerEmail: manager.email
    }).then(alert("תשלום על סך: " + amount + " בוטל בהצלחה על ידי:" + manager.email))
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const sendMessage = async (uid, name, email, phone, title, message) => {
  try {
    await addDoc(collection(db, "Message"), {
      uid: uid,
      name: name,
      email: email,
      phone: phone,
      title: title,
      message: message
    }).then(alert("הודעה נשלחה בהצלחה"));
  } catch (err) {
    console.log(err);
    alert("תקלת תקשורת, ההודעה איננה נשלחה בהצלחה");
  }
}

const sendEventOrder = async (eventUid, eventName, eventDate, fullName, phoneNumber, paymentMethod, ticketChose, totalPrice) => {
  try {
    await addDoc(collection(db, "EventOrder"), {
      eventName: eventName,
      eventDate: eventDate,
      eventUid: eventUid,
      fullName: fullName,
      paymentMethod: paymentMethod,
      phoneNumber: phoneNumber,
      tickets: ticketChose,
      totalPrice: totalPrice
    })
    return true;
  } catch (err) {
    console.log(err);
    alert("תקלת תקשורת, ההודעה איננה נשלחה בהצלחה");
  }
}

const postPayment = async (data) => {
  console.log("**********post payment***********");
  try {
    const response = await fetch(
      "https://sandbox.payme.io/api/generate-sale", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const uploadList = async (list, type) => {
  try {
    var reference = ref(database, 'list/' + type);
    update(reference, {
      check_list: list,
    }).then(alert("רשימה עודכנה בהצלחה"));;
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const uploadWheelOfLuckList = async (list) => {
  try {
    var reference = ref(database, 'LuckyWheel');
    update(reference, {
      list: list,
    }).then(alert("רשימה עודכנה בהצלחה"));;
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

const updatebarStatus = async (status) => {
  try {
    var reference = ref(database, 'bar');
    update(reference, {
      status: status,
    }).then(alert("הפאב " + status));;
  } catch (err) {
    console.log(err);
    alert(err)
  }
};

export {
  removeFromStorageTickets,
  upladNewUser,
  uploadList,
  uploadWheelOfLuckList,
  updatebarStatus,
  auth,
  db,
  database,
  fetchVersion,
  version,
  updateVersion,
  CreateMessage,
  sendMessage,
  postPayment,
  updateUserPayBox,
  updateUserFromManager,
  uploadEvent,
  updateEvent,
  updateEventComing,
  addCoupon,
  addToFavorite,
  completeOrderFromBar,
  deleteOrderFromBar,
  updateUserTax,
  updateUserKind,
  updateUserAccount,
  updateUserDiscount,
  updateUserInfo,
  updateUserManager,
  updateUserPoints,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  uploadProduct,
  uploadCategory,
  addToCart,
  completeFullOrder,
  removeFromStorage,
  updateCategory,
  updateProduct,
  updateInventory,
  updateStorage,
  updateStorageAmount,
  deletaFromRealTimeDb,
  updateStoragebar,
  updateUserAccountBalance,
  updateUserAccountPoints,
  uploadItem,
  uploadPaymentToCheck,
  uploadPaymentDelete,
  uploadPaymentApprove,
  uploadTicket,
  updateTicketUser,
  updateTipBalance,
  updateTip0,
  addEmployeeAction,
  app,
  sendEventOrder,
  storage,
};