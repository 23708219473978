

import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateStorageAmount, updateStoragebar } from "../../../../firebase";

const InventoryPull = (props) => {

  const history = useHistory();
  
  const [click, setClick] = useState(false);

  const update1 = () => {
    setClick(true);
    let a=parseFloat(props.amount)-1
    updateStorageAmount(props.id, a)
    let ab=parseFloat(props.amountBar)+1
    updateStoragebar(props.id, ab)

    // updateInventory(props.kind, props.id, amount)
  }
  const update6 = () => {

    setClick(true);
    let a=parseFloat(props.amount)-6
    updateStorageAmount(props.id, a)
    let ab=parseFloat(props.amountBar)+6
    updateStoragebar(props.id, ab)
  }

  return (<>
    <div  className={click ? 'storage_box fade_out' : 'storage_box'} onAnimationEnd={() => setClick(false)}>
      <span className="text text2 w600 ">{props.name}  </span><br/>
      <span className="text text1_3 w400 ">  כמות במלאי: {props.amount}  </span>
      <span className="text text1_3 w400 ">כמות בבר: {parseFloat(props.amountBar).toFixed(2)}  </span>
      <button className="storage_button" disabled={click||parseFloat(props.amount)-1<0} onClick={update1}>הוצא 1</button>
      <button className="storage_button" disabled={click||parseFloat(props.amount)-6<0} onClick={update6}>הוצא 6</button>
{/* 

      {props.id !== "Coupon" &&
        <button disabled={click} className="product_button" onClick={remove}> הסר מ-<BiBasket /></button>
      }


    </div>




    <div className="storage_box">
      <div className="text text2 w600 ">{props.name}  </div>
      <div className="text text1_3 w400 ">כמות במלאי: {amount}  </div>
      <div className="text text1_3 w400 ">כמות בבר: {amountBar}  </div>
      <button className="button" onClick={update}>עדכון</button>
      <button className="button width20" onClick={update}>עדכון</button>
      <br />
      {props.location !== "מיקום" &&
        <button className="product_button width40" onClick={update}>עדכון</button>}
         */}
    </div>
  </>
  );
};

export default memo(InventoryPull);
