

import { doc as doc1, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiBasket } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { addToCart, auth, db, deletaFromRealTimeDb, removeFromStorage } from "../../../firebase";

const FavoriteCard = (props) => {

  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);
  useEffect(() => {
    if (loading) return;

  }, [user, loading]);



  const update = () => {
    console.log(props)
    setClick(true);
    if (!user) {
      alert("להוספת פריטים לעגלה, יש להתחבר למערכת");
    }
    else
      addToCart(props.id, props.name, props.price, props.amount, user.uid, user.email, props.des, props.img, props.title, props.id);

    if (props.status === "off") {
      remove();
    }


  }

  const remove = async () => {
    // console.log(props)
    deletaFromRealTimeDb(props.id, props.title)
    // try {
    //   const docRef = doc1(db, props.title, props.id);
    //   await deleteDoc(docRef);



    // } catch (err) {
    //   console.log(err);
    //   alert("אירעה שגיאה");
    // }

  };




  return (
    <>

      <div id="product-master-new" className={click ? 'fade_out' : ''}
        onAnimationEnd={() => setClick(false)}
      >

        <div >
          <div className="split_div">
            <img className="coupon_pic p3" src={props.img} />
            <div className="right_div p3">
              {/* <span className="text_black text2 w500">{props.des}</span> */}
              <span className="product_p1 text_black text2 w600">{props.name}</span>
              <div className="split_div">
                <span className="product_p2 text_black text1_5 w600">כמות: {props.amount} </span>
                <span className="product_p2 text_black text1_5 w500" > - מחיר: {props.price} ₪</span>
              </div>
              {props.des !== "" &&
                <span className="product_p1 text_black text1_5 w500" >הערות: {props.des}</span>}
              {props.title !== "Coupon" &&
                <button className="product_button_new cancel_button1 text1_5 p1" onClick={remove} >מחיקה</button>}
              <button disabled={click} className="product_button_new  primary_button1 text1_5 p1" onClick={update} > הוסף לסל <BiBasket /></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoriteCard;
