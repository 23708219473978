

import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth, database, db, deletaFromRealTimeDb, updateUserAccountBalance, uploadPaymentApprove, uploadPaymentDelete } from "../../../firebase";
import { onValue, ref } from "firebase/database";
import { Table } from "semantic-ui-react";
import { Button, Dialog, DialogContent } from "@mui/material";

const PaymentRow = (props) => {
  const history = useHistory();
  console.log(props.payment)
  let payment = props.payment
  const [user, loading, error] = useAuthState(auth);
  const [barName, setbarName] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(0);
  const [newamountToCheck, setnewBalance] = useState(payment.amountToCheck);


  useEffect(() => {
    if (loading) return;
    else if (user) {
      async function fetchUserName() {
        const query = ref(database, "Account/" + user.uid);
        return onValue(query, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setbarName(data.name);
          }
        }, {
          onlyOnce: true
        });
      };
      fetchUserName();
    }
  }, [user, loading]);

  useEffect(() => {
    const query = ref(database, "Account/" + payment.userUid);
    return onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data.accountBalance)
        setBalance(data.accountBalance)
      }
    });
  }, []);

  const confirmPayment = () => {
    console.log(balance, payment.amountToCheck)
    updateUserAccountBalance(payment.userUid, -1*payment.amountToCheck).then(
      uploadPaymentApprove(payment.userUid, payment.userName, payment.userEmail, payment.amountToCheck, user)
    ).then(
      deletaFromRealTimeDb(payment.uid, "Payment/waiting")
    )
  }
  const onApproval = () => {
    handleClose()
    updateUserAccountBalance(payment.userUid, -1*payment.amountToCheck).then(
      uploadPaymentApprove(payment.userUid, payment.userName, payment.userEmail, newamountToCheck, user)
    ).then(
      deletaFromRealTimeDb(payment.uid, "Payment/waiting")
    )
  }

  const removePayment = () => {
    uploadPaymentDelete(payment.userUid, payment.userName, payment.userEmail, payment.amountToCheck, user)
      .then(
        deletaFromRealTimeDb(payment.uid, "Payment/waiting")
      )
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (<>
    <Table.Row>
      <Table.Cell>{payment.userName}</Table.Cell>
      <Table.Cell>{payment.amountToCheck} ₪</Table.Cell>
      <Table.Cell selectable>
        <button className="button" onClick={handleClickOpen}>
          אישור סכום אחר
        </button>
        <button className="button" onClick={confirmPayment}>
          אישור
        </button>
        <button className="button" onClick={removePayment}>
          ביטול
        </button>
      </Table.Cell>
    </Table.Row>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <br />
      <div className='text_rtl text1_5 w300'>

        <div className='text_center text2 w300'>איזה סכום תרצה לאשר? </div>

      </div>
      <DialogContent>
        <div className='text_center'>
          <input
            type="number"
            className="__textBoxamount"
            value={newamountToCheck}
            onChange={(e) => setnewBalance(e.target.value)}
            placeholder={newamountToCheck}
          /> ₪
        </div>
      </DialogContent>

      <Button
        className='text_center'
        style={{ marginLeft: "10px", marginRight: "10px" }}
        variant="outlined"
        onClick={onApproval}
        color="primary"
      >
        אישור
      </Button>
    </Dialog>
  </>
  )
};

export default PaymentRow;
