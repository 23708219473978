

import { get, onValue, ref } from "firebase/database";
import { useRef } from "react";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiBasket } from "react-icons/bi";
import { FaEye, FaEyeSlash, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { addToCart, auth, database } from "../../../firebase";

const ProductCard = (props) => {
  const objectRef = useRef(null);
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [items, setItems] = useState(props.items);
  const [inStock, setInStock] = useState(true);
  const [discount, setDiscount] = useState(0);


  useEffect(() => {
    if (loading) return;
    if (items && items.length > 0) {
      items.map(item => {
        CheckInventory(item.name, item.amount)
      })
    }
    async function fetchUserName() {
      const query = ref(database, "Account/" + user.uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setDiscount(data.discount?data.discount:0);
        }
      }, {
        onlyOnce: true
      });
    };
    if (user)
    fetchUserName()

  
  }, [user, loading]);


  const CheckInventory = async (id, amount) => {
    // console.log(id)
    // console.log(amount)
    get(ref(database, `Storage/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        let value = snapshot.val()
        // console.log(value)
        if ((parseFloat(value.amount) + parseFloat(value.amountBar)) > parseInt(amount)) {
          // console.log(value.name,"true")
        }
        else {
          setInStock(false)
          console.log(value.name, "false")
        }
      } else {
        setInStock(false)
      }
    }).catch((error) => {
      console.error(error);
    })
  }

  const update = () => {
    setClick(true);
    if (!user) {
      alert("להוספת המוצר לעגלה, יש להתחבר למערכת");
    }

    else {
      console.log(props)
      addToCart(props.id, props.name, count * props.price, count, user.uid, user.email, "", props.img, "Products", props.id);
    }
  }

  const watch = () => {
    console.log(props.key)
    history.push("/menu/" + (props.id));
  }

  let [count, setCount] = useState(1);
  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    if (count !== 1) count = count - 1;
    setCount(count);
  }

  if (objectRef.current) {
    objectRef.current.style.top = objectRef.current.offsetTop + "px";
    objectRef.current.style.left = objectRef.current.offsetLeft + "px";
  }

  return (

    <>
      {/* {!inStock &&
        <div id="product-master" className={click ? 'fade' : ''}
          onAnimationEnd={() => setClick(false)}
        >
          <div >
            <img className="product_pic_out" src={props.img} />
            <br />
            <span className="product_name">{props.name}</span>
            <br />
            <span className="product_price" >מחיר: {props.price} ₪</span>
            <br />
            <span className="product_name" >חסר במלאי</span>
          </div>


        </div>} */}

      {inStock &&
        <div id="product-master-new" ref={objectRef} className={click ? 'swipe-fade' : ''}
          onAnimationEnd={() => setClick(false)}
        >
          <div >
            <div className="split_div">
              <img className="product_pic" src={props.img} onClick={watch} />
              {/* <br /> */}
              {/* <button disabled={click} className="product_button_watch text1" onClick={watch} >צפייה<br /><FaEye /></button> */}

              <div className="right_div">
                {/* <span className="text_black text2 w500">{props.des}</span> */}

                <span className="text_black text1_9 w600">{props.name}</span>
                <div>
                  <b className="count_icon_new" onClick={decrementCount}><FaMinusCircle /></b>
                  <a className="product_amount text_black text2 w500">{count}</a>
                  <b className="count_icon_new" onClick={incrementCount}><FaPlusCircle /></b>
                </div>
              </div>
              <div className="left_div">
                {discount === 0 &&
                  <span className="text_black text_sub text1_5" >מחיר: {props.price * count} ₪</span>}
                {discount > 0 &&
                  <>
                    <span className="text_black  text_sub text1_5" >
                      מחיר: <span className="text_black line_middle text_sub text1_5" >{props.price * count} ₪
                      </span>
                    </span>
                    <span className="text_transparent  text_sub text1_5" >
                      מחיר: <span className="text_black text_sub text1_5" >{props.price * count *(100-discount)/100} ₪
                      </span>
                    </span>
                  </>}
                <div>
                  {(!props.discount || props.discount == 0) &&
                    <button disabled={click} className="product_button_new text1_2" onClick={update} >הוסף לסל <BiBasket /></button>}
                  {(props.discount || props.discount > 0) &&
                    <button disabled={click} className="product_button_new text1_3" onClick={() => watch()} >בחירת פריטים</button>}
                </div>
              </div>
            </div>
          </div>

          
        </div>}
        
    </>
  );
};

export default memo(ProductCard);
