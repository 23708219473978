import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { uploadItem } from '../../../../firebase';
import { Label } from 'semantic-ui-react';
import Navbar from '../../../sidebar/sidebar';


function NewStorageForm() {

  const [pname, setPName] = useState("");
  const [price, setPrice] = useState("");
  const [priceTax, setPriceTax] = useState("");
  const [amount, setAmount] = useState("");
  const [amountBar, setAmountBar] = useState("");
  const [amountMax, setAmountMax] = useState("");
  const [amountBarMax, setAmountBarMax] = useState("");
  const [location, setLocation] = useState("");
  const history = useHistory();

  // function to update state of name with
  // value enter by user in form

  const managment = () => {
    history.push("/xmanagment")
  }

  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    e.preventDefault();
    if (
      pname === ""
    ) alert("מידע חסר");
    else {
      uploadItem(location, pname, price, priceTax, amount, amountBar, amountMax, amountBarMax)
    }
  };


  return (
    <>
      <div id={"page-wrap"} >
        <div id="page">
          <h3> הוספת פריט מחסן </h3>

          <Label>מספר סידורי</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />

          <Label>שם פריט</Label>
          <input
            type="text"
            className="__textBox"
            value={pname}
            onChange={(e) => setPName(e.target.value)}
            placeholder="שם מוצר"
          />
          <br />

          <Label>מחיר עלות</Label>
          <input
            type="double"
            placeholder="Enter Price"
            className="__textBox"
            value={price}
            onChange={(e) => {
              setPriceTax(e.target.value * 1.17);
              setPrice(e.target.value);
            }}
          /><br />
          <Label>מחיר עלות כולל מע"מ</Label>
          <input
            type="double"
            placeholder="Enter Price"
            className="__textBox"
            value={priceTax}
            onChange={(e) => {
              setPriceTax(e.target.value);
              setPrice(e.target.value / 1.17);
            }}
          /><br />

          <Label>כמות במחסן</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Label>כמות בבר</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={amountBar}
            onChange={(e) => setAmountBar(e.target.value)}
          />
          <Label>כמות מקסימום למחסן</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={amountMax}
            onChange={(e) => setAmountMax(e.target.value)}
          />
          <Label>כמות מקסימום לבר</Label>
          <input
            type="number"
            placeholder="Enter amount"
            className="__textBox"
            value={amountBarMax}
            onChange={(e) => setAmountBarMax(e.target.value)}
          />
          <br />
          <input className='button' type="Button" value="הוסף פריט" onClick={Submit} />
          <br />
          <input className='button' type="Button" value="חזור לתפריט מנהל" onClick={managment} />
        </div>
      </div>
    </>
  );
}

export default NewStorageForm
