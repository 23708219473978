import RingLoader from "react-spinners/RingLoader";
import Select from "react-select";
import { useEffect, useState } from "react";
import { database, db, updateProduct, uploadProduct } from "../../../../firebase";
import { getStorage, ref, listAll, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Label } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import Navbar from "../../../sidebar/sidebar";
import { onValue, ref as ref1 } from "firebase/database";
import Choices from "./Choices";
import InventoryChoices from "./InventoryChoices";

const ProductEdit = () => {

  const storage = getStorage();
  const uid = window.location.pathname.replace("/xmanagment/menuedit/", "");
  const history = useHistory();
  const [percent, setPercent] = useState(0);
  const [picture, setPic] = useState('');
  const [discount, setDiscount] = useState(0);
  const [loading1, setLoading1] = useState(true);
  const [newName, setnewName] = useState('');
  const [pcat, setPCat] = useState('');
  const [newprice, setnewPrice] = useState('');
  const [newamount, setnewAmount] = useState('');
  const [newdescription, setnewDes] = useState('');
  const [categories, setCategories] = useState([]);

  const [choices, setChoices] = useState([]);
  const [primary, setPrimary] = useState("");
  const [items, setItems] = useState([]);
  const [storageX, setStorage] = useState([]);


  const handleImg = (e) => {
    const file = e.target.files[0];
    uploadImg(file);
  };

  const uploadImg = (file) => {
    if (!file) {
      alert("Empty Image");
    } else {
      const storageRef = ref(storage, "/menu_images/" + file.name);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on("state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(upload.snapshot.ref).then((url) => {
            setPic(url);
          });
        });
    }
  };


  useEffect(() => {
    const query = ref1(database, "Products/" + uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log(snapshot);
      if (snapshot.exists()) {
        setnewAmount(data.amount);
        setnewDes(data.description);
        setPic(data.picture);
        setnewName(data.pname);
        setnewPrice(data.price);
        setLoading1(false);
        setPCat(data.category);
        if (data.choices)
          setChoices(data.choices);
        if (data.discount)
          setDiscount(data.discount);
        if (data.primary)
          setPrimary(data.primary);
        if (data.items)
          setItems(data.items);


      }
    }, {
      onlyOnce: true
    });

  }, []);

  useEffect(() => {
    const query = ref1(database, "Category");
    return onValue(query, (snapshot) => {
      setCategories([]);
      const data = snapshot.val();
      const logs = [];

      if (snapshot.exists()) {

        Object.values(data).map((project) => {
          const data1 = {

            value: project.uid,
            label: project.title,

          };
          logs.push(data1);

          // if (String(project.category).includes(id))

        });
        console.log("Category", logs)
        setCategories(logs);

      }

    });
  }, []);


  useEffect(() => {
    const query = ref1(database, "Storage");
    return onValue(query, (snapshot) => {
      setStorage([]);
      const data = snapshot.val();
      const logs = [];
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          const data1 = {
            value: project.uid,
            label: project.name,
          };
          logs.push(data1);
          // if (String(project.category).includes(id))
        });
        console.log("Storage", logs)
        setStorage(logs);
      }
    });
  }, []);

  const handleCategoryChange = (newValue1) => {
    setPCat(newValue1.value);
    console.log(newValue1.label);
  };




  const update = () => {

    updateProduct(uid, picture, pcat, newName, newdescription, newprice, newamount, choices, primary, items, discount);
  }
  const duplicate = () => {

    uploadProduct(picture, pcat, newName, newdescription, newprice, newamount, choices, primary, items, discount).then(alert("מוצר שוכפל בהצלחה")).then(history.goBack());

  }


  const handleSubmitarray = (choices, answer) => e => {
    e.preventDefault();
    setChoices(choices)
    setPrimary(answer)
    console.log(choices, answer);
  };
  const handleSubmitarrayItems = (choices) => e => {
    e.preventDefault();
    setItems(choices)
    console.log(choices);
  };

  return (
    <>
      <div id={"page-wrap"} className="top_padding" >
        <div id="page">
          {loading1 === true && <RingLoader id="loading" color={"#a57733"} />}
          {loading1 === false && <>
            <Label>בחירת תמונה</Label>
            <input
              className="__textBox"
              type="file"
              placeholder="Enter Product Image"
              name="pimg"
              defaultValue={""}
              onChange={handleImg}
            /><br />העלאת קובץ: {percent}%

            <Label>תצוגת תמונה</Label>
            <img src={picture} width={300} />

            <br />
            <Label>קטגוריה בתפריט</Label>
            <Select
              value={pcat}
              onChange={handleCategoryChange}
              options={categories}
              placeholder="הקלד או בחר קטגוריה"
            />

            <Label>שם מוצר</Label>
            <input
              type="text"
              className="__textBox"
              value={newName}
              onChange={(e) => setnewName(e.target.value)}
              placeholder="שם מוצר"
            />
            <br />
            <Label>פירוט</Label>
            <textarea
              placeholder="Product Description"

              className="__textBox"
              value={newdescription}
              onChange={(e) => setnewDes(e.target.value)}
            /><br />
            <Label>האם זה מבצע?</Label>
            <input
              type="number"
              placeholder="מה מספר הפריטים שיש לבחור"
              className="__textBox"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            /><br />
            {choices.length !== undefined && choices.length > 0 &&
              <Label>סך כל האפשרויות לבחירה בפריט זה: {choices.length}</Label>
            }

            <Choices
              choices={choices}
              answer={primary}
              handleSubmit={handleSubmitarray}
            />
            <br />
            {items.length !== undefined && items.length > 0 &&
              <Label>סך כל המרכיבים לבחירה בפריט זה: {items.length}</Label>
            }

            <InventoryChoices
              choices={items}
              handleSubmit={handleSubmitarrayItems}
              items={storageX}
            />

            <Label>מחיר</Label>
            <input
              type="number"
              placeholder="Enter Price"

              className="__textBox"
              value={newprice}
              onChange={(e) => setnewPrice(e.target.value)}
            /><br />

            <Label>כמות במלאי</Label>
            <input
              type="number"
              placeholder="Enter amount"

              className="__textBox"
              value={newamount}
              onChange={(e) => setnewAmount(e.target.value)}
            />
            <br />
            <button className="product_button_view" onClick={update}> עדכון פרטי פריט</button>

            <button className="product_button_view" onClick={duplicate}> שכפול עם פירוט זה</button>

          </>}

        </div>
      </div>
    </>
  )


}
export default ProductEdit
