import React from "react";

export const formatChoices = choices => {
    return choices;
};




class EventChoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.choices
        };
    }
    handlePriceChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, price: e.target.value };
        });
        this.setState({ choices: newChoices });
    };
    handleNameChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, name: e.target.value };
        });
        this.setState({ choices: newChoices });
    };
    handleAmountChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, amount: e.target.value };
        });
        this.setState({ choices: newChoices });

    };

    handleRemoveChoice = index => () => {
        this.setState({
            choices: this.state.choices.filter((choice, sidx) => index !== sidx)
        });
    };

    handleAddChoice = () => {
        this.setState({
            choices: this.state.choices.concat([{ name: "", price: "", amount: "" }])
        });
    };




    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                {
                    choices.map((choice, index) => (
                        <div key={["choice", index].join("__")} className={choice.amount==""?"red":""}>
                            <input
                                type="text"
                                className="__textBoxSmall"
                                value={choice.name}
                                onChange={this.handleNameChange(index)}
                                onMouseLeave={handleSubmit(
                                    formatChoices(this.state.choices)
                                )}
                                placeholder="סוג כרטיס"
                            />
                            <input
                                name="number"
                                className="__textBoxNatural"
                                value={choice.price}
                                onChange={this.handlePriceChange(index)}
                                onMouseLeave={handleSubmit(
                                    formatChoices(this.state.choices)
                                )}
                                placeholder="מחיר כרטיס"
                            />
                            <select
                                onChange={this.handleAmountChange(index)}
                                // onChange={(e) => updateUserKind(rowData[8], e.target.value)}
                                className="__text"
                                value={choice.amount}
                            >
                                <option value="">מחיר עבור?</option>
                                <option value={1}>תושב/חבר</option>
                                <option value={0}>אורח</option>
                                <option value={9}>כולם</option>
                            </select>
                            {/* <input
                                name="number"
                                className="__textBoxNatural"
                                value={choice.amount}
                                onChange={this.handleAmountChange(index)}
                                onMouseLeave={handleSubmit(
                                    formatChoices(this.state.choices)
                                )}
                                placeholder="כמות כרטיסים מסוג זה"
                            /> */}
                            <button
                                className="button"
                                onClick={this.handleRemoveChoice(index)}
                                type="submit" >
                                מחיקה
                            </button>
                        </div>
                    ))
                }
                <button className="button" type="button" onClick={this.handleAddChoice}>
                    הוסף כרטיס
                </button>
                <button className="button_b" type="submit">רענן</button>
            </form>
        );
    }
}

export default EventChoices;
