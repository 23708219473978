

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, database, db, sendMessage } from "../../firebase";
import Navbar from "../sidebar/sidebar";
import { useAuthState } from "react-firebase-hooks/auth";
import Logo from "../../image/logo.png"
import "./style.css"
import ButtomPage from "../buttomPage";
import { onValue, ref } from "firebase/database";
import { WeelOfLuck } from "../weelOfLuck/weelofluck";
import Wheelbutton from "../weelOfLuck/wheelbutton";

const Terms_and_conditions = () => {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const [account, setAccount] = useState("");

    useEffect(() => {
        async function fetchUserName() {
            const query = ref(database, "Account/" + user.uid);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setAccount(data);
                }
            }, {
                onlyOnce: true
            });
        };
        if (loading) return;
        else if (!user) setName("אורח");
        else {
            fetchUserName();
        }
    }, [user, name]);

    const onSubmit = (event) => {
        console.log("send message");
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage(account.uid, account.name, account.email, account.phone, title, des);
        }
    };

    const onSubmitNew = (event) => {
        event.preventDefault(event);
        let title = (event.target.messagetitle.value);
        let des = (event.target.messagetext.value);
        let name = (event.target.username.value);
        let email = (event.target.useremail.value);
        let phone = (event.target.userphone.value);
        if (title === "" || des === "") {
            alert("אחד הפרטים ריק")
        }
        else {
            sendMessage("NoAccount", name, email, phone, title, des);
        }
    };

    return (
        <>
            <div id="page-wrap" className='background top_padding' >
                <center>
                    <div id="personal-wrap">
                        <h id="product-title">
                        
                        </h>
                    <div id="terms-wrap">
                        <h2>תנאים והגבלות לפאב גבעון החדשה</h2>
                        <p>התנאים וההגבלות הללו חלים על השימוש באתר הפאב גבעון החדשה ועל כל רכישה המתבצעת דרכו. על ידי גישה לאתר וביצוע רכישה, אתה מסכים לתנאים הללו.</p>

                        <h3>מוצרים ושירותים:</h3>
                        <p>כל המוצרים והשירותים ניתנים מיד עם הרכישה ונצרכים במקום. לאחר קבלת המוצר, אין אפשרות לביטול או החזר. עבור כרטיסים לאירועים, ניתן לבצע ביטולים והחזרים עד 5 ימים לפני תאריך האירוע.</p>

                        <h3>תשלום:</h3>
                        <p>התשלום עבור מוצרים ושירותים מתבצע בזמן הרכישה. אמצעי התשלום המתקבלים יוצגו באתר ובנקודת המכירה.</p>

                        <h3>ביטול והחזרים לאירועים:</h3>
                        <p>כרטיסים לאירועים ניתנים לביטול והחזר עד 5 ימים לפני האירוע. ההחזרים יתבצעו באמצעות אמצעי התשלום המקורי.</p>

                        <h3>אחריות:</h3>
                        <p>הפאב אינו אחראי לכל אובדן או נזק הנובע משימוש באתר או מרכישה שבוצעה דרכו, אלא אם כן נדרש לפי החוק. אחריות הפאב לכל טענה מוגבלת לסכום ששולם על ידי הלקוח עבור המוצר או השירות הנדון.</p>

                        <h3>פרטיות:</h3>
                        <p>הפאב מכבד את פרטיותך ומחויב להגן על המידע האישי שלך. אנא עיין במדיניות הפרטיות שלנו לפרטים על איך אנו אוספים, משתמשים ומגנים על המידע שלך.</p>

                        <h3>שינויים בתנאים:</h3>
                        <p>הפאב שומר לעצמו את הזכות לשנות את התנאים הללו בכל עת. השינויים יהיו בתוקף מיד עם פרסומם באתר. המשך השימוש באתר ורכישות לאחר פרסום השינויים מהווים את הסכמתך לתנאים החדשים.</p>

                        <h3>מידע ליצירת קשר:</h3>
                        <p>לשאלות או חששות בנוגע לתנאים הללו, אנא פנה אלינו במידע יצירת קשר המופיע באתר.</p>
                    </div>
                    </div>
                </center>

                <br /><br />
                <ButtomPage />
                {/* <Wheelbutton/> */}
            </div>

        </>
    );



}
export default Terms_and_conditions