
import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import { updateUserPayBox } from "../../../firebase";
import Navbar from "../../sidebar/sidebar";
import Container from "../../aboutUs/components/container";



const PayBox = (props) => {


    const history = useHistory();
    let stateData = props.location.state

    const [checked, setChecked] = useState(false);
    var uid = stateData["uid"]
    var phone = stateData["phone"]
    console.log(String(phone).length < 5)
    console.log(phone)
    console.log(uid)

    const doPayBox = async () => {
        if (checked === true)
            updateUserPayBox(uid, "true").then(history.goBack());
        else (alert("תקנון לא אושר"))
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };


    return (
        <>

            {String(phone).length < 5 &&
                <Container triggerText={"מספר הטלפון איננו מעודכן"} kind={"welcomeminus"}></Container>
            }
            <center id="page-wrap" className="top_padding background">
                <h1 >תקנון תשלום:</h1>
                <center>
                    <div id="personal-wrap" className="background_white">
                        {/* <text id="title1"> 1. בעת בחירת "PayBox" כאופן התשלום הרצוי, עליך להכנס לאפליקציה, ולהעביר את הסכום המדוייק כפי שמופיע בתום אישור ההזמנה, בקבוצת התשלום ״פאב גבעון החדשה״.</text><br /> */}
                        <text id="title1"> 1. התשלום בפאב מתבצע מתוך היתרה האישית שלך, אותה ניתן לראות באיזור האישי</text><br />
                        <text id="title1">
                            2. לאחר ביצוע הזמנה, החשבון יכנס למינוס זמני עד הסדרת התשלום באפליקציית PayBox
                        </text><br />
                        <text id="title1red">
                            3. אני מתחייב/ת לוודא בסוף כל ערב שיתרת החשבון באיזור האישי גדולה מ0 ואפעל להעביר את התשלום בהתאם
                        </text><br />
                        <text id="title2">
                            * השימוש באפליקצית פייבוקס באחריות בנק דיסקונט לישראל ושופרסל בע"מ
                        </text><br />
                    </div>

                    {String(phone).length > 5 &&
                        <>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} color={'primary'} />}
                                label="אישור תקנון"
                            />
                            <div onClick={() => doPayBox()} id="payment-title">שמירה וחזרה</div>
                        </>}
                    {String(phone).length < 5 &&
                        <div id="payment-title" className="red">לא ניתן לאשר תקנון</div>}
                </center>

            </center>
        </>
    );



}
export default PayBox