import { onValue, ref } from 'firebase/database';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { RingLoader } from 'react-spinners';
import { Label } from 'semantic-ui-react';
import { database, uploadWheelOfLuckList } from '../../../firebase';
import ListChoices from './listChoices';

function LuckListManage() {
    //   var type = props.type == "open" ? ("פתיחת בר") : ("סגירת בר")
    //   var text = "עדכן רשימה עבור- " + type

    const [list, setList] = useState([]);
    const [storageX, setStorage] = useState([]);
    const history = useHistory();

    const managment = () => {
        history.push("/xmanagment")
    }

    // below function will be called when user
    // click on submit button .
    const Submit = async (e) => {
        e.preventDefault();
        if (
            list.length === 0
        ) alert("מידע חסר");
        else {
            // console.log(props.type)
            uploadWheelOfLuckList(list)
        }
    };

    const handleSubmitarrayItems = (choices) => e => {
        e.preventDefault();
        let x = [];
        choices.map(item => {
            if (item.product.pname)
                x.push(item)
        })
        setList(x)
        console.log(x);
    };

    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [comingList, setComingList] = useState([]);

    useEffect(() => {
        const query = ref(database, "LuckyWheel/list");
        return onValue(query, (snapshot) => {
            setComingList([]);
            const data = snapshot.val();
            if (snapshot.exists()) {
                setComingList(data);
            }

            setLoading1(false);
        }, {
            onlyOnce: true
        });

    }, []);

    useEffect(() => {
        const query = ref(database, "Products");
        return onValue(query, (snapshot) => {
            setStorage([]);
            const data = snapshot.val();
            if (snapshot.exists()) {

                Object.values(data).map((project) => {
                    project.value = project.uid
                    project.label = project.pname
                    setStorage((projects) => [...projects, project]);
                    // console.log(project)
                    // const data1 = {
                    //     value: project.uid,
                    //     label: project.pname,
                    //     picture: project.picture,
                    //     picture: project.picture,
                    //     picture: project.picture,
                    //     picture: project.picture,
                    // };
                    // logs.push(data1);
                    // if (String(project.category).includes(id))

                })
                setLoading2(false)

                // setStorage(logs);
            }
        }, {
            onlyOnce: true
        });
    }, [])


    return (
        <>
            {loading1 && !loading2 ?
                (
                    <center id="loading" >
                        <RingLoader id="loading" color={"#a57733"} />
                    </center>
                )
                :
                (
                    <>
                        <h3> עריכת חלקים גלגל המזל</h3>
                        <br />
                      
                            <ListChoices
                                choices={comingList}
                                handleSubmit={handleSubmitarrayItems}
                                items={storageX}
                            />

                        <br />
                        {list.length !== undefined &&
                            <Label>סך כל הפריטים בגלגל: {list.length}</Label>
                        }
                        <input className='welcome_button' type="Button" value={"עדכן את גלגל המזל"} onClick={Submit} />
                        <br /><br /><br /><br />
                    </>
                )}
        </>
    );
}

export default LuckListManage
