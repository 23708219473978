import React from "react";
import Select from "react-select";



export const formatChoices = choices => {
    return choices;
};

export const customStyles = {
    control: (base, state) => ({
        ...base,
        margin: "10px",
        width: "max-content",
        color: "#914d03",
        background: "transparent",
        // match with the menu
        // borderRadius: state.isFocused ? "10px 10px 0 0" : 10,
        borderRadius: 10,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "yellow" : "#914d03",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "red" : "blue"
        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
        color: "914d03" // Custom colour
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "914d03" // Custom colour
    })
};




class Choices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.choices
        };
        console.log(props)
    }

    handleChoiceChange = (value, index) => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, name: value.label };
        });
        this.setState({ choices: newChoices });
        this.props.handleSubmit(
            formatChoices(this.state.choices)
        )
    };


    handleRemoveChoice = index => () => {
        this.setState({
            choices: this.state.choices.filter((choice, sidx) => index !== sidx)
        });
    };

    handleAddChoice = () => {
        this.setState({
            choices: this.state.choices.concat([{ name: "" }])
        });
    };

    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;
        const { items } = this.props;
        const { length } = this.props;

        if (choices.length < length) {
            this.handleAddChoice()
        }

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                <div className="split_div_menu">
                    {choices.map((choice, index) => (
                        <>
                            {/* 
                        <select
                            value={choice.label}
                            className="__textBoxNewProduct text_center text_black text2"
                            type="submit"
                            onChange={(e) => this.handleChoiceChange(e.target.value, index)}
                        >
                            <option value={""}>נא לבחור</option>
                            {items.map(x => {
                                return (
                                    <option value={x}>{x}</option>
                                )
                            })}
                        </select> */}

                            <Select
                                className="text_brown text1_5 w500"
                                placeholder={"נא לבחור"}
                                value={choice.label}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={true}
                                isSearchable={false}
                                options={items.map(x => {
                                    return ({
                                        value: x,
                                        label: x
                                    })
                                })}
                                styles={customStyles}
                                onChange={(e) => this.handleChoiceChange(e, index)}
                            />
                        </>
                    ))}
                </div>
            </form>
        );
    }
}

export default Choices;
