import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import MessageCard from "./messageCard";


const ComingMessages = () => {
  
    const [loading1, setLoading1] = useState(true);
    const [comingOrders, setComingOrders] = useState([]);


    const fetchOrders = async () => {
       

        
        try {
          const q = collection(db, "Message");
          const doc = await getDocs(q);
          const logs = [];
        
          doc.forEach(data => {
            
                const data1 = {
                  id:data.id,
                  name: data.data().name,
                  email: data.data().email,
                  uid: data.data().uid,
                  phone: data.data().phone,
                  title: data.data().title,
                  message: data.data().message,
                };
                logs.push(data1);
                            
            });
             
        setComingOrders(logs);

        } catch (err) {
          console.log(err);
          alert(err);
        }
        setLoading1(false);
      };

      useEffect(() => {
        fetchOrders();
      }, [comingOrders]);


return(
    <>
         
          {loading1==true ||comingOrders.length === 0 ? (
            <center id="loading" >
             <RingLoader id="loading" color={"#a57733"} />
            </center>
          ) : (
            <div id="page-menu">
               
                  {comingOrders.map((products, i) => (
                    <MessageCard
                      id={products.id}
                      uid={products.uid}
                      name={products.name}
                      email={products.email}
                      phone={products.phone}
                      title={products.title}
                      message={products.message}
                    />
                  ))}
               </div>
          )}
    </>
);



}
export default ComingMessages