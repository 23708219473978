

import { useEffect, useState } from "react";
import { doc as doc1, deleteDoc, query, collection, where, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth, completeOrderFromBar, database, db, deleteOrderFromBar, getAmountFromStorage, removeFromStorage, updateProductAmount, updateTipBalance, updateUserAccountBalance, updateUserAccountPoints } from "../../../firebase";
import { onValue, ref } from "firebase/database";
import { Button, Dialog } from "@material-ui/core";
import { StarPurple500 } from "@mui/icons-material";
import { FaUser } from "react-icons/fa";

const OrderCard = (props) => {
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [barName, setbarName] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(0);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (loading) return;
    else if (user) {
      async function fetchUserName() {
        const query = ref(database, "Account/" + user.uid);
        return onValue(query, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setbarName(data.name);
          }
        }, {
          onlyOnce: true
        });
      };
      fetchUserName();
    }
  }, [user, loading]);

  useEffect(() => {
    const query = ref(database, "Account/" + props.uid);
    return onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data.accountBalance)
        setBalance(data.accountBalance)
      }
    }, {
      onlyOnce: true
    });
  }, []);

  const remove = async () => {
    setOpen(false);
    let tips = props.tip
    if (props.kind === "חשבון הבית") {
      tips = 0;
    }
    try {
      let totalPrice = (parseFloat(tips) + props.price);
      await deleteOrderFromBar(props.uid, props.name, props.email, props.product, totalPrice, props.kind, props.tip, props.discount, barName, user.email, user.uid);
      const docRef = doc1(db, "ComingOrder", props.id);
      await deleteDoc(docRef);
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
  };

  const orderConfirm = async () => {
    setClick(true)
    const tips = props.tip
    const totalPrice = (parseFloat(tips) + props.price);
    if (props.kind === "חשבון הבית") {
      tips = 0;
    }
    const points = parseInt(totalPrice / 10)
    try {

      if (props.kind === "יתרת חשבון") {
        console.log("newBalance", totalPrice);
        updateUserAccountBalance(props.uid, totalPrice)
      }
      updateUserAccountPoints(props.uid, points);
      if (tips > 0)
        updateTipBalance(tips);
      completeOrderFromBar(props.uid, props.name, props.email, props.product, totalPrice, props.kind, tips, props.discount, barName, user.email, user.uid);
      props.product.forEach(p => {
        console.log(p.id, p.amount, p.kind)
        removeFromStorage(p.id, p.amount, p.kind)
      });
      const docRef = doc1(db, "ComingOrder", props.id);
      deleteDoc(docRef);
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
    setClick(false)
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div id="product-master">
        <button className="cancel_button text1 button" onClick={handleClickOpen}> ביטול הזמנה</button>
        <span id="title1">{props.name} {props.discount >= 20 ? <StarPurple500 /> : <FaUser />}<br />
          <span className="text_red" >
            {props.kind == "חשבון הבית" ? "על חשבון הבית" : <>
              <span className="product_price text_black" >מחיר: {props.price} ₪</span>
              <br/>
              <span className="product_price text_black" >טיפ: {props.tip} ₪</span>
            </>}
          </span>
        </span>
        {/* <br />
        <span className="product_price" >הנחה: {props.discount} %</span> */}
        {/* <span className="product_price" >אמצעי תשלום:
          <a id="title" >{props.kind}</a></span>
        <br /> */}
        <br />
        {props.product.map(data => (<>
          <span id="title" className="p1">{data.name}: {data.amount}</span>
          {data.description !== "" && <span id="title2" className="red" >הערות: {data.description} </span>}
        </>))}
        {props.note != "" &&
          <span id="title2" className="red"  >{props.note}</span>}
        <br />
        <button className="primary_button text1_5 button" disabled={click} onClick={orderConfirm}>הוגש ל: {props.name}</button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <br />
        <div className='text_rtl text1_5 w300'>

          <div className='text_center text2 w300'>האם אתה בטוח למחוק? </div>

        </div>
        <Button
          className='text_center'
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="outlined"
          onClick={handleClose}
          color="primary"
        >
          ביטול
        </Button>
        <Button
          className='text_center'
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="outlined"
          onClick={remove}
          color="secondary"
        >
          מחק
        </Button>
      </Dialog>

    </>
  );
};

export default OrderCard;
