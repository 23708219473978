import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from './messageForm';
import FocusTrap from 'focus-trap-react';
import FormNewUser from './messageFormNewUser';
import FormMessage from './messageFormManager';
import FormCoupon from './couponFormManager';
import { FormWelcome } from './messageFormWelcome';
import FormWelcomeMinus from './messageFormWelcomeMinus';
export const Modal = ({
    onClickOutside,
    onKeyDown,
    modalRef,
    buttonRef,
    closeModal,
    onSubmit,
    kind,
    triggerText
}) => {
    return ReactDOM.createPortal(
        <FocusTrap>
            <aside
                tag="aside"
                role="dialog"
                tabIndex="-1"
                aria-modal="true"
                className="modal-cover"
                onClick={onClickOutside}
                onKeyDown={onKeyDown}
            >


                <div className="modal-area" ref={modalRef}>
                    {String(kind).includes("welcome") && <>
                        {kind === "welcome" &&
                            <FormWelcome onSubmit={onSubmit} onClose={closeModal} />}
                        {kind === "welcomeminus" &&
                            <FormWelcomeMinus onSubmit={onSubmit} onClose={closeModal}
                                triggerText={triggerText} />}
                    </>
                    }

                    {String(kind).includes("welcome") &&
                        <button
                            ref={buttonRef}
                            aria-label="Close Modal"
                            aria-labelledby="close-modal"
                            className="_modal-close"
                            onClick={closeModal}
                        >
                            <span id="close-modal" className="_hide-visual">
                                סגירה
                            </span>
                            <svg className="_modal-close-icon" viewBox="0 0 40 40">
                                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
                            </svg>
                        </button>}

                    {kind !== "new" && kind !== "message" && kind !== "coupon" && !String(kind).includes("welcome") &&
                        <Form onSubmit={onSubmit} />}
                    {kind === "new" &&
                        <FormNewUser onSubmit={onSubmit} />}
                    {kind === "message" &&
                        <FormMessage onSubmit={onSubmit} />}
                    {kind === "coupon" &&
                        <FormCoupon onSubmit={onSubmit} />}

                </div>
            </aside>
        </FocusTrap>,
        document.body
    );
};

export default Modal;
