

import { useEffect, useState } from "react";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth, database, db, updateUserAccountBalance, uploadPaymentApprove } from "../../../../firebase";
import { Table } from "semantic-ui-react";
import { Button, Dialog, DialogContent } from "@mui/material";
import { onValue, ref } from "firebase/database";

const BalanceRow = (props) => {
  const history = useHistory();
  // console.log(props.row)
  const row = props.row
  const [user, loading, error] = useAuthState(auth); //user.uid
  const [name, setName] = useState("row.user.name");
  const [email, setEmail] = useState("row.user.email");
  const [phone, setPhone] = useState("row.user.phone");
  const [balance, setBalance] = useState("row.user.accountBalance ? row.user.accountBalance : 0");
  const [newamountToCheck, setnewBalance] = useState(0);

  useEffect(() => {
    if (loading) return;
    else if (user) {
      async function fetchUserName() {
        const query = ref(database, "Account/" + row.key);
        return onValue(query, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setName(data.name)
            setEmail(data.email)
            setPhone(data.phone);
            setBalance(data.accountBalance ? data.accountBalance : 0)
          }
        });
      };
      fetchUserName();
    }
  }, [user, loading]);


  const onApproval = () => {
    handleClose()
    updateUserAccountBalance(row.key, -1*newamountToCheck).then(
      uploadPaymentApprove(row.key, name, email, newamountToCheck, user)
    ).finally(props.onChange())

  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (<>
    <Table.Row>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{phone}</Table.Cell>
      <Table.Cell>{balance} ₪</Table.Cell>
      <Table.Cell selectable>
        <button className="button" onClick={handleClickOpen}>
          הכנס סכום לפי תשלום שבוצע
        </button>
      </Table.Cell>
    </Table.Row>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <br />
      <div className='text_rtl text1_5 w300'>

        <div className='text_center text2 w300'>איזה סכום תרצה לאשר? </div>

      </div>
      <DialogContent>
        <div className='text_center'>
          <input
            type="number"
            className="__textBoxamount"
            value={newamountToCheck}
            onChange={(e) => setnewBalance(e.target.value)}
            placeholder={newamountToCheck}
          /> ₪
        </div>
      </DialogContent>

      <Button
        className='text_center'
        style={{ marginLeft: "10px", marginRight: "10px" }}
        variant="outlined"
        onClick={onApproval}
        color="primary"
      >
        אישור
      </Button>
    </Dialog>
  </>
  )
};

export default BalanceRow;
