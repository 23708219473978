import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useState } from 'react'
import AllOrders from "../managment/components/allOrders";
import SumOrders from "../managment/components/sumOrders";
import AllUsers from "../managment/components/allUsers";
import Navbar from "../sidebar/sidebar";
import AllEvents from "../managment/components/event/allEvents";
import EventScan from "../managment/components/event/EventsScan";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: { main: "#a57733" },
    secondary: { main: "#f8e7ce" },
  },
});


function OfficeEventScan() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>

      <ThemeProvider theme={theme}>
        <div id="page-wrap">
          <h1>סריקת כרטיסים</h1>

          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="סריקת כרטיסים" {...a11yProps(0)} />
              <Tab label="מוזמנים לאירוע" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <EventScan />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <AllEvents />
          </TabPanel>
        </div>
      </ThemeProvider>
    </>
  )
}

export default OfficeEventScan
