import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { collection, getDocs } from "firebase/firestore";
import { CreateMessage, database, db, updateTip0, updateTipBalance } from "../../firebase";
import ComingMessages from "./components/comingMessages";
import Container from "../aboutUs/components/container";
import { useEffect } from 'react';
import WorkerFormEdit from './components/work/barCheckListEdit';
import ManageEvents from './mangmentEvents';
import { onValue, ref } from 'firebase/database';
import BarTips from '../bar/barTips';
import ManagmentActions from './managmentActions';

function ManagmentView() {

  // const fetchUsers = async () => {

  //   try {
  //     const q = collection(db, "Users");
  //     const doc = await getDocs(q);
  //     const logs = [];

  //     doc.forEach(data => {

  //       const data1 = {

  //         value: data.data().uid,
  //         label: data.data().name,

  //       };
  //       logs.push(data1);

  //     });
  //     setUsers(logs);

  //   } catch (err) {
  //     console.log(err);
  //     alert(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  // useEffect(() => {
  //   console.log("******useEffect-managmentView************")
  //   const query = ref(database, "Account");
  //   return onValue(query, (snapshot) => {
  //     setUsers([])
  //     const data = snapshot.val();
  //     const logs = [];
  //     if (snapshot.exists()) {
  //       Object.keys(data).map((key, i) => {
  //         const data1 = {
  //           value: Object.values(data)[i].uid,
  //           label: Object.values(data)[i].name,
  //         };
  //         logs.push(data1);
  //         console.log(logs)
  //       });
  //       setUsers(logs);
  //     }
  //   });
  // }, []);





  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const newProduct = () => {
    history.push("/xmanagment/newproduct")
  }
  const newItem = () => {
    history.push("/xmanagment/newitem")
  }
  const newCategory = () => {
    history.push("/xmanagment/newcategory")
  }
  const newEvent = () => {
    history.push("/xmanagment/newevent/")
  }


  const menuEdit = () => {
    history.push("/xmanagment/menuedit")
  }

  const storageEdit = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'all' }
    });
  }
  const storageEdit1 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'bar' }
    });
  }
  const storageEdit2 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storage' }
    });
  }
  const storageToBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storagetobar' }
    });
  }
  const storageAdd = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageadd' }
    });
  }
  const storageviewBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'barView' }
    });
  }
  const storageView = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageView' }
    });
  }
  const categoryEdit = () => {
    history.push("/xmanagment/categoryedit")
  }


  const barFunction = (type) => {
    history.push("/worker/" + type)
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="פעולות"{...a11yProps(0)} />
          <Tab label="הודעות"{...a11yProps(1)} />
          <Tab label="עריכת משימות" {...a11yProps(2)} />
          <Tab label="אירועים" {...a11yProps(3)} />

        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <ManagmentActions />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div id="product-title">הודעות נכנסות</div>
        <ComingMessages />
        <br />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className='split_div'>
          <div className='left_div'>
            <WorkerFormEdit type={"open"} />
          </div>
          <div className='right_div'>
            <WorkerFormEdit type={"close"} />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ManageEvents />
      </TabPanel>
    </ThemeProvider>
  );
}

export default ManagmentView
