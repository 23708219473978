import { useRef } from "react";

const EventDate = (props) => {
  const random = (Math.floor(Math.random() * 20 - 10))
  const namelist = props.name.split(" ")
  let name = namelist[0]
  if (namelist[1])
    name += " " + namelist[1]
  console.log(random)
  const ChipStyles = useRef({
    top: props.top + "%",
    left: props.left + "%",
  });
  const date = String(props.date).split("-").reverse().filter((x, i) => i !== 2).join("/");

  return (
    // style={ChipStyles.current}
    <>
      {props.index < 4 &&
        <div
          className={"grid-item text w800" + "rotate" + random}
          style={ChipStyles.current}
        >
          {date}
          <div className="text_event w700">{name}</div>
        </div>}
    </>
  );
};

export default EventDate;
