import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage, updateEvent, uploadEvent } from '../../../../firebase';
import { Label } from 'semantic-ui-react';
import Navbar from '../../../sidebar/sidebar';
import EventChoices from './EventChoices';
import { useEffect } from 'react';
import EventCoupon from './EventCoupon';

function NewEventForm(props) {

  const [pname, setPName] = useState("");
  const [key, setKey] = useState("");
  const [edit, setEdit] = useState(false);
  const [pimg, setPimg] = useState("");
  const [pdes, setPdes] = useState("");
  const [price, setPrice] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState(0);


  const history = useHistory();

  const handleImg = (e) => {
    const file = e.target.files[0];
    uploadImg(file);
  };

  const uploadImg = (file) => {
    if (!file) {
      alert.error("Empty Image");
    } else {
      const storageRef = ref(storage, "/event_images/" + file.name);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on("state_changed", (snapshot) => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setPimg(url);

        });
      });
    }
  };
  useEffect(() => {
    if (props.event) {
      let x = props.event;

      setPrice(x.price)
      setCoupon(x.coupon)
      setKey(x.id)
      setTime(x.time)
      setAmount(x.amount ? x.amount : 0)
      setDate(x.date)
      setPdes(x.des)
      setPimg(x.img)
      setPName(x.name)
      setEdit(true)
    }
  }, [])


  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    e.preventDefault();
    if (
      pname === "" ||
      price === [] ||
      pdes === "" ||
      pimg === "" ||
      date === "" ||
      time === "" ||
      amount === 0
    ) alert("מידע חסר");
    else {
      if (edit) {
        updateEvent(key, pimg, pname, pdes, price ? price : [], date, time, coupon, amount)
        props.event.onChange()
      }
      else
        uploadEvent(pimg, date, time, pname, pdes, price, coupon, amount)
    }
  };

  const handleSubmitarrayItems = (choices) => e => {
    e.preventDefault();
    setPrice(choices)
    console.log(choices);
  };

  const handleSubmitarrayCoupon = (choices) => e => {
    e.preventDefault();
    setCoupon(choices);
    console.log(choices);
  };

  return (
    <>
        <div id={"page-wrap"} >
          <div id="page">
            {edit === false ? (
              <h3> צור אירוע </h3>
            ) : (
              <h3> ערוך אירוע </h3>
            )}

            <Label> בחירת תמונת נושא</Label>
            <input

              className="__textBox"
              type="file"
              placeholder="Enter Product Image"
              name="pimg"
              defaultValue={pimg}
              onChange={handleImg}
            /><br />

            <Label>**נא להמתין עד שמופיעה תמונה </Label>
            <img src={pimg} width={300} />


            <Label>שם אירוע</Label>
            <input
              type="text"
              className="__textBox"
              value={pname}
              onChange={(e) => setPName(e.target.value)}
              placeholder="שם אירוע"
            />
            <Label>תאריך</Label>
            <input
              type="date"
              className="__textBox"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            /><br />
            <Label>שעת התחלה</Label>
            <input
              type="text"
              className="__textBox"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            /><br />
            <br />
            <Label>פירוט</Label>
            <textarea
              placeholder="פירוט"
              className="__textBox"
              value={pdes}
              onChange={(e) => setPdes(e.target.value)}
            /><br />
            <Label>סה"כ כמות כרטיסים</Label>
            <input
              type="text"
              className="__textBox"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            /><br />
            <Label>כרטיסים</Label>
            <EventChoices
              choices={props.event ? (props.event.price ? (props.event.price) : ([])) : (price)}
              handleSubmit={handleSubmitarrayItems}
            />
            <Label>קופונים</Label>
            <EventCoupon
              choices={props.event ? (props.event.coupon ? (props.event.coupon) : ([])) : (coupon)}
              handleSubmit={handleSubmitarrayCoupon}
            />
            <br />
            <button className='button' onClick={Submit} >{props.event ? ("עדכן אירוע") : ("הוסף אירוע")}</button>
          </div>
        </div>
    </>
  );
}

export default NewEventForm
