import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentDialog = () => {
    const location = useLocation();
    const { menuData = {}, sum = 0, tip = 0 } = location.state || {};
    console.log("PaymentDialog:", sum, tip, menuData)
    useEffect(() => {
        window.scrollTo(0, 0);

        const iframeBaseUrl = 'https://direct.tranzila.com/jira/iframenew.php';

        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', iframeBaseUrl);
        form.setAttribute('target', 'paymentIframe');

        const paymentParams = {
            sum: sum + tip,
            tip: tip,
            menuData: JSON.stringify(menuData), // Convert menuData to string for submission
            buttonLabel: 'Pay',
            success_url_address: 'https://liron-sternberg.com/process.php',
            fail_url_address: 'https://liron-sternberg.com/process.php',
            currency: 1,
        };

        Object.keys(paymentParams).forEach((key) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', paymentParams[key]);
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }, [sum, tip, menuData]); // Dependencies to rerun effect if any of these values change

    return (
        <>
            <iframe
                name="paymentIframe"
                title="Payment"
                style={{ width: '100%', height: '600px', border: 'none', marginTop: '15vw' }}
            />
        </>
    );
};

export default PaymentDialog;
