

import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateStorageAmount, updateStoragebar } from "../../../../firebase";

const InventoryAdd = (props) => {

  const history = useHistory();
  
  const [click, setClick] = useState(false);

  const update = (int) => {
    setClick(true);
    let a=parseFloat(props.amount)+int
    updateStorageAmount(props.id, a)

    // updateInventory(props.kind, props.id, amount)
  }
 
  return (<>
    <div  className={click ? 'storage_box fade_out' : 'storage_box'} onAnimationEnd={() => setClick(false)}>
      <span className="text text2 w600 ">{props.name}  </span><br/>
      <span className="text text1_3 w400 ">כמות במלאי: {props.amount}  </span>
      <button className="storage_button" disabled={click} onClick={()=>update(1)}>הכנס 1</button>
      <button className="storage_button" disabled={click} onClick={()=>update(3)}>הכנס 3</button>
      
      <button className="storage_button" disabled={click} onClick={()=>update(12)}>הכנס 12</button>
      <button className="storage_button" disabled={click} onClick={()=>update(24)}>הכנס 24</button>
     
{/* 

      {props.id !== "Coupon" &&
        <button disabled={click} className="product_button" onClick={remove}> הסר מ-<BiBasket /></button>
      }


    </div>




    <div className="storage_box">
      <div className="text text2 w600 ">{props.name}  </div>
      <div className="text text1_3 w400 ">כמות במלאי: {amount}  </div>
      <div className="text text1_3 w400 ">כמות בבר: {amountBar}  </div>
      <button className="button" onClick={update}>עדכון</button>
      <button className="button width20" onClick={update}>עדכון</button>
      <br />
      {props.location !== "מיקום" &&
        <button className="product_button width40" onClick={update}>עדכון</button>}
         */}
    </div>
  </>
  );
};

export default memo(InventoryAdd);
