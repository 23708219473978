import React, { memo, useEffect, useMemo, useState } from 'react'
import '../../App.css'
import Navbar from '../sidebar/sidebar';
import { RingLoader } from 'react-spinners';
import { onValue, ref } from "firebase/database";
import { auth, database } from '../../firebase';
import ButtomPage from '../buttomPage';
import { WeelOfLuck } from './weelofluck';
import { useAuthState } from 'react-firebase-hooks/auth';
import Gcoin from "../../image/Gcoin1.png";
import { Link } from 'react-router-dom';

function WheelHome() {
    const [user, loadinguser, error] = useAuthState(auth);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [loading, setloading] = useState(false);
    const [points, setPoints] = useState(0);
    const [touchPosition, setTouchPosition] = useState("");

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
        if (diff > 10) {
            showSidebar()
        }
        if (diff < -5) {
            // showSidebar()
        }
        setTouchPosition(null)
    }
    // useEffect(() => {
    //     localStorage.clear()
    // }, []);

    const [comingList, setComingList] = useState([]);

    useEffect(() => {
        const query = ref(database, "LuckyWheel/list");
        return onValue(query, (snapshot) => {
            setComingList([]);
            const data = snapshot.val();
            if (snapshot.exists()) {
                setComingList(data);
                setloading(true);
            }
        });
    }, []);

    useEffect(() => {
        if (loadinguser) return;
        async function fetchUserName() {
            const query = ref(database, "Account/" + user.uid);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    console.log(data)
                    setPoints(data.points ? data.points : 0);
                }
            });
        };
        if (user)
            fetchUserName();
    }, [user, loadinguser]);


    return (
        <div id="page-wrap" className='top_padding background'>
            <span className="text_title_img text3 w100" >גלגל המזל </span>

            <div className='text_center text_dark text2_5 w500 p3'>פאב גבעון מחלק לכם הפתעות

                {/* <div className='text_center text_dark text2 w500'></div> */}
            </div>
            {/* {points >= 0 && <> */}
            <a className="text text2 w500">נקודות:  <a className="text_brown">{points.toFixed(0)}

                <img src={Gcoin} className="gcoin" alt="logo" />
            </a>
                <br />
                7 נקודות לזכייה בפרס
            </a>
            <br />
            <br />
            <Link to="/luckyhow"
                className="button p3 nounderline" >
                איך אני מקבל נקודות?
            </Link>

            <br />



            {/* <div className='text_welcome text2_5 w600'>תפריט פאב</div>*/}
            {/* <img src={menuTitle} className="width40" alt="logo" /> */}
            {/* <div className='text_welcome text1_5 w600'>פאב גבעון</div> */}
            {/* <img src={header} className="width50" alt="logo" /> */}
            {loading ? <>
                <WeelOfLuck list={comingList} points={points} uid={user.uid} />

            </>
                :
                <center>
                    <br /><br />
                    <RingLoader id="loading" color={"#a57733"} />
                </center>}

            <ButtomPage />
        </div>
    );
}

export default memo(WheelHome)
