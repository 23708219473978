import React, { memo, useEffect, useMemo, useState } from 'react'
import '../../App.css'
import Navbar from '../sidebar/sidebar';
import { RingLoader } from 'react-spinners';
import { onValue, ref } from "firebase/database";
import { auth, database } from '../../firebase';
import ButtomPage from '../buttomPage';
import { WeelOfLuck } from './weelofluck';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from "react-router-dom";

function WheelInfo() {

    const [user, loadinguser, error] = useAuthState(auth);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [loading, setloading] = useState(false);
    const [points, setPoints] = useState(0);
    const [touchPosition, setTouchPosition] = useState("");


    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
        if (diff > 10) {
            showSidebar()
        }
        if (diff < -5) {
            // showSidebar()
        }
        setTouchPosition(null)
    }
    // useEffect(() => {
    //     localStorage.clear()
    // }, []);

    const [comingList, setComingList] = useState([]);

    useEffect(() => {
        const query = ref(database, "LuckyWheel/list");
        return onValue(query, (snapshot) => {
            setComingList([]);
            const data = snapshot.val();
            if (snapshot.exists()) {
                setComingList(data);
                setloading(true);
            }
        });
    }, []);

    useEffect(() => {
        if (loadinguser) return;
        async function fetchUserName() {
            const query = ref(database, "Account/" + user.uid);
            return onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    console.log(data)
                    setPoints(data.points ? data.points : 0);
                }
            });
        };
        if (user)
            fetchUserName();
    }, [user, loadinguser]);

    const history = useHistory();
    const toMenu = () => {
        history.push("/lucky")
    }


    return (

        <div id="page-wrap" className='top_padding background'>
            <span onClick={toMenu} className="text_title_img text3 w100" >גלגל המזל </span>

            <div className='text_center text_dark text2_5 w500 p3'>איך זוכים בנקודות?

                {/* <div className='text_center text_dark text2 w500'></div> */}
            </div>
            {/* {points >= 0 && <> */}
            <a className="text text2 w500">
                מקבלים נקודה על כל 10 שקלים בהזמנה
                <br />
                ניתן להרוויח פרסים נוספים ברשתות החברתיות
                <br />
                הישארו מעודכנים!
                {/* נקודות:  <a className="text_brown">{points.toFixed(0)} <FaCoins /></a>
                        <br />
                        5 נקודות לזכייה בפרס */}
            </a>
            <br />
            <br />

            <br />



            <ButtomPage />
        </div>
    );
}

export default memo(WheelInfo)
