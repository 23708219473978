import React, { memo, useMemo, useState } from 'react'
import '../../App.css'
import Navbar from '../sidebar/sidebar';
import { RingLoader } from 'react-spinners';
import { onValue, ref } from "firebase/database";
import { database } from '../../firebase';
import CategoryCard from './components/CategoryCard';
import ButtomPage from '../buttomPage';
import { useEffect } from 'react';

function Menu() {

    const [categories, setCategories] = useState([]);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [menuData, setMenuData] = useState([]);
    const [loading, setloading] = useState(false);
    const [touchPosition, setTouchPosition] = useState("");

    useMemo(() => {
        console.log("******useMemo-menu-Category************")
        const query = ref(database, "Category");
        return onValue(query, (snapshot) => {
            setCategories([])
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).sort((a, b) => parseInt(a.location) - parseInt(b.location)).map((project) => {
                    setCategories((projects) => [...projects, project]);
                    return project;
                });
            }
        });
    }, []);

    useMemo(() => {
        const query = ref(database, "Products");
        return onValue(query, (snapshot) => {
            setMenuData([]);
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((project) => {
                    setMenuData((projects) => [...projects, project]);
                    return project;
                });
                setloading(true)
            }
        });
    }, []);

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
        if (diff > 10) {
            showSidebar()
        }
        if (diff < -5) {
            // showSidebar()
        }
        setTouchPosition(null)
    }
    // useEffect(() => {
    //     localStorage.clear()
    // }, []);

    return (<>
        <div id="page-wrap" className='top_padding background'>
            <span className="text_title_img text3 w100" >תפריט</span>
            <br /><br />
            {/* <div className='text_welcome text2_5 w600'>תפריט פאב</div>*/}
            {/* <img src={menuTitle} className="width40" alt="logo" /> */}
            {/* <div className='text_welcome text1_5 w600'>פאב גבעון</div> */}
            {/* <img src={header} className="width50" alt="logo" /> */}
            {loading ? <>
                <div id="page-menu">
                    {categories.map((category, i) => (
                        <CategoryCard
                            index={i}
                            key={category.uid}
                            id={category.uid}
                            subtitle={category.subtitle}
                            img={category.picture}
                            title={category.title}
                            menuData={menuData}
                        />
                    ))}
                </div>
                <br /><br />
            </>
                :
                <center>
                    <br /><br />
                    <RingLoader id="loading" color={"#a57733"} />
                </center>}

            <ButtomPage />
        </div>
    </>
    );
}

export default memo(Menu)
