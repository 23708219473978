
import React from "react";
import ReactSelect from "react-select";

export const formatChoices = choices => {
    return choices;
};

class ListChoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.choices
        };
        console.log(" constructor(props", props.choices)
    }

    handleChoiceChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            console.log(e)
            return { ...choice, product: e };
        });
        this.setState({ choices: newChoices });
    };
    handleColorChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            console.log(e.target.value)
            return { ...choice, color: e.target.value };
        });
        this.setState({ choices: newChoices });
    };
    handleSizeChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            console.log(e.target.value)
            return { ...choice, size: e.target.value };
        });
        this.setState({ choices: newChoices });
    };
    handleDuplicateChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            console.log(e.target.value)
            return { ...choice, duplicate: e.target.value };
        });
        this.setState({ choices: newChoices });
    };

    handleRemoveChoice = index => () => {
        this.setState({
            choices: this.state.choices.filter((choice, sidx) => index !== sidx)
        });
    };

    handleAddChoice = () => {
        this.setState({
            choices: this.state.choices.concat([{ color: "#ff0000", duplicate: 1, size: 10 }])
        });
    };

    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;
        const { items } = this.props;

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                {choices.map((choice, index) => (
                    <>
                    <div key={["choice", index].join("__")} className="row_display">

                        <ReactSelect
                            value={choice.product}
                            onChange={this.handleChoiceChange(index)}
                            options={items}
                            placeholder="הקלד או בחר מוצר"
                        />

                        צבע: <input type="color" id="favcolor" name="favcolor" value={choice.color} onChange={this.handleColorChange(index)}></input>
                        {/* גודל: <input type="number" id="favcolor" name="favcolor" value={choice.size} onChange={this.handleSizeChange(index)}></input>
                        כמות: <input type="number" id="favcolor" name="favcolor" value={choice.duplicate} onChange={this.handleDuplicateChange(index)}></input> */}

                        <button
                            className="button"
                            onClick={this.handleRemoveChoice(index)}
                            type="submit" >
                            מחיקה
                        </button>
                    </div>
                    
                    <br/>
                    </>
                ))}
                <button className="button" type="button" onClick={this.handleAddChoice}>
                    הוסף פרס
                </button>
                <button className="button_b" type="submit">רענן</button>
            </form>
        );
    }
}

export default ListChoices;
