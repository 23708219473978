
import { completeFullOrder } from "../../../firebase";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import Navbar from "../../sidebar/sidebar";



const Confirm = (props) => {


  let stateData = props.location.state
  var tip = stateData["tip_i"]
  var items = stateData["menuData_i"]
  var sum = stateData["sum_i"]
  var sumUSD = stateData["sum_usd"]

  const [orderID, setOrderID] = useState(false);

  const createOrder = (data, actions) => {


    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: sumUSD
            },
          },
        ],
        // remove the applicaiton_context object if you need your users to add a shipping address
        application_context: {
          shipping_preference: "NO_SHIPPING",

        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // handles when a payment is confirmed for paypal
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      completeFullOrder(details.payer.payer_id, details.payer.name.given_name + " " + details.payer.name.surname, details.payer.email_address, items, sum, "PayPal", tip, 0);
    })
  };
  const onCancel = (data) => {

    // Show a cancel page, or return to cart
  }
  // handles payment errors




  return (
    <>
        <center id="page-wrap">

          <h2 >סה"כ: {sum} ₪</h2>


          <PayPalScriptProvider options={{ "client-id": "ASCnPT5LNXk54YIuHpG0qXstMHcZR6O4VMpDjev1vrm5f5qrc18GPoj-dAw1m04xqBMNCL71Lwwmdbmc" }}>
            <PayPalButtons


              createOrder={createOrder}
              onApprove={onApprove}
              onCancel={onCancel}

            />
          </PayPalScriptProvider>


        </center>
    </>
  );



}
export default Confirm