
import { onValue, ref } from "firebase/database";
import RingLoader from "react-spinners/RingLoader";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { addToCart, addToFavorite, auth, database, db, deletaFromRealTimeDb } from "../../../firebase";
import { FaHeart, FaMinusCircle, FaPlusCircle, FaRegHeart } from "react-icons/fa";
import Navbar from "../../sidebar/sidebar";
import line from "../../../image/line.png";
import ButtomPage from "../../buttomPage";
import Choices, { customStyles } from "./Choices";
import { ArrowDropDown } from "@mui/icons-material";
import MenuSmallProduct from "../menuSmallProduct";
import Select from "react-select";
import { BiHeart } from "react-icons/bi";
import { BsHeart } from "react-icons/bs";
import { Button, Dialog } from "@mui/material";
import { NotificationContainer, NotificationManager } from 'react-notifications';


import { toast } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

const ProductView = () => {
  const uid = window.location.pathname.replace("/menu/", "");
  const [click, setClick] = useState(false);
  const [click1, setClick1] = useState(false);
  const [amount, setAmont] = useState('');
  const [details, setDetails] = useState('');
  const [description, setDes] = useState('');
  const [picture, setPic] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [discountLength, setDiscountLength] = useState(0);
  const [chosentLength, setChosentLength] = useState(0);
  const [choicesList, setChoicesList] = useState([]);
  const [choicesListX, setChoicesListX] = useState([]);
  const [choices, setChoices] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [primary, setPrimary] = useState('');
  const [value, setValue] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");



  useEffect(() => {

    const query = ref(database, "Products/" + uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log(snapshot);
      if (snapshot.exists()) {
        setAmont(data.amount);
        setCategory(data.category);
        setDes(data.description);
        setPic(data.picture);
        setId(uid);
        setName(data.pname);
        setPrice(data.price);
        if (data.choices) {
          setChoicesList(data.choices);
          setChoicesListX(data.choices);
          //   const logs=[]
          //   const data1 = {
          //     value: "",
          //     label: "נא לבחור",
          //   };
          //   logs.push(data1);
          //   Object.values(data.choices).map((project) => {
          //     const data1 = {
          //       value: project,
          //       label: project,
          //     };
          //     logs.push(data1);
          //     // if (String(project.category).includes(id))
          //   });
          //   console.log("Storage", logs)
          //   setChoicesListX(logs);
        }
        if (data.primary)
          setPrimary(data.primary);
        if (data.discount)
          setDiscountLength(data.discount);
        if (choicesList[parseInt(primary)])
          setDetails(choicesList[parseInt(primary)])
        // setLoading1(false);
      }
    }, {
      onlyOnce: true
    });
  }, [uid]);

  useEffect(() => {

    const query = ref(database, "Favorite");
    if (user)
      return onValue(query, (snapshot) => {
        setFavorites([])
        const data = snapshot.val();
        console.log("favorites", data)
        console.log("favorites favorites", uid)
        if (snapshot.exists()) {

          Object.values(data).filter((a) => a.email === user.email && a.id === uid).map((project) => {
            setFavorites((projects) => [...projects, project]);
            return project;
          });
        }
        console.log(favorites)
        // setLoading1(false)
      });
  }, [uid]);


  useEffect(() => {

    const query = ref(database, "Category/" + category);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log(snapshot);
      if (snapshot.exists()) {
        setCategoryName(data.title)
        setLoading1(false);
      }
    }, {
      onlyOnce: true
    });
  }, [category]);


  let [count, setCount] = useState(1);
  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    if (count !== 0) count = count - 1;
    setCount(count);
  }


  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  const add = () => {

    console.log(chosentLength)
    console.log(discountLength)
    if (!user) {
      history.push('/login');
      alert("להוספת המוצר לעגלה, ייש להתחבר למערכת");
    }
    else if (
      chosentLength < discountLength
    ) alert("נא לבחור " + discountLength + " אופציות ");
    else if (
      name === "" ||
      price === "" ||
      count === 0 ||
      user.uid === "" ||
      user.email === ""

    ) alert("משהו השתבש, מוצר לא התווסף לעגלה");

    else {
      if (value !== null) {
        if (details !== "") {
          addToCart(id, name, count * price, count, user.uid, user.email, details + " , " + value.label, picture, "Products", id);
          setClick(true);
        }
        else {
          addToCart(id, name, count * price, count, user.uid, user.email, value.label, picture, "Products", id);
          setClick(true);
        }
      }
      else {
        addToCart(id, name, count * price, count, user.uid, user.email, details, picture, "Products", id);
        setClick(true);
      }
    }
  }

  const back = () => {
    history.goBack()

  }

  const favorite = () => {
    // setClick1(true);
    if (!user) {
      alert("להוספת המוצר למועדפים, יש להתחבר למערכת");
    }
    else if (
      chosentLength < discountLength
    ) alert("נא לבחור " + discountLength + " אופציות ");
    else if (
      picture === "" ||
      name === "" ||
      price === "" ||
      count === 0 ||
      user.uid === "" ||
      user.email === ""
    ) alert("משהו השתבש");

    else {
      if (value !== null) {
        if (details !== "") {
          addToFavorite(name, count * price, count, user.uid, user.email, details + " , " + value, picture, id);
          setClick1(true);
        }
        else {
          addToFavorite(name, count * price, count, user.uid, user.email, value, picture, id);
          setClick1(true);
        }
      }
      else {
        addToFavorite(name, count * price, count, user.uid, user.email, details, picture, id);
        setClick1(true);
      }


    }
  }

  const favoriteRemove = () => {
    console.log(favorites.keyUid)
    deletaFromRealTimeDb(favorites[0].keyUid, "Favorite")
  }

  const handleSubmitarray = (choices) => {
    var logs = []
    choices.map(x => {
      var y = x.name
      if (y !== "" && y !== null)
        logs.push(y)
    })
    setChosentLength(logs.length)
    setDetails(logs.join(","));
    console.log(logs.length);
  };

  const handleChoiceChange = (value) => {
    console.log(value)
    setValue(value)
  };


  return (
    <>
      {/* <CartButton /> */}
      <div id="page-wrap" className={'width100 top_padding background z_index4'}
      // />
      // <div id="page-wrap" className={click ? 'width100 top_padding fade z_index4' : 'width100 background top_padding z_index4'}

      >
        {loading1 === true && <RingLoader id="loading" color={"#a57733"} />}
        {loading1 === false && <>
          <img className="product_image_view_new z_index_1 p3" src={picture} width={300} />
          <img src={line} className="line_png" alt="logo" />

          <div className="product_box ">
            {/* <button className="menu-bars-back " onClick={back}> <ArrowBendDoubleUpRight color={"#996633"} /></button> */}

            <div className=" text_rtl text_brown text3 w500 p3">{name}
            </div>

            <br />
            <div className="text_brown text_rtl text1_5 w500 p3 text_sub">{description} </div>

            <div className="text_brown text_rtl text2 w500 p3 text_sub">מחיר: {price} ₪</div>

            <div className="text_brown text_rtl text2 w500 p3 text_sub">כמות: {discountLength > 0 && <>
              {discountLength}
            </>}
            </div>
            {discountLength == 0 && <div className="row_div_space">
              <b className="text_brown text2 w500 p5" onClick={decrementCount}><FaMinusCircle /></b>
              <a className="text_brown text2 w500 p4">{count}</a>
              <b className="text_brown text2 w500 p5" onClick={incrementCount}><FaPlusCircle /></b>
            </div>}
            {choicesList !== undefined && choicesList.length > 0 && discountLength == 0 && <>
              <div className="split_div">
                <div className="right_div leftttt">
                  <div className="text_brown text_rtl text2 w500 p3 text_sub">אפשרויות:</div>
                </div>

                <div className=" split_div">
                  <Select
                    className="text_brown text1_5 w500"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={true}
                    isSearchable={false}
                    onChange={(e) => handleChoiceChange(e)}
                    value={value}
                    placeholder={"לבחירה"}
                    options={choicesList.map(x => {
                      return ({
                        value: x,
                        label: x
                      })
                    })}
                    styles={customStyles}
                  />




                  {/* <select
                      value={value}
                      className="__textBoxNewProduct text_center text_brown text2 "
                      type="submit"
                      onChange={(e) => handleChoiceChange(e.target.value)}
                    >
                      <option value={""} >נא לבחור</option>
                      {choicesList.map(x => {
                        return (<><option value={x}>{x} </option></>

                        )
                      })}
                    </select> */}

                  {/* <ArrowDropDown className="text_brown text3 top_padding_arrow" /> */}

                </div>
              </div>


              {/* {choicesList.map((x) => (
                  <button className={String(details).includes(String(x)) ? ("__textBoxProductChosesClick") : ("__textBoxProductChoses")} onClick={() => setDetails(details == "" ? (x) : (String(details).includes(String(x)) ? (details) : (details + " , " + x)))} >{x}</button>
                ))} */}
              <br /></>}
            {discountLength !== undefined && discountLength > 0 && choicesList !== undefined && choicesList.length > 0 && <>
              <div className="text_brown text_rtl text2 w500 p3 text_sub">בחירת פריטים:</div>
              <Choices
                choices={choices}
                items={choicesListX}
                length={discountLength}
                handleSubmit={handleSubmitarray}
              />
              <br /></>}
            {discountLength == 0 &&
              // <div className="split_div p3">
              //   {/* <div className="right_div_3_right text_brown text_rtl text2 w500">הערות:</div> */}
              //   <div className="right_div2_3">
              <input
                type="text"
                className="__textBox text_center text_brown text1_5 w400 p3 text_sub"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                placeholder="הערות..."
              />
              //   </div>
              // </div>
            }
          </div>

          {/* <img src={line} className="line_png" alt="logo" /> */}

          {/* <div id="product-sum" >סה"כ: {count * price} ₪</div> */}
          <div className="split_div">
            {/* <div className="right_div_70"> */}
            <button disabled={click} className={click ? "product_button_view_new text_center text_white text2 w400  width80 fade " : "product_button_view_new text_center text_white text2 w400  width80"} onClick={add}
              onAnimationEnd={() => setClick(false)}
            >הוסף לעגלה</button>
            {/* </div> */}
            {/* <div className="right_div_70"> */}
            <button className={"product_button_view_new text_center text3 w400 p3313 cancel_button1"} onClick={() => favorites.length > 0 ? favoriteRemove() : favorite()}
              onAnimationEnd={() => setClick1(false)}
            >{favorites.length > 0 ? <FaHeart color={"#A57735"} /> : <FaRegHeart color={"#A57735"} />}</button>
            {/* </div> */}
          </div>

          <img src={line} className="line_png" alt="logo" />

          <div className='text_black text_rtl text3 w600 p3'>עוד {categoryName}:</div>
          <MenuSmallProduct title={category} product={uid} />
          <ButtomPage />

        </>}

      </div>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className='background'
      >
        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text_dark text2_5 w500 p3'>מוצר מועדף</div>
            <br />
            <div className='text_rtl text_brown text1_5 w300 p3'>
             האם אתה בטוח להסיר?
              <br />
            </div>
          </div>
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            ביטול
          </Button>
          <Button
            className='primary_button1'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={favoriteRemove}
            color="secondary"
          >
            אישור
          </Button>
        </div>
      </Dialog> */}
    </>
  )


}
export default memo(ProductView)
