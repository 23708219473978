import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import { useHistory } from "react-router-dom";
import EventCard1 from "./EventCard";
import Navbar from "../../../sidebar/sidebar";


const MenuEventEdit = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const uid = window.location.pathname.replace("/xmanagment/neworder/","");
  const [loading1, setLoading1] = useState(true);
    const [menuData, setMenuData] = useState([]);

 
    const fetchProducts = async () => {
               
        try {
          const q = collection(db, "Events");
          const doc = await getDocs(q);
          const logs = [];
        
          doc.forEach(data => {
            
                const data1 = {
                  id:data.id,
                  name: data.data().pname,
                  picture: data.data().picture,
                  date: data.data().date,
                  time: data.data().time,
                  description: data.data().description,
                  amount: data.data().amount,
                  price: data.data().price,
                };
                logs.push(data1);
                         
             
            });

             
        setMenuData(logs);

        } catch (err) {
          console.log(err);
          alert(err);
        }
        setLoading1(false);
      };

      useEffect(() => {
        fetchProducts();
      }, []);


return(
    <>

        <div id={"page-wrap"} >
          <div id="page">
            <h3> ערוך אירועים </h3>
         <input
        type="text"
        className="__textBox"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="חיפוש פריט"
      />
            
      <br />
          {loading1==true ||menuData.length === 0 ? (
            <center id="loading" >
             <RingLoader id="loading" color={"#a57733"} />
            </center>
          ) : (
            <div id="page-event">
              
               
                  {menuData.map((products, i) => (
                    products.name.includes(search) &&
                    <EventCard1
                    uid={uid}
                    email={"נקלט דרך מערכת עובדים"}
                      id={products.id}
                      des={products.description}
                      img={products.picture}
                      name={products.name}
                      price={products.price}
                      date={products.date}
                      time={products.time}
                      amount={products.amount}
                    />
                  ))}
               </div>
          )}
          </div>
          </div>
          
    </>
);



}
export default MenuEventEdit