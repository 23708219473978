import React, { useEffect, useState } from 'react';
import './form.css';
import { Link, useHistory } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FcGoogle } from 'react-icons/fc'

import Logo from '../../image/logo.png';
import Navbar from '../sidebar/sidebar';
import { AiOutlineGoogle } from 'react-icons/ai';
import ButtomPage from '../buttomPage';

function LoginForm() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate.push("/");
  }, [user, loading]);

  return (
    <>
        <div id="page-wrap" className="background top_padding">

          <div className='p3'>
            <img
              className='width50'
              src={Logo}
              alt="logo"
            />
            <div className='text_brown text2 w500 p3'> התחברות </div>


            <label className='text_brown text1_5 w500 ' >

              אימייל
            </label><br />
            <input
              type="text"
              className="__textBoxEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            /><br />
            <label className='text_brown text1_5 w500 ' >
              סיסמא          </label><br />
            <input
              type="password"
              className="__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <br /><br />
            <button
              className="welcome_button cancel_button1"
              onClick={() => logInWithEmailAndPassword(email, password)}
            >התחבר</button>
            <br />
            <br />
            <div className='text_brown text1_5 w500'>התחבר באמצעות גוגל   </div>

            <button className="welcome_button_google primary_button1"
              onClick={signInWithGoogle}> <AiOutlineGoogle />
            </button>

            <br />
            <br />
            <div className='text_center text_brown w400 p3 '>
              <Link to="/reset" className='text_center text_brown w400 text_sub'>שכחתי את הססמא</Link>

              <div className='text_center text_brown w600 p1 text_sub'>
                אין לך חשבון? <Link to="/register" className='text_center text_brown w400 text_sub'>צור חשבון חדש</Link>
              </div>
            </div>
          </div>

          <ButtomPage />
        </div>
    </>
  );
}

export default LoginForm
