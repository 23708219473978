import RingLoader from "react-spinners/RingLoader";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, completeOrderFromBar, database, db, removeFromStorageTickets, sendEventOrder, sendSMSMessage, updateEventComing, updateUserAccountBalance, uploadTicket } from "../../../firebase";
import Navbar from "../../sidebar/sidebar";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { onValue, ref } from "firebase/database";
import EventUserChoices from "./EventUserChoices";
import { Button, Dialog, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import ButtomPage from "../../buttomPage";
import line from "../../../image/line.png";
import Example from "./exsample";
import { CloseOutlined } from "@mui/icons-material";

const EView = (props) => {
  const [uid, setUid] = useState(window.location.pathname.replace("/event/", ""));
  const [flag, setFlag] = useState(false);
  const [click, setClick] = useState(false);
  const [click1, setClick1] = useState(true);
  const [clickOrder, setClickOrder] = useState(false);
  const [details, setDetails] = useState('');
  const [id, setId] = useState('');
  const [description, setDes] = useState([]);
  const [picture, setPic] = useState('');
  const [name, setName] = useState('');
  const [nameuser, setNameuser] = useState('');
  const [emailuser, setEmailuser] = useState('');
  const [paybox, setPaybox] = useState("false");
  const [tax, setTax] = useState("false");
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [ticketOption, setTicketOption] = useState([]);
  const [ticketChose, setTicketChose] = useState([]);
  const [comingList, setComingList] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [balance, setBalance] = useState(0);
  const [userKind, setUserKind] = useState(0);
  const history = useHistory();

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  useEffect(() => {
    let uidX = uid
    if (props.id) {
      uidX = props.id
    }
    const query = ref(database, "Events/" + uidX);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log(snapshot);
      if (snapshot.exists()) {
        setDetails(data.description);
        setDes(String(data.description).split(','));
        setPic(data.picture);
        setName(data.pname);
        if (data.coming) {
          setComingList(data.coming)
        }
        // setTicketOption(data.price);
        var logs = []
        if (data.price)
          data.price.map(ticket => {
            let x = {
              name: ticket.name,
              amount: 0,
              price: ticket.price,
              kind: ticket.amount,
              totalprice: 0
            }
            logs.push(x)
          })
        setTicketChose(logs)
        setId(data.uid);
        setDate(data.date);
        setTime(data.time);
        setLoading1(false);
      }
    }
      // , {
      //   onlyOnce: true
      // }
    );
  }, [uid]);

  let [count, setCount] = useState(1);

  // eslint-disable-next-line
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;

    async function fetchUserName() {
      const query = ref(database, "Account/" + user.uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setNameuser(data.name);
          setEmailuser(data.email);
          setTax(data.tax);
          setPaybox(data.payBox);
          setBalance(data.accountBalance)
          setUserKind(data.kind ? data.kind : 0)
        }
      }, {
        onlyOnce: true
      });
    };
    if (user)
      fetchUserName()
  }, [user, loading]);

  useEffect(() => {
    if (user)
      if (comingList.length > 0) {
        comingList.map(x => {
          if (x.uid == user.uid)
            setFlag(true)
        })
      }

  }, [comingList]);



  const add = () => {
    if (!user) {
      alert("להזמנת כרטיסים, יש להתחבר למערכת");
      return;
    }
    else if (
      name === "" ||
      ticketOption === "" ||
      count === 0 ||
      user.uid === "" ||
      user.email === ""

    ) alert("משהו השתבש");

    else {
      // if (parseInt(amount) < count)
      //   alert("לא נותרו מספיק כרטיסים לרכישה")
      // else {
      //   addToCart(id, name, count * price, count, user.uid, user.email, details, picture, "Events", id);
      setClick(true);

    }
  }

  const handleSubmitarrayItems = (choices) => e => {
    e.preventDefault();
    setTicketChose(choices)
    var sum = 0;
    var sum1 = 0;
    choices.map(x => {
      sum += x.totalprice
      sum1 += x.amount

    })
    setTotal(sum)
    setTotalAmount(sum1)
  };

  const comleteTax = async () => {
    handleClose()
    setClickOrder(true)
    if (!user) {
      alert("להזמנת כרטיסים, יש להתחבר למערכת");
      return;
    }
    else if (
      name === "" ||
      ticketOption === "" ||
      count === 0 ||
      user.uid === "" ||
      user.email === ""

    ) alert("משהו השתבש");
    else {
      setLoading1(true)
      let products = []
      let sum = 0;
      ticketChose.forEach(x => {
        sum += x.amount;
        let var1 = {
          id: id,
          name: x.name + name,
          amount: x.amount,
          price: parseFloat(x.price) * x.amount,
          kind: "Event"
        }
        products.push(var1)
      })
      // completeFullOrder(uid, nameuser, emailuser, ticketChose, total, "יתרת חשבון", 0, 0)
      const payment = await completeOrderFromBar(user.uid, nameuser, emailuser, products, total, "מיסי הישוב", 0, 0, "event", name, date);
      if (payment) {
        const ticket = await uploadTicket(uid, name, date, ticketChose, user.uid, nameuser, emailuser, total, "מיסי הישוב");
        removeFromStorageTickets(id, sum)
        if (ticket != "") {
          alert("כרטיסים לאירוע מחכים לך באיזור הפריטים שלי")
          history.push("/favorites")
          setLoading1(false)
        }
      }

    }
  };
  const comletePayBox = async () => {
    handleClose1()
    setClickOrder(true)
    if (!user) {
      alert("להזמנת כרטיסים, יש להתחבר למערכת");
      return;
    }
    else if (
      name === "" ||
      ticketOption === "" ||
      count === 0 ||
      user.uid === "" ||
      user.email === ""

    ) alert("משהו השתבש");
    else {
      let products = []
      let sum = 0
      ticketChose.forEach(x => {
        sum += x.amount;
        let var1 = {
          id: id,
          name: x.name + name,
          amount: x.amount,
          price: parseFloat(x.price) * x.amount,
          kind: "Event"
        }
        products.push(var1)
      })
      setLoading1(true)
      // completeFullOrder(uid, nameuser, emailuser, ticketChose, total, "יתרת חשבון", 0, 0)
      let newBalance = balance - total
      console.log("newBalance", newBalance);
      const update = await updateUserAccountBalance(user.uid, total)
      if (update) {
        alert("יתרת החשבון החדשה: " + newBalance)
        const payment = await completeOrderFromBar(user.uid, nameuser, emailuser, products, total, "יתרת חשבון", 0, 0, "event", name, date);
        if (payment) {
          const ticket = await uploadTicket(uid, name, date, ticketChose, user.uid, nameuser, emailuser, total, "יתרת חשבון");
          removeFromStorageTickets(id, sum)
          if (ticket != "") {
            alert("כרטיסים לאירוע מחכים לך באיזור הפריטים שלי")
            history.push("/favorites")
            setLoading1(false)
          }
        }
      }
    }
  };
  const sendOrder = async () => {
    console.log("sendOrder", paymentMethod + fullName + phoneNumber);
    // setClickOrder(true)
    if (
      fullName === "" ||
      phoneNumber === "" ||
      paymentMethod === ""
    ) alert("אחד מהפרטים חסר");
    else {
      handleClosePayment()
      if (paymentMethod == "PayBox") {
        const send = await sendEventOrder(uid, name, date, fullName, phoneNumber, paymentMethod, ticketChose, total)
        if (send) {
          alert("הזמנתך נשלחה בהצלחה, הינך מועבר להמשך תשלום");
          window.location.assign('https://payboxapp.page.link/tMUCWvfnFRsiFeHV9');
        }
      }
      else {
        const send = await sendEventOrder(uid, name, date, fullName, phoneNumber, paymentMethod, ticketChose, total)
        if (send) {
          alert("הזמנתך נשלחה בהצלחה, אנא המתן עד לקבל הודעת וואטסאפ ממפיקי האירוע");
        }
      }
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open1, setOpen1] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [openPayment, setOpenPayment] = useState(false);

  const handleClickOpenPayment = () => {
    setOpenPayment(true);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };


  const [openComing, setOpenComing] = useState(false);

  const handleClickOpenComing = () => {
    setOpenComing(true);
  };

  const handleCloseComing = () => {
    setOpenComing(false);
  };


  const comleteEvent = async () => {
    if (!user) {
      alert("להזמנת כרטיסים, יש להתחבר למערכת");
      return;
    }
    else if (
      name === "" ||
      totalAmount === 0 ||
      user.uid === "" ||
      user.email === ""

    ) alert("משהו השתבש");
    else {
      setLoading1(true)
      // completeFullOrder(uid, nameuser, emailuser, ticketChose, total, "יתרת חשבון", 0, 0)
      const x = { uid: user.uid, name: nameuser, total: totalAmount }
      setComingList(comingList.push(x))
      const update = await updateEventComing(uid, comingList)
      if (update) {
        alert("נתראה באירוע")
        setLoading1(false)

      }
    }
  };
  const regex = /^[\/\-\=\+]{1}$/;

  return (
    <>
      {/* <CartButton /> */}
      <div id="page-wrap" className={click ? 'top_padding background fade' : 'top_padding background'}
        onAnimationEnd={() => setClick(false)}
      >
        <div  >
          {loading1 === true && <center><br /><br /><RingLoader id="loading" color={"#a57733"} /></center>}
          {loading1 === false && <>
            <img className="product_image_view_new z_index_1 p3" src={picture} alt="" />
            <img src={line} className="line_png" alt="logo" />

            <div className="product_box">
              {props.close ? <>
                <br />
                <span onClick={props.close} className=" text_title_img text3  " >
                  <span className=" text3 w700 p3" ><CloseOutlined /></span>
                  <span className=" text3 p3" >{name.split(" ")[1] ? name.split(" ")[0] + " " + name.split(" ")[1] : name.split(" ")[0]}  </span>
                </span>
                {name.split(" ").length > 2 &&
                  regex.test(name.split(" ").slice(2)[0]) ?
                  <div className="text_center text_brown text3 w500 p3">{name.split(" ").slice(3).join(" ")}</div>
                  :
                  <div className="text_center text_brown text3 w500 p3">{name.split(" ").slice(2).join(" ")}</div>
                }
              </> : <>
                <div className="text_center text_brown text3 w500 p3">{name}</div>

                <br /></>}


              <div className="text_rtl text_brown text2 w500 p3">

                {description.map(x => {
                  return (
                    <>
                      {x}
                      <br />
                    </>
                  )
                })} </div>
              <br />
              <div className="text_rtl text_brown  text2 w600 p3">תאריך: {String(date).split("-").reverse().join("/")} <br /> שעה: {time} </div>
              <br />

              {ticketChose.length > 0 && <>
                <div className="text_rtl text_brown text2 w500 p3" >בחירת כרטיסים:</div>

                <EventUserChoices
                  choices={ticketChose}
                  userKind={userKind}
                  handleSubmit={handleSubmitarrayItems}
                />
              </>}
              {flag && <>
                <div className="text_center text_brown text2 w500 p3">נרשמת לאירוע!
                </div>

                <div className="p3">
                  <button id="payment-title" onClick={() => handleClickOpenComing()}>
                    צפה מי עוד מגיע
                  </button>
                </div>
              </>}
              <Example name={name} des={description.join(" ")} date={date} time={time} />

              {ticketChose.length == 0 && <>
                <div className="text_brown text_rtl text2 w500 p3">הכניסה חינם!
                  <br />
                  כמה אנשים תגיעו?</div>
                <div className="row_div_space">
                  {/* <div className="text_center text_brown  text1_5 w600" >כמות:</div> */}
                  <button className="text_brown text2 w500 p4 clear_button" disabled={totalAmount < 1}
                    onClick={() => setTotalAmount(totalAmount - 1)}
                  ><FaMinusCircle /></button>
                  <a className="text_brown text2 w500 p3 ">{totalAmount}</a>
                  <button className="text_brown text2 w500 p4 clear_button"
                    onClick={() => setTotalAmount(totalAmount + 1)}
                  ><FaPlusCircle /></button>
                </div>
                {totalAmount > 0 && <>
                  <div className="p3">
                    <button id="payment-title" disabled={clickOrder} onClick={() => comleteEvent()}>
                      שמור לי שולחן בפאב
                    </button>
                  </div>
                </>}
              </>}
            </div>
            {user && <>
              {totalAmount > 0 && ticketChose.length > 0 && <>
                <img src={line} className="line_png" alt="logo" />
                <spam className="text_black text2 w600">סה"כ: {total} ₪</spam>
                <br />
                <br />
                {tax === "true" && <>
                  <button id="payment-title" onClick={() => handleClickOpen()}>
                    {total === 0 ? "שמור לי שולחן בפאב" : "תשלום באמצעות מיסי הישוב"}
                  </button>
                </>
                }
                {tax === "true" && paybox === "true" && <>

                  <br /><br />
                </>}
                {paybox === "true" &&
                  <button id="payment-title" onClick={() => handleClickOpen1()}>
                    {total === 0 ? "שמור לי שולחן בפאב" : "תשלום באמצעות יתרה אישית"} </button>
                }
                {paybox === "false" && tax === "false" &&
                  <button id="payment-title" > לא נמצא אמצעי תשלום בחשבון זה</button>
                }


              </>
              }
            </>}


            {!user && <>
              {totalAmount > 0 && ticketChose.length > 0 && <>
                <img src={line} className="line_png" alt="logo" />
                <spam className="text_black text2 w600">סה"כ: {total} ₪</spam>
                <br />
                <br />
                <button id="payment-title" onClick={() => handleClickOpenPayment()}>המשך לתשלום
                </button>
              </>
              }
            </>}
            <br />

            <br />
          </>}
        </div>
        <ButtomPage />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className='background'
      >
        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text_dark text2_5 w500 p3'>אישור תשלום</div>
            <br />
            <div className='text_rtl text_brown text1_5 w300 p3'>סה"כ לתשלום: {total} ₪
              <div className='text1_3'>
                תשלום עבור האירוע יתבצע ממסי הישוב</div>
              <br />
            </div>
          </div>
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            ביטול
          </Button>
          <Button
            className='primary_button1'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            disabled={clickOrder}
            onClick={comleteTax}
            color="secondary"
          >
            אישור
          </Button>
        </div>
      </Dialog>

      <Dialog
        open={openPayment}
        onClose={handleClosePayment}
        aria-labelledby="form-dialog-title"
        className='background'
      >
        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>
            <div className='text_center text_dark text2_5 w500 p3'>הזמנת כרטיסים</div>


          </div>

          <br />
          <input
            type="text"
            className="__textBox"
            value={fullName}
            onChange={handleFullNameChange}
            placeholder="שם מלא"
          />
          <br />
          <input
            type="tel"
            className="__textBox"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="מספר טלפון"
          />
          <br />
          <select

            className="__textBox"
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="">בחר אמצעי תשלום</option>
            <option value="tax">מיסי הישוב</option>
            <option value="PayBox">PayBox</option>
            <option value="else">אחר</option>
          </select>
          <br />
          <div className='text_rtl text_brown text1_5 w300 p3'>
            סה"כ לתשלום: {total} ₪
          </div>
          {paymentMethod == "tax" &&
            <div className='text_rtl text_brown text1_5 w300 p3'>
              נא לוודא כי ישנה הוראת קבע פעילה במזכירות הישוב גבעון החדשה, לאחר אישור התשלום במזכירות הכרטיסים ישלחו אליך לנייד
            </div>}
          {paymentMethod == "PayBox" &&
            <div className='text_rtl text_brown text1_5 w300 p3'>
              מייד תועבר להמשך תשלום, לאחר ביצוע התשלום ישלחו אליך כרטיסים לנייד
            </div>}
          {paymentMethod == "else" &&
            <div className='text_rtl text_brown text1_5 w300 p3'>
              צוות הפאב יצור איתך קשר במהלך השבוע הקרוב
            </div>}
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleClosePayment}
            color="primary"
          >
            ביטול
          </Button>
          <Button
            className='primary_button1'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            disabled={clickOrder}
            onClick={sendOrder}
            color="secondary"
          >
            שלח הזמנה
          </Button>
        </div>
      </Dialog>


      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
        className='background'
      >

        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text_dark text2_5 w500 p3'>אישור תשלום</div>
            <br />
            <div className='text_rtl text_brown text1_5 w300 p3'>סה"כ לתשלום: {total} ₪
              <div className='text1_3'>
                תשלום עבור האירוע יתבצע מיתרת החשבון</div>
              <br />
            </div>
          </div>
          <Button
            className='cancel_button1'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleClose1}
          >
            ביטול
          </Button>
          <Button
            className='primary_button1'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={comletePayBox}
            disabled={clickOrder}
          >
            אישור
          </Button>
          <br /> <br />
        </div>
      </Dialog>

      <Dialog
        open={openComing}
        onClose={handleCloseComing}
        aria-labelledby="form-dialog-title"
        className='background'
      >
        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text_dark text2_5 w500 p3'>רשומים לאירוע</div>
            <div className='text_rtl text_brown text1_5 w300 p3'>
              {
                comingList.map(x => {
                  return (
                    <>
                      {x.name} : {x.total}
                      <br />
                    </>
                  )
                })
              }
            </div>
          </div>
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleCloseComing}
            color="primary"
          >
            סגירה
          </Button>
        </div>
      </Dialog>

    </>
  )


}
export default memo(EView)
