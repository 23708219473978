import React, { Component } from 'react';
import Modal from './modal';
import Trigger from './triggerButton';
export class Container extends Component {
    
    state = { isShown:String(this.props.kind).includes("welcome")? true:false };
    
    showModal = () => {
        this.setState({ isShown: true }, () => {
          //  this.closeButton.focus();
        });
        // this.toggleScrollLock();
    };
    closeModal = () => {
        this.setState({ isShown: false });
      //  this.TriggerButton.focus();
      //  this.toggleScrollLock();
    };
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };
    onClickOutside = (event) => {
        if (this.modal && this.modal.contains(event.target)) return;
        // this.toggleScrollLock();
        this.closeModal();
    };

    toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };
    render() {
        return (
            <React.Fragment >
                
                {!String(this.props.kind).includes("welcome")&&
                <Trigger
                    showModal={this.showModal}
                    buttonRef={(n) => (this.TriggerButton = n)}
                    triggerText={this.props.triggerText}
                />}
                
                {this.state.isShown ? (
                    <Modal
                        kind={this.props.kind}
                        onSubmit={this.props.onSubmit}
                        modalRef={(n) => (this.modal = n)}
                        buttonRef={(n) => (this.closeButton = n)}
                        closeModal={this.closeModal}
                        onKeyDown={this.onKeyDown}
                        onClickOutside={this.onClickOutside}
                        triggerText={this.props.triggerText}
                        //onClickOutside={this.props.kind=='welcome'?null:(this.onClickOutside)}

                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default Container;
