import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from 'react'
import { database } from "../../firebase";


function BarTips(props) {

    const [tip, setTip] = useState(0);


    useEffect(() => {
        const query = ref(database, "bar");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            setTip(data.tips)
        });
    }, []);

    return (


        <div  className="text1 green-digital-clock" >
            טיפים:{` `}{tip} ₪
        </div>


    )
}

export default BarTips
