import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import AllOrders from "./components/allOrders";
import AllUsers from "./components/allUsers";
import ComingOrders from "./components/comingOrders";
import ComingUsers from "./components/comingUsers";
import SumOrders from "./components/sumOrders";
import AllEvents from "./components/event/allEvents";
import ComingPayments from "./components/comingPayments";
import AllUsersBalance from "./components/users/allUserBalance";
import ManagmentView from "./managmentView";
import ManagePayments from "./mangmentPayments";
import ManageUsers from "./mangmentUsers";
import ManageOrders from "./mangmentOrders";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: { main: "#a57733" },
    secondary: { main: "#f8e7ce" },
  },
});


function Managment() {


  const [value, setValue] = useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const exit = () => {
    history.push("/")
  }
  const bar = () => {
    history.push("/bar")
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <button className="navbar"
          onClick={exit}> סגירת מסך מנהל
        </button>
        <div id="page-wrap-m">
          <h1>מסך מנהל
            <br />

            <button
              onClick={bar}> מעבר למסך בר
            </button>
          </h1>

          <AppBar position="flex" >
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab label="טפסים ופעולות" {...a11yProps(0)} />
              <Tab label="תשלומים" {...a11yProps(1)} />
              <Tab label="לקוחות" {...a11yProps(2)} />
              <Tab label="הזמנות" {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <ManagmentView />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ManagePayments />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ManageUsers />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ManageOrders />
          </TabPanel>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Managment
