import React from "react";
import Select from "react-select";



export const formatChoices = choices => {
    return choices;
};




class InventoryChoices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            choices: props.choices
        };
        console.log(props.choices)
    }



    handleChoiceChange = index => e => {

        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, name: e.value, label: e.label };
        });
        this.setState({ choices: newChoices });
    };

    handleAnswerChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            return { ...choice, amount: e.target.value };
        });
        this.setState({ choices: newChoices });

    };

    handleRemoveChoice = index => () => {
        this.setState({
            choices: this.state.choices.filter((choice, sidx) => index !== sidx)
        });
    };

    handleAddChoice = () => {
        this.setState({
            choices: this.state.choices.concat([{ name: "", amount: "", label: "" }])
        });
    };




    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;
        const { items } = this.props;

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                {choices.map((choice, index) => (
                    <div key={["choice", index].join("__")}>
                        <Select
                            value={""}
                            onChange={this.handleChoiceChange(index)}
                            options={items}
                            placeholder="הקלד או בחר קטגוריה"
                        />
                        <input
                            type="text"
                            className="__textBoxSmall"
                            value={choice.label}
                            onChange={this.handleChoiceChange(index)}
                            onMouseLeave={handleSubmit(
                                formatChoices(this.state.choices)
                            )}
                            placeholder="שם פריט"
                        />
                        <input
                            name="text"
                            className="__textBoxSmall"
                            value={choice.amount}
                            onChange={this.handleAnswerChange(index)}
                            onMouseLeave={handleSubmit(
                                formatChoices(this.state.choices)
                            )}
                            placeholder="כמות מתוך 1"
                        />
                        <button
                            className="button"
                            onClick={this.handleRemoveChoice(index)}
                            type="submit" >
                            מחיקה
                        </button>
                    </div>
                ))}
                <button className="button" type="button" onClick={this.handleAddChoice}>
                    הוסף מרכיב
                </button>
                <button className="button_b" type="submit">רענן</button>
            </form>
        );
    }
}

export default InventoryChoices;
