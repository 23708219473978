

import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { addCoupon, database, deletaFromRealTimeDb } from "../../../../firebase";

const TicketCardManager = (props) => {
  const [click, setClick] = useState(false);

  const remove = async () => {
    const query = ref(database, "Events/" + props.EUid);
    let sum = 0
    props.ETicket.map(x => {
      sum += x.amount
    })
    return onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val(); 
        console.log("dataEvents")
        console.log(props)
        if (data.coupon.length > 0)
        data.coupon.forEach((element, index) => {
          if (sum > 0)
            addCoupon(element.name, parseInt(element.price), parseInt(element.amount * sum), props.userUid, element.detail, data.picture, "off")
          if (index == 0)
            alert("קופון נוסף ללקוח")
        });
        deletaFromRealTimeDb(props.uid, "Tickets")
        props.onChange()
      }
    }, {
      onlyOnce: true
    });
  };


  return (
    <>
      <div id="product-master-new" className={click ? 'fade_out' : ''}
        onAnimationEnd={() => setClick(false)}
      >
        <div >
          <span className="product_p1 text_black text2 w600">{props.EName}</span>
          <br />
          <span className="product_p1 text_black text1_5 w600">{String(props.Edate).split("-").reverse().join("/")}</span>
          <div className="split_div">
            <div className="right_div p2 height30">
              <span className="text_black text2 w700">{props.name}</span>
              <button disabled={props.name === ""} className="product_button_new" onClick={remove} >אישור כרטיס</button>


            </div>
            <div className="right_div p1">
              {props.ETicket &&
                <>
                  <span className="text_black text2 w700">כרטיסים</span>
                  {props.ETicket.map(x => {
                    return (
                      <>
                        {x.amount != 0 &&
                          <span className="text_black text1_5 w600">{x.name} : {x.amount}</span>}
                      </>
                    )
                  })}
                </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketCardManager;
