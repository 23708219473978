import { onValue, ref } from 'firebase/database';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { RingLoader } from 'react-spinners';
import { Label } from 'semantic-ui-react';
import { database, uploadList } from '../../../../firebase';
import ListChoices from './listChoices';

function WorkerFormEdit(props) {
  var type = props.type == "open" ? ("פתיחת בר") : ("סגירת בר")
  var text = "עדכן רשימה עבור- " + type

  const [list, setList] = useState([]);
  const history = useHistory();

  const managment = () => {
    history.push("/xmanagment")
  }

  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    e.preventDefault();
    if (
      list.length === 0
    ) alert("מידע חסר");
    else {
      // console.log(props.type)
      uploadList(list, props.type)
    }
  };

  const handleSubmitarrayItems = (choices) => e => {
    e.preventDefault();
    let x = [];
    choices.map(item => {
      if (item.name !== "")
        x.push(item.name)
    })
    setList(x)
    console.log(x);
  };

  const [loading1, setLoading1] = useState(true);
  const [comingList, setComingList] = useState([]);

  useEffect(() => {
    const query = ref(database, "list/" + props.type + "/check_list");
    return onValue(query, (snapshot) => {
      setComingList([]);
      const data = snapshot.val();
      const logs = [];

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          const data1 = {
            name: project,
          };
          logs.push(data1);
        });
        setComingList(logs);
        setLoading1(false);
      }
    });
  }, []);


  return (
    <>
      {loading1 == true ?
        (
          <center id="loading" >
            <RingLoader id="loading" color={"#a57733"} />
          </center>
        )
        :
        (
          <>
            <h3> משימות עבור {type}: </h3>
            <br />
            {list.length !== undefined && list.length > 0 &&
              <Label>סך כל המשימות: {list.length}</Label>
            }
            <ListChoices
              choices={comingList}
              handleSubmit={handleSubmitarrayItems}
            />

            <br />
            <input className='button' type="Button" value={text} onClick={Submit} />
            <br />
          </>
        )}
    </>
  );
}

export default WorkerFormEdit
