//import useState hook to create menu collapse state
import { ClassNames } from "@emotion/react";
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";


import { useAuthState } from "react-firebase-hooks/auth";
//import icons from react icons
import { BsCartCheckFill } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { addCoupon, auth, db } from "../../../firebase";




const MessagesButton = () => {

  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [messagesData, setMessagesData] = useState([]);
  const [change, setChange] = useState(false);
  const [open, setOpen] = useState(false);
  const toCart = () => {
    history.push("cart")
  }

  const onChange = () => {
    setChange(!change);
  }

  const fetchMessages = async () => {

    try {
      const q = query(collection(db, "Messages"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const logs = [];
      doc.forEach(data => {
        const data1 = {
          id: data.id,
          title: data.data().title,
          description: data.data().message,
          amount: data.data().amount,
          price: data.data().price,
          picture: data.data().picture,
          kind: data.data().kind,
          date: data.data().date,
          time: data.data().time,
        };
        logs.push(data1);

      });
      console.log("************fetchMessages*************")
      console.log(messagesData)
      setMessagesData([]);
      setMessagesData(logs);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    fetchMessages();
  }, [user, loading, change]);

  const removeMessage = async (id) => {
    try {
      const docRef = doc(db, "Messages", id);
      await deleteDoc(docRef);
      onChange();
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
  };
  const removeCoupon = async (id, title, description, price, amount, picture) => {
    try {
      addCoupon(title, price, amount, user.uid, description, picture, "off").then
        (alert("מחכה לך קופון באיזור הפריטים שלי"))
      const docRef = doc(db, "Messages", id);
      await deleteDoc(docRef);
      onChange();
    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }
  };

  return (
    <>
      {messagesData.length > 0 && <>

        <div id="message-layout" className={'fade_in'}>
          {/* <div id="message_master"> */}
          <div id="message_master"
          >
            <div id="message-button1">הודעות נכנסות: {messagesData.length}
              {!open && <button className="product_button" onClick={() => { setOpen(!open) }}>פתח</button>}

              {open && <>
                <button className="product_button" onClick={() => { setOpen(!open) }}>סגור</button>
                {messagesData.map(
                  (info) => {
                    if (info.kind === "message")
                      return (

                        <div id="message-wrap">
                          <span id="title2">התקבלה בתאריך: {String(info.date).split("-").reverse().join("/")} | בשעה: {info.time}</span>
                          <br />
                          <span id="menu-title1">{info.title}</span>
                          <br />
                          <span className="product_price" >{info.description}</span>
                          <br />
                          <button className="product_button" onClick={() => removeMessage(info.id)}>מחיקת הודעה</button>
                        </div>
                      )
                    if (info.kind === "coupon")
                      return (

                        <div id="message-wrap">
                          <span id="title2">התקבלה בתאריך: {String(info.date).split("-").reverse().join("/")} | בשעה: {info.time}</span>
                          <br />
                          <h id="menu-title1">{info.title}</h>
                          <br />
                          <h className="product_price" >{info.description}</h>
                          <br />
                          <button className="product_button" onClick={() => removeCoupon(info.id, info.title, info.description, info.price, info.amount, info.picture)}>הוסף לקופונים שלי</button>
                        </div>
                      )

                  })}
              </>}
            </div>
          </div>
        </div>

      </>}
    </>
  );
};

export default MessagesButton;
