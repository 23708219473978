import React from 'react';
import TextField from '@material-ui/core/TextField';

export const FormCoupon = ({ onSubmit }) => {

  return (
    <form onSubmit={onSubmit} >

      <label className="__text" htmlFor="messagetitle">שם קופון:</label>
      <TextField
        className="__textBox"
        id="messagetitle"
        placeholder="שם"
      />
      <br />
      <label className="__text" htmlFor="messagetext">פירוט:</label>
      <br />
      <textarea
        type="text"
        className="__textBoxMessage"
        id="messagetext"
        placeholder="פירוט"
      />
      <label className="__text" htmlFor="messageprice">מחיר:</label>
      <TextField
        type="number"
        className="__textBox"
        id="messageprice"
        placeholder="מחיר"
      />
      <br />
      <label className="__text" htmlFor="messageamount">כמות:</label>
      <TextField
        type="number"
        className="__textBox"
        id="messageamount"
        placeholder="כמות"
      />
      <br />
      <label className="__text" htmlFor="messageimage">לינק לתמונה:</label>
      <TextField
        type="text"
        className="__textBox"
        id="messageimage"
        placeholder="ניתן להשאיר שורה זו ריקה"
      />

      <div className="form-group">
        <button className="button" type="submit">
          שלח
        </button>
      </div>
    </form>




  );
};
export default FormCoupon;
