import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { BsCalendarDate, BsCalendarDateFill, BsCartCheckFill } from 'react-icons/bs';
import { color } from '@mui/system';
import { red } from '@material-ui/core/colors';
import { DataArrayOutlined, DateRangeOutlined } from '@mui/icons-material';

export const SidebarDataUser = [
  {
    title: 'בית',
    path: '/home',
    icon: <AiIcons.AiFillHome className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },
  {
    title: 'אירועים',
    path: '/events',
    icon: <BsCalendarDateFill className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },
  {
    title: 'תפריט',
    path: '/menu',
    icon: <IoIcons.IoIosPaper className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },

  {
    title: 'עגלת קניות',
    path: '/cart',
    icon: <BsCartCheckFill className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },
  {
    title: 'הפריטים שלי',
    path: '/favorites',
    icon: <IoIcons.IoMdHeart className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  },
  {
    title: 'איזור אישי',
    path: '/personal',
    icon: <FaIcons.FaUserCircle className='menu-icon' color={"#a57733"} />,
    cName: 'nav-text'
  }

];