

import { memo, useState } from "react";
import { Input } from "semantic-ui-react";
import { deletaFromRealTimeDb, updateStorage } from "../../../../firebase";

const StorageCard = (props) => {
  // console.log(props)
  const [amount, setAmount] = useState(props.amount);
  const [amountBar, setAmountBar] = useState(props.amountbar);
  const [amountBarMax, setAmountBarMax] = useState(props.amountbarmax);
  const [amountMax, setAmountMax] = useState(props.amountmax);
  const [location, setLocation] = useState(props.location);

  const update = () => {
    updateStorage(props.id, amount, amountBar, amountMax, amountBarMax, location)
  };

  const deleteData = () => {
    deletaFromRealTimeDb(props.id, "Storage")
  };

  return (
    <div id="page-inventory">
      <Input
        type="number"
        className="__textBoxSmall"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        placeholder="מיקום"
      />
      <text className="text text1_3 w400 width40">{props.name}  </text>
      <Input
        type="number"
        className="__textBox"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="כמות במלאי"
      />
      <br />
      <Input
        type="number"
        className="__textBox"
        value={amountBar}
        onChange={(e) => setAmountBar(e.target.value)}
        placeholder="כמות בבר"
      />
      <Input
        type="number"
        className="__textBox"
        value={amountMax}
        onChange={(e) => setAmountMax(e.target.value)}
        placeholder="כמות מקסימום במחסן"
      />
      <br />
      <Input
        type="number"
        className="__textBox"
        value={amountBarMax}
        onChange={(e) => setAmountBarMax(e.target.value)}
        placeholder="כמות מקסימום בבר"
      />
      <br />
      {props.location !== "מיקום" &&
        <button className="product_button width40" onClick={update}>עדכון</button>}

      {props.location !== "מיקום" &&
        <button className="product_button width40" onClick={deleteData}>מחיקה</button>}
    </div>
  );
};

export default memo(StorageCard);
