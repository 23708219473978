

import { ref  } from "firebase/database";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth, database, db, deletaFromRealTimeDb } from "../../../../firebase";

const ProductCard = (props) => {

  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);
  useEffect(() => {
    if (loading) return;

  }, [user, loading]);

  const remove =  () => {
      deletaFromRealTimeDb(props.id, "Products")
  };




  const watch = () => {
    history.push("/xmanagment/menuedit/" + (props.id));

  }

  return (
    <>

      <div id="product-master" className={click ? 'fade' : ''}
        onAnimationEnd={() => setClick(false)}
      >
      {/* <span className="product_name">כותרת{props.category}</span> */}
        <img className="product_pic" src={props.img} />
        
        
        <span className="product_name">{props.name}</span>
        
        <span className="product_price">{props.des}</span>
        
        <span className="product_price" >מחיר: {props.price} ₪</span>
        <div className="btns">


          <>

          <button className="product_button" onClick={watch}>עריכת פריט</button>
          
          <button className="product_button" onClick={remove}>מחיקה</button>
          </>

        </div>

      </div>










    </>
  );
};

export default ProductCard;
