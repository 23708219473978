import React, { memo, useState } from 'react'
import { useHistory } from "react-router-dom";

import { onValue, ref } from "firebase/database";
import { useEffect } from 'react';
import { database } from '../../../../firebase';
import { Navbar } from 'react-bootstrap';
import CategoryCard from '../../../menu/components/CategoryCard';
import CategoryCardEdit from './CategoryCardEdit';



function CategoryEdit() {

    const [categories, setCategories] = useState([]);
    const history = useHistory();

    const [menuData, setMenuData] = useState([]);



    useEffect(() => {
        console.log("******useEffect-menu-Category************")
        const query = ref(database, "Category");
        return onValue(query, (snapshot) => {

            setCategories([])
            const data = snapshot.val();
            const logs = [];
            if (snapshot.exists()) {

                Object.values(data).sort((a, b) => parseInt(a.location) - parseInt(b.location)).map((project) => {
                    setCategories((projects) => [...projects, project]);
                });

            }
        });
    }, []);

    useEffect(() => {
        console.log("******useEffect-menu-Products************")
               const query = ref(database, "Products");
        return onValue(query, (snapshot) => {
            setMenuData([])
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((project) => {
                    setMenuData((projects) => [...projects, project]);
                });
            }
        });
    }, []);

    return (


        <>
                <div id="page-wrap">
                    <div className='text_welcome text2_5 w600'>עריכת קטגוריות</div>
                    <div className='text_welcome text1_5 w600'>גבעון החדשה</div>



                    <div id="page-menu">
                        {categories.map((category) => (
                            <CategoryCardEdit
                                key={category.uid}
                                id={category.uid}
                                subtitle={category.subtitle}
                                img={category.picture}
                                title={category.title}
                                menuData={menuData}
                                location={category.location}
                            />
                        ))}
                    </div>
                </div>

        </>
    );
}

export default memo(CategoryEdit)
