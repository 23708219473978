import React from "react";
import { atcb_init } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';

const Example = (props) => {
    console.log(props)

    React.useEffect(() => { atcb_init() }, []);
    return (
        <div className="atcb p3">
            {'{'}
            "name":"{props.name}",
            "description":"{props.des}",
            "startDate":"{props.date}",
            "endDate":"{props.date}",
            "location":"{"כליל החורש 24 גבעון החדשה"}",
            "label":"{" הוסף אירוע ללוח השנה "}",
            "options":[
            "Apple",
            "Google"
            ],
            "iCalFileName":"Reminder-Event"
            {'}'}
        </div>
    );
}
export default Example;