import React from 'react';
import TextField from '@material-ui/core/TextField';

export const Form = ({ onSubmit }) => {
    return (
        <form onSubmit={onSubmit}  className=" background p5" >
            <label className="__text" htmlFor="messagetitle">נושא:</label>
            <input
                className="__textBoxTitle"
                id="messagetitle"
                placeholder="נושא"
            />

            <br />
            <label className="__text" htmlFor="messagetext">הודעה:</label>
            <br />
            <textarea
                type="text"
                className="__textBox"
                id="messagetext"
                placeholder="ההודעה שלי"
            />
            <div className="form-group">
                <button className="menu_button primary_button" type="submit">
                    שלח
                </button>
            </div>
        </form>
    );
};
export default Form;
