import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import AllUsersBalance from "./components/users/allUserBalance";
import ComingPayments from "./components/comingPayments";
import SumOrdersDailySortable from "./components/sumOrdersDailyPaybox";

function ManagePayments() {
  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <div

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <>
      <ThemeProvider theme={theme}>
          <div className="page-wrap">

            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="יתרות חשבון" {...a11yProps(0)} />
                <Tab label="תשלומים נכנסים" {...a11yProps(1)} />
                <Tab label="לקוחות אחרונים משלמי יתרת חשבון" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <AllUsersBalance />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ComingPayments />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SumOrdersDailySortable />
            </TabPanel>
          </div>
      </ThemeProvider>
    </>
  )
}
export default ManagePayments
