
import { onValue, ref } from "firebase/database";
import { memo, useEffect, useState } from "react";
import { database } from "../../../firebase";

const ComingListForEvent = (props) => {
    console.log(props)
    const [events, setEvents] = useState([]);

    useEffect(() => {
        console.log("******useMemo-events************")
        const query = ref(database, "Tickets");
        return onValue(query, (snapshot) => {
            setEvents([])
            const data = snapshot.val();
            console.log(data)
            if (snapshot.exists()) {
                Object.values(data).filter(x => x.eventUid === props.event).map((project) => {
                    setEvents((projects) => [...projects, project]);
                    return project;
                });
            }
        }, {
            onlyOnce: true
        });
    }, []);





    return (
        <>
            {events.map((event, i) => (
                <>
                    {event.userName} : {
                        event.eventTickets.map((x, i) => {
                            if (event.eventTickets.length == i + 1)
                                return (
                                    <>
                                        {x.amount}
                                    </>
                                )
                            else return (
                                <>
                                    {x.amount} {" + "}
                                </>
                            )
                        })
                    }
                    <br />
                </>
                // <TicketCardManagment
                //     name={event.userName}
                //     email={event.userEmail}
                //     userUid={event.userUid}
                //     Edate={event.eventDate}
                //     EName={event.eventName}
                //     ETicket={event.eventTickets}
                //     EUid={event.eventUid}
                //     total={event.total}
                //     key={event.uid}
                //     uid={event.uid}
                //     status={event.status}
                //     note={event.note}
                //     payment={event.payment}
                //     onChange={onChange}
                // />
            ))}
        </>
    );
};

export default ComingListForEvent;
