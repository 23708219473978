import { useEffect, useRef, useState } from "react";
import { database, db } from "../../../../firebase";
import { onValue, ref } from "firebase/database";
import TicketCardManager from "./TicketCardManager";
import { QrReader } from "react-qr-reader";

const EventScan = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userUid, setUserUid] = useState("");
    const [eventName, seteventName] = useState("");
    const [eventUid, seteventUid] = useState("");
    const [eventDate, seteventDate] = useState("");
    const [eventTickets, seteventTickets] = useState([]);
    const [total, settotal] = useState("");
    const [uid, setUid] = useState("");

    const handleScan = (data) => {
        if (data != null)
            setUid(data.text)
    }
    const handleError = (err) => {
        console.error(err)
    }
    const previewStyle = {
        height: 240,
        width: 320,
    }
    useEffect(() => {
        if (uid !== "") {
            console.log(uid)
            const query = ref(database, "Tickets/" + uid);
            return onValue(query, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                console.log(snapshot);
                if (snapshot.exists()) {
                    seteventTickets(data.eventTickets);
                    seteventName(data.eventName);
                    seteventDate(data.eventDate);
                    settotal(data.total);
                    seteventUid(data.eventUid);
                    setUserUid(data.userUid);
                    setEmail(data.userEmail)
                    setName(data.userName);
                }
            }, {
                onlyOnce: true
            });
        }
    }, [uid]);

    return (
        <>
            {uid === "" ? (
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            setUid(result?.text);
                        }
                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    constraints={{ facingMode: 'environment' }}
                    style={{ width: '100%' }}
                />
            ) : (
                <TicketCardManager
                    name={name}
                    email={email}
                    userUid={userUid}
                    Edate={eventDate}
                    EName={eventName}
                    ETicket={eventTickets}
                    EUid={eventUid}
                    total={total}
                    key={uid}
                    uid={uid}
                    onChange={() => setUid("")}
                />
            )}





        </>
    );



}
export default EventScan