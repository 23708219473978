import React, { useState } from 'react';
import { GiBubbles } from 'react-icons/gi';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import leftBubble from '../../../image/leftBubble.png'
import rightBubble from '../../../image/rightBubble.png'
import { Link } from 'react-router-dom';

export const FormWelcomeMinus = ({ onSubmit, onClose, triggerText }) => {

  return (
    < >
      <div className='page-wrap background'>
        <Box sx={{
          minWidth: '100%',
          marginTop: "0 0 0 0",
          padding: '40px 40px 40px 40px'
        }}>

          <div className='text_center text_brown text2_5 w500'>
            {triggerText}
          </div>
        </Box>
        <center>
          <Link style={{ textDecoration: 'none' }} to={{
            pathname: '/personal'
          }}>
            <div id="payment-title" className="">מעבר לאיזור האישי
            </div></Link>
        </center>
        <br />
      </div>

      {/* 
      <div >
        <button className="welcome_button"  onClick={onClose}>
          הזכר לי מאוחר יותר
        </button>
      </div>
      <br/> */}

      {/* <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} color={"#fff"} />}
        label="אל תציג הודעה זו שוב"
      /> */}
    </>
  );
};
export default FormWelcomeMinus;
