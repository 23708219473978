import { ArrowRight, CloseOutlined } from "@mui/icons-material";
import { onValue, ref } from "firebase/database";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../../../../firebase";
import TicketCardManager from "./TicketCardManager";
import TicketCardManagment from "./TicketCardManagment";

const TicketsEditView = () => {
  const [user, loading] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [touchPosition, setTouchPosition] = useState("");
  const [events, setEvents] = useState([]);
  const [change, setChange] = useState(false);

  useEffect(() => {
    console.log("******useMemo-events************")
    const query = ref(database, "Tickets");
    return onValue(query, (snapshot) => {
      setEvents([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          setEvents((projects) => [...projects, project]);
          return project;
        });
      }
    }, {
      onlyOnce: true
    });
  }, [change]);

  const watch = () => {
    setClick(!click)
    // scrollToTop()
  }
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 10) {
      // close()
    }
    if (diff < -10) {
      watch()
    }
    setTouchPosition(null)
  }

  const onChange = () => {
    setChange(!change);
  }



  return (
    <>

      {events.map((event, i) => (

        <TicketCardManagment
          name={event.userName}
          email={event.userEmail}
          userUid={event.userUid}
          Edate={event.eventDate}
          EName={event.eventName}
          ETicket={event.eventTickets}
          EUid={event.eventUid}
          total={event.total}
          key={event.uid}
          uid={event.uid}
          status={event.status}
          note={event.note}
          payment={event.payment}
          onChange={onChange}
        />
      ))}
    </>
  );
};

export default memo(TicketsEditView);
