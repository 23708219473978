import { useState } from "react";
import { updateStorageAmount, updateStoragebar } from "../../../firebase";


const InventoryStorageControl = (props) => {
  const [click, setClick] = useState(false);
  const [view, setView] = useState(true);

  const update1 = () => {
    setClick(true);
    let a = parseFloat(props.amount) - 1
    updateStorageAmount(props.id, a)
    let ab = parseFloat(props.amountBar) + 1
    updateStoragebar(props.id, ab)

  }
  const update6 = () => {
    setClick(true);
    let a = parseFloat(props.amount) - 6
    updateStorageAmount(props.id, a)
    let ab = parseFloat(props.amountBar) + 6
    updateStoragebar(props.id, ab)
  }

  var amount = parseInt(props.amountBarMax - props.amountBar)
  if (props.amount - (props.amountBarMax - props.amountBar) < 0)
    amount = parseInt(props.amount)

  return (
    <>
      {view && amount > 0 &&
        <div className={click ? 'storage_box fade_out' : 'storage_box'} onAnimationEnd={() => setClick(false)}>
          <span className="text text2 w600 ">{props.name} </span>
          <span className="text text1_3 w400 "> כמות מומלצת להוצאה {amount}  </span>
          <button className="storage_button" disabled={click || amount - 1 < 0} onClick={update1}>הוצא 1</button>
          <button className="storage_button" disabled={click || amount - 6 < 0} onClick={update6}>הוצא 6</button>  </div>}
    </>
  );
};

export default InventoryStorageControl;
