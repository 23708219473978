

import { useEffect, useState } from "react";
import { doc as doc1, deleteDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { auth, completeOrderFromBar, db, uploadTicket } from "../../../firebase";
import { Button, Dialog } from "@mui/material";

const EventOrderCard = (props) => {

  const history = useHistory();

  const remove = async () => {

    try {
      const docRef = doc1(db, "EventOrder", props.id);
      await deleteDoc(docRef);

    } catch (err) {
      console.log(err);
      alert("אירעה שגיאה");
    }

  };

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link className="product_button"
        to='#'
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        {label}
      </Link>
    );
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openTicket, setOpenTicket] = useState(false);
  const handleClickOpenTicket = () => {
    setOpenTicket(true);
  };
  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const comleteTicket = async () => {
    handleCloseTicket()
    if (
      props.fullName === "" ||
      props.tickets === "" ||
      props.paymentMethod === "" ||
      props.phoneNumber === ""
    ) alert("משהו השתבש");
    else {
      let products = []
      let sum = 0;
      props.tickets.forEach(x => {
        sum += x.amount;
        let var1 = {
          id: props.id,
          name: x.name + " " + props.eventName,
          amount: x.amount,
          price: parseFloat(x.price) * x.amount,
          kind: "Event"
        }
        products.push(var1)
      })
      if (props.paymentMethod == "tax") {
        const payment = await completeOrderFromBar("Managment", props.fullName, "Managment", products, props.totalPrice, "מיסי הישוב", 0, 0, "event", props.fullName, props.eventDate);
        if (payment) {
          const ticket = await uploadTicket(props.eventUid, props.eventName, props.eventDate, props.tickets, "Managment", props.fullName, "Managment", props.totalPrice, "מיסי הישוב");

          if (ticket != "") {
            alert("כרטיסים נוצרו בהצלחה")
            remove()
          }
        }
      }
      if (props.paymentMethod == "PayBox") {
        const payment = await completeOrderFromBar("Managment", props.fullName, "Managment", products, props.totalPrice, "יתרת חשבון", 0, 0, "event", props.fullName, props.eventDate);
        if (payment) {
          const ticket = await uploadTicket(props.eventUid, props.eventName, props.eventDate, props.tickets, "Managment", props.fullName, "Managment", props.totalPrice, "יתרת חשבון");
          if (ticket != "") {
            alert("כרטיסים נוצרו בהצלחה")
            remove()
          }
        }
      }

    }
  };

  const text = encodeURIComponent(`
  שלום, הזמנת כרטיסים דרך אתר הפאב
  רכישת בכרטיסים הושלמה בהצלחה!

  נתראה באירוע הגדול של השנה 
  צוות פאב גבעון החדשה`);

  const url = `https://wa.me/+972${props.phoneNumber}?text=${text}`;
  return (
    <>
      <div id="product-master">
        <button className="product_button" onClick={handleClickOpen}> מחיקה מהמערכת</button>
        <span className="product_name">שם מלא: {props.fullName}</span>
        <span className="nav-space" >אירוע: {props.eventName} </span>
        <span className="nav-space" >אמצעי תשלום: {props.paymentMethod} </span>
        <span className="nav-space" >מחיר: {props.totalPrice} </span>
        <span className="text_black text2 w700">כרטיסים</span>
        {props.tickets.map(x =>
          <>
            {x.amount != 0 &&
              <span className="text_black text1_5 w600">{x.name} : {x.amount}</span>}
          </>
        )}

        {props.phoneNumber &&
          <a href={url} target="_blank" rel="noreferrer">
            <button className="product_button">שלח וואטסאפ: {props.phoneNumber}
            </button><br /><br /></a>}
        <button className="product_button" onClick={handleClickOpenTicket}>יצר כרטיס
        </button>

        {/* 
        <button className="product_button_multi" onClick={orderConfirm}>אישור הזמנה</button>
 */}

        <Dialog
          open={openTicket}
          onClose={handleCloseTicket}
          aria-labelledby="form-dialog-title"
          className='background'
        >
          <div className='text_center p3 background_white'>
            <br />
            <div className='text_rtl text1_5 w300'>

              <div className='text_center text_dark text2_5 w500 p3'>אישור כרטיס</div>
              <br />
              <div className='text_rtl text_brown text1_5 w300 p3'> שם:  {props.fullName}
                <div className='text1_3'>  סה"כ : {props.totalPrice} ₪ </div>
                <div className='text1_3'> אופן תשלום : {props.paymentMethod}</div>
                <div className='text1_3'> שם אירוע : {props.eventName} </div>
                <div className='text1_3'> כרטיסים: <br /> {props.tickets.map(x =>
                  <>
                    {x.amount != 0 &&
                      <span className="text_black text1_5 w600">{x.name} : {x.amount}<br /></span>}
                  </>
                )}</div>
                <br />
              </div>
            </div>
            <Button
              className='cancel_button1 '
              style={{ marginLeft: "10px", marginRight: "10px" }}
              variant="outlined"
              onClick={handleCloseTicket}
              color="primary"
            >
              ביטול
            </Button>
            <Button
              className='primary_button1'
              style={{ marginLeft: "10px", marginRight: "10px" }}
              variant="outlined"
              onClick={comleteTicket}
              color="secondary"
            >
              ייצר
            </Button>
          </div>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text2 w300'>האם אתה בטוח למחוק? </div>

          </div>
          <Button
            className='text_center'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            ביטול
          </Button>
          <Button
            className='text_center'
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={remove}
            color="secondary"
          >
            מחק
          </Button>
        </Dialog>






      </div >










    </>
  );
};

export default EventOrderCard;
