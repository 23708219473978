

import { Button, Dialog, MenuItem } from "@mui/material";
import { doc as doc1, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { addToCart, auth, db, updateTicketUser } from "../../../firebase";
import { customStyles } from "../../menu/components/Choices";
import Select from "react-select";
import ComingListForEvent from "./comingList";

const TicketCard = (props) => {
  // console.log(props.Ticket)
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);

  const remove = async () => {
    if (code == "") {
      alert("נא לבחור סיבת ביטול");
      return;
    }
    updateTicketUser(props.uid, code.label);
    handleClose();
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openComing, setOpenComing] = useState(false);

  const handleClickOpenComing = () => {
    setOpenComing(true);
  };

  const handleCloseComing = () => {
    setOpenComing(false);
  };

  return (
    <>
      <div id="product-master-new" className={click ? 'fade_out' : ''}
        onAnimationEnd={() => setClick(false)}
      >
        <div >
          <span className="product_p1 text_black text2 w600">{props.EName}</span>
          <br />
          <span className="product_p1 text_black text1_5 w600">{String(props.Edate).split("-").reverse().join("/")}</span>
          <div className="split_div">
            <div className="right_div p6 ">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={props.uid}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className="right_div p3">
              <>
                {props.ETicket && <>
                  <span className="text_black text1.5 w700">שם: {props.Ticket.userName}</span>
                  <span className="text_black text2 w700">כרטיסים</span>
                  {props.ETicket.map(x => {
                    return (
                      <>
                        {x.amount != 0 &&
                          <span className="text_black text1_5 w600">{x.name} : {x.amount}</span>}
                      </>
                    )
                  })}
                </>}
              </>
              <button className="product_button_new cancel_button1 text1_5 p1" disabled={props.status !== 0} onClick={handleClickOpen}>{props.status !== 0 ? ("הועבר לבדיקה") : ("ביטול כרטיסים")}</button>
              {/* {props.status === 0 &&
                <button className="product_button_new primary_button1 text1_5 p1" disabled={props.status !== 0} onClick={handleClickOpenComing}>מי מגיע?</button>} */}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className='text_center p3 background_white '>
          <br />

          <div className='text_center text_dark text2_5 w500 p3'>ביטול כרטיסים</div>
          <div className='text_rtl text_brown text1_5 w300 p3'>למה ברצונך לבטל כרטיסים? </div>
          <Select
            className="text_center text_brown text1_5 w500"
            // placeholder={"לבחירה"}
            // value={value}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={true}
            isSearchable={false}
            // options={choicesList.map(x => {
            //   return ({
            //     value: x,
            //     label: x
            //   })
            // })}
            onChange={(e) => setCode(e)}
            // className="__text"
            value={code}
            placeholder="בחר סיבת ביטול"
            styles={customStyles}
            options={[{
              value: "תקלה בהזמנה",
              label: "תקלה בהזמנה"
            }, {
              value: "אשמח לבטל כרטיסים",
              label: "אשמח לבטל כרטיסים"
            }, {
              value: "סיבה אחרת",
              label: "סיבה אחרת"
            }]
            }
          >
          </Select>
          <br />          <br />
          <br />      <br />

          <div className="row_div_space ">

            <Button
              className='cancel_button1'
              style={{ marginLeft: "10px", marginRight: "10px" }}
              variant="outlined"
              onClick={handleClose}
              color="primary"
            >
              ביטול
            </Button>
            <Button
              className='primary_button1'
              style={{ marginLeft: "10px", marginRight: "10px" }}
              variant="outlined"
              onClick={remove}
              color="secondary"
            >
              שלח
            </Button>

          </div>
        </div>
      </Dialog>

      <Dialog
        open={openComing}
        onClose={handleCloseComing}
        aria-labelledby="form-dialog-title"
        className='background'
      >
        <div className='text_center p3 background_white'>
          <br />
          <div className='text_rtl text1_5 w300'>

            <div className='text_center text_dark text2_5 w500 p3'>רשומים לאירוע</div>
            <div className='text_rtl text_brown text1_5 w300 p3'>
              <ComingListForEvent event={props.EUid} />
              {/* {
                comingList.map(x => {
                  return (
                    <>
                      {x.name} : {x.total}
                      <br />
                    </>
                  )
                })
              } */}
            </div>
          </div>
          <Button
            className='cancel_button1 '
            style={{ marginLeft: "10px", marginRight: "10px" }}
            variant="outlined"
            onClick={handleCloseComing}
            color="primary"
          >
            סגירה
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default TicketCard;
