

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiBasket } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { Input, Label } from "semantic-ui-react";
import { auth, storage, updateCategory, updateInventory, updateStorage, updateStorageAmount, updateStoragebar } from "../../../../firebase";
import MenuFood from "../../../menu/components/menuFood";

const InventoryCard = (props) => {
  const [amount, setAmount] = useState(parseFloat(props.amount).toFixed(2));
  const history = useHistory();
  const [click, setClick] = useState(false);

  const update = () => {
    setClick(true);
    if (props.kind==="storage") {
      updateStorageAmount(props.id, amount)
    }
    if (props.kind==="bar") {
      updateStoragebar(props.id, amount)
    }
    updateInventory(props.kind, props.id, amount)
  }

  return (
    <div className={click ? 'storage_box fade_out' : 'storage_box'} onAnimationEnd={() => setClick(false)}>
      <text className="text text1_3 w400 width40">{props.name}  </text>
      <Input
        type="number"
        className="__textBox"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="כמות במלאי"
      />
      <br />
      {props.location!=="מיקום"&&
      <button className="product_button width40"  disabled={click} onClick={update}>עדכון</button>}
    </div>
  );
};

export default memo(InventoryCard);
