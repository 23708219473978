import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { addCoupon, auth, completeFullOrder, database, db, deletaFromRealTimeDb, updateUserAccountBalance } from "../../../firebase";
import ProductCardCart from "./CartCard";
import { ImSad2 } from "react-icons/im";
import { doc as doc1, deleteDoc } from "firebase/firestore";
import payboxlogo from '../../../image/payBox.png';
import { BsCoin } from "react-icons/bs";
import { GrUserWorker } from "react-icons/gr";
import { onValue, ref } from "firebase/database";
import Gcoin from "../../../image/Gcoin1.png";
import { CloseOutlined, CurrencyExchangeRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import { BiShekel } from "react-icons/bi";
import PaymentDialog from "./paymentDialog";
import { MdCreditCard } from 'react-icons/md';

const CartView = () => {


  const [name, setName] = useState("");
  const [balance, setBalance] = useState(0);
  const [dataUid, setDataUid] = useState("");
  const [discount, setdiscount] = useState(0);
  const [phone, setPhone] = useState("");
  const [tax, setTax] = useState("");
  const [account, setAccount] = useState("");
  const [payBox, setPayBox] = useState("");
  const [change, setChange] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [menuData, setMenuData] = useState([]);
  let [sum, setSum] = useState(0);
  let [sum1, setSum1] = useState(0);
  let [sumUSD, setSumUSD] = useState(0);
  let [tip, setTip] = useState(0);
  let [note, setNote] = useState("");
  let [refresh1, setrefresh] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const history = useHistory();
  const toMenu = () => {
    history.push("/menu")
  }
  const navigateToTerms = () => {
    history.push('/terms_and_conditions');
  };
  const refresh = () => {
    setrefresh(refresh1 + 1);
  }
  const onChange = () => {
    setChange(!change);
  }

  const deleteCart = () => {
    menuData.forEach(async data => {
      const docRef = doc1(db, "Cart", data.uid);
      try {
        if (data.id === "Coupon") {
          addCoupon(data.name, data.price, data.amount, user.uid, data.description, data.picture, "off")
          deletaFromRealTimeDb(data.uid, "Cart")
          //await deleteDoc(docRef);
          onChange();
        }
        else
          deletaFromRealTimeDb(data.uid, "Cart")
        // await deleteDoc(docRef);
        onChange();
      } catch (err) {
        console.log(err);
        alert("אירעה שגיאה");
      }
    });
  };

  const deleteCartComplete = () => {
    menuData.forEach(async data => {
      const docRef = doc1(db, "Cart", data.uid);
      try {
        deletaFromRealTimeDb(data.uid, "Cart")
        //await deleteDoc(docRef);

      } catch (err) {
        console.log(err);
        alert("אירעה שגיאה");
      }
    });
    onChange();
  };

  useEffect(() => {
    if (tip >= 0) {
      var val1 = parseFloat(sum);
      var val2 = parseFloat(tip);
      var i = val1 + val2;
      setSum1(i);
      setSumUSD((Math.round(i * 1) / 3.5).toFixed(2));
    }
  }, [tip]);

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchUserName();
    }

    async function fetchUserName() {
      const query = ref(database, "Account/" + user.uid);
      return onValue(query, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data)
          setDataUid(data.uid);
          setName(data.name);
          setdiscount(data.discount);
          setPhone(data.phone);
          setTax(data.tax);
          setAccount(data.account);
          setPayBox(data.payBox);
          if (data.accountBalance) {
            console.log(data.accountBalance)
            setBalance(data.accountBalance)
          }
          else {
            updateUserAccountBalance(user.uid, 0)
          }
        }
      }, {
        onlyOnce: true
      });
    };
    // fetchProducts();
  }, [user, loading]);


  useEffect(() => {

    const query = ref(database, "Cart");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const logs = [];
      var sumsum = 0
      if (snapshot.exists()) {
        Object.values(data).filter(x => x.uid === user.uid).map((project) => {
          let pPrice = project.price * (100 - discount) / 100
          sumsum += pPrice;
          const data1 = {
            uid: project.keyUid,
            id: project.id,
            name: project.name,
            description: project.detail,
            amount: project.amount,
            price: pPrice,
            picture: project.picture,
            kind: project.kind
          };
          logs.push(data1);
        });
      }
      setMenuData(logs);
      setSum(sumsum);
      setSum1(sumsum)
      setTip(Math.round(sumsum / 10))
    });
    // fetchProducts();
  }, [discount]);


  const comleteTax = async () => {
    let result = completeFullOrder(user.uid, name, user.email, menuData, sum, "מיסי הישוב", tip, discount, note).then(deleteCartComplete()).then(history.push("/"))
    if (result) {
      alert("הזמנה על סך של " + sum.toFixed(2) + " ₪ נשלחה בהצלחה")
    }
  };
  const comleteBalance = async () => {
    let result = completeFullOrder(user.uid, name, user.email, menuData, sum, "יתרת חשבון", tip, discount, note).then(deleteCartComplete()).then(history.push("/"))
    if (result) {
      let x = (balance - sum - tip)
      if (x > 0)
        alert("לאחר קבלת ההזמנה, יתרת החשבון החדשה תהיה " + x.toFixed(2) + " ₪")
      else
        alert("לאחר קבלת ההזמנה, החשבון יכנס למינוס של " + x.toFixed(2) + " ₪")

    }
  };

  const comleteCrew = async () => {
    let result = completeFullOrder(user.uid, name, user.email, menuData, sum, "חשבון הבית", tip, discount, note).then(deleteCartComplete()).then(history.push("/"))
    if (result) {
      alert("הזמנה על חשבון הבית נשלחה בהצלחה")
    }
  };
  const comletePayBox = async () => {
    completeFullOrder(user.uid, name, user.email, menuData, sum, "PayBox", tip, discount, note).then(deleteCartComplete()).then(history.push("/"))
  };

  const fetchProducts = async () => {

    try {
      const q = query(collection(db, "Cart"), where("uid", "==", user.uid));
      const doc = await getDocs(q);
      const logs = [];
      let sum = 0;
      doc.forEach(data => {
        let price = parseFloat(data.data().price);
        sum = sum + price;
        setSum(sum);
        setSum1(sum)
        setSumUSD((Math.round(sum * 1) / 3.5).toFixed(2));

        const data1 = {
          uid: data.id,
          id: data.data().id,
          name: data.data().name,
          description: data.data().detail,
          amount: data.data().amount,
          price: data.data().price,
          picture: data.data().picture,
          kind: data.data().kind
        };
        logs.push(data1);

      });

      setMenuData([]);
      setMenuData(logs);

    } catch (err) {
      console.log(err);
    }
  };

  const handlePayment = () => {
    history.push({
      pathname: '/payment',
      state: { menuData, sum, tip }
    });
  };

  return (
    <>

      {menuData.length === 0 ? (
        <>
          <div className="text_brown text_sub text2 w500" >העגלה ריקה  <ImSad2 /><br />  שווה לבדוק את התפריט שלנו</div>
          <br />
          {/* <img src={menu} height={70} alt="logo" onClick={toMenu} /> */}
          {/* <img onClick={toMenu} src={header} className="header-logo-1" alt="logo" /> */}
          <span onClick={toMenu} className="text_title_img text3 " >מעבר לתפריט</span>
          <br /> <br />
          <center>
            <div id="tip-title" className="text_brown text_sub text2 w500" > טיפ לצוות הבר <br />
              <TextField
                type={"number"}
                value={tip}
                onChange={(e) => setTip(parseFloat(e.target.value) ? parseFloat(e.target.value) : 0)}
                variant="outlined"
                InputLabelProps={{
                  style: {
                    direction: "lrt",
                    display: "none"
                  }
                }}
                InputProps={{
                  style: {
                    direction: "rtl",
                    border: "1px solid brown",
                    borderRadius: "10px",
                    borderStyle: "none",
                    boxShadow: "none",
                    marginBottom: "10px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <CloseOutlined onClick={() => setTip(0)} />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <BiShekel />
                    </InputAdornment>
                  ),
                }}
              /><br />
              <button className="text1_5 w600 button width30vw primary_button p2 " onClick={() => { setTip(x => x + 5) }}>+ 5 ₪</button>
              <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(x => x + 10) }}>+ 10 ₪</button>
              <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(x => x + 20) }}>+ 20 ₪</button>
              <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(x => x + 50) }}>+ 50 ₪</button>
            </div>
            <br />
            {tip > 0 && <>
              {tax === "true" &&
                <a>
                  <div onClick={() => comleteTax()} id="payment-title">תשלום באמצעות מיסי הישוב  <BsCoin /></div><br /></a>
              }
              {tax === "false" && payBox !== "true" && payBox !== "false" &&

                <div className="product_price">*לא קיימים אפשרויות לתשלום, נא לוודא את פרטייך האישיים בעמוד "איזור אישי"</div>
              }
              {/* {payBox==="true" &&
                <a >
                  <div onClick={() => comletePayBox()} id="paybox-title">תשלום באמצעות אפליקציית
                    <img src={payboxlogo} width={100} alt="logo" /> </div><br /></a>
              } */}
              {payBox === "true" &&
                <a >
                  <div onClick={() => comleteBalance()} id="payment-title">תשלום מיתרת החשבון  {"("}יתרת חשבון: {balance}{")"}<BsCoin />
                  </div><br /></a>
              }
              <div style={{ textAlign: 'center', margin: '20px' }}>
                <p>
                  תשלום בכפוף ל
                  <span onClick={navigateToTerms} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                    תנאי השימוש
                  </span>
                </p>
              </div>
              {/* <PaymentDialog open={dialogOpen} onClose={handleCloseDialog} amount={tip} /> */}
              <div onClick={() => handlePayment()} id="payment-title">מעבר לתשלום <MdCreditCard />
                  </div>
              {/* 
              <Link to={{
                pathname: 'cart/pay',
                state: {
                  sum_i: sum1,
                  sum_usd: sumUSD,
                  menuData_i: menuData,
                  tip_i: tip
                }
              }}>

                <div id="payment-title">בחירת אמצעי תשלום <FaRegCreditCard /></div></Link>
              <br /> */}
            </>}<br />

          </center>
        </>
      ) : (

        <center>
          {/* 
          <div id="menu-title" >

            <button className="button_cart2"
              onClick={deleteCart}>איפוס עגלה
            </button>
          </div> */}
          <div id="page-menu">


            {menuData.map((products, i) => (
              <ProductCardCart
                id={products.id}
                uid={products.uid}
                des={products.description}
                name={products.name}
                price={products.price}
                amount={products.amount}
                picture={products.picture}
                kind={products.kind}
                discount={discount ? discount : 0}
              // change={onChange}
              />
            ))}
          </div>

          <div className="split_div text_black text_sub text1_5 w500 width75">

            <input
              type="text"
              className="__textBox"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="הערות להזמנה"
              required={false}
            />
          </div>
          <h2 className=" text2 w500 p3">סה"כ: {sum1.toFixed(2)} ₪  <br />


            <text className=" text1_5 w500">
              הוספנו על החשבון 10% דמי שירות לצוות הבר האגדי <br />לאיפוס הטיפ <Link onClick={() => setTip(0)}>לחץ כאן</Link>
            </text>
            <br />
            <text className="text text1_5 w500">
              לאחר ההזמנה תקבל : {parseInt(sum1 / 10)}
              <img src={Gcoin} className="gcoin" alt="logo" />
            </text>
            <br />
          </h2>

          <div id="tip-title" className="text_brown text_sub text2 w500">
            טיפ לצוות הבר
            <br />
            {/* <div className="__textBox row_div"> */}
            <TextField
              type={"number"}
              value={tip}
              onChange={(e) => parseFloat(e.target.value) > parseFloat(sum / 10) ? setTip(parseFloat(e.target.value)) : setTip(Math.round(sum / 10))}
              variant="outlined"
              InputLabelProps={{
                style: {
                  direction: "lrt",
                  display: "none"
                }
              }}
              InputProps={{
                style: {
                  direction: "rtl",
                  border: "1px solid brown",
                  borderRadius: "10px",
                  borderStyle: "none",
                  boxShadow: "none",
                  marginBottom: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseOutlined onClick={() => setTip(Math.round(sum / 10))} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <BiShekel />
                  </InputAdornment>
                ),
              }}
            />
            {/* <input
              type="number"
              className="__textBox"
              value={tip}
              onChange={(e) => parseInt(e.target.value) > parseInt(sum / 10) ? setTip(parseFloat(e.target.value)) : setTip(parseInt(sum / 10))}
              placeholder="Tip"
              required={false}
            />
            <button className="reset-button" onClick={() => setTip(parseInt(sum / 10))}>
              &times;
            </button> */}
            {/* </div> */}
            <br />
            <button className="text1_5 w600 button width30vw primary_button p2 " onClick={() => { setTip(Math.round((sum / 10) * 1.5)) }}> 15 %</button>
            <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(Math.round(sum / 10) * 2) }}> 20 %</button>
            <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(x => x + 5) }}>+ 5 ₪</button>
            <button className="text1_5 w600 button width30vw primary_button p2" onClick={() => { setTip(x => x + 10) }}>+ 10 ₪</button>
          </div>
          <br />
          {(account === "manager" || account === "worker" || account === "bar") &&
            <a>
              <div onClick={() => comleteCrew()} id="payment-title"> רישום על חשבון הבית  <GrUserWorker /></div><br /></a>
          }
          {tax === "true" &&
            <a >
              <div onClick={() => comleteTax()} id="payment-title">תשלום באמצעות מיסי הישוב  <BsCoin />
              </div><br /></a>
          } {payBox === "true" &&
            <a >
              <div onClick={() => comleteBalance()} id="payment-title">תשלום מיתרת החשבון  {"("}יתרת חשבון :{balance}{")"}<BsCoin />
              </div><br /></a>
          }
          <div style={{ textAlign: 'center', margin: '20px' }}>
                <p>
                  תשלום בכפוף ל
                  <span onClick={navigateToTerms} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                    תנאי השימוש
                  </span>
                </p>
              </div>
              {/* <PaymentDialog open={dialogOpen} onClose={handleCloseDialog} amount={tip} /> */}
              <div onClick={() => handlePayment()} id="payment-title">מעבר לתשלום <MdCreditCard />
                  </div>
          {balance <= -100 &&
            <Link style={{ textDecoration: 'none' }} to={{
              pathname: 'personal',
              state: {
                uid: dataUid,
                phone: phone
              }
            }}>
              <div id="payment-title" className="red">יתרת חשבון איננה מספיקה, מעבר לאיזור האישי  <BsCoin />
              </div></Link>
            // <a href="/personal">
            //   <div id="payment-title">יתרת חשבון איננה מספיקה, גש/י לאיזור האישי  <BsCoin />
            //   </div><br /></a>
          }

          {tax === "false" && payBox !== "true" && payBox !== "false" &&

            <div className="product_price">*לא קיימים אפשרויות לתשלום, נא לוודא את פרטייך האישיים בעמוד "איזור אישי"</div>
          }

          {payBox === "false" &&
            <Link style={{ textDecoration: 'none' }} to={{
              pathname: 'cart/paybox',
              state: {
                uid: dataUid,
                phone: phone
              }
            }}>
              <div id="paybox-title">רוצה לשלם באמצעות אפליקציית
                <img src={payboxlogo} width={100} alt="logo" /> ? </div></Link>}

          {/* <Link to={{
            pathname: 'cart/pay',
            state: {
              sum_i: sum1,
              sum_usd: sumUSD,
              menuData_i: menuData,
              tip_i: tip
            }
          }}>

            <div id="payment-title">בחירת אמצעי תשלום <FaRegCreditCard /></div></Link> */}

          <br />
        </center>
      )}
    </>
  );



}
export default CartView