
import { useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import { onValue, ref } from "firebase/database";
import { database } from "../../../firebase";
import InventoryStorageControl from "./inventoryStorageControl";
import InventoryBarControl from "./inventoryBarControl";


const StorageBarCheck = (props) => {
  // const kind = props.location.state.kind
  // console.log("***********StorageEdit******",props.location.state.kind)
  const [search, setSearch] = useState("");
  const [loading1, setLoading1] = useState(true);
  const [ok, setOk] = useState(false);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const query = ref(database, "Storage");
    return onValue(query, (snapshot) => {
      setMenuData([]);
      const data = snapshot.val();
      var logs = []
      if (snapshot.exists()) {
        Object.values(data).sort((a, b) => parseInt(a.location) - parseInt(b.location)).map((project) => {
          const data1 = {
            amount: project.amount,
            amountBar: project.amountBar,
            amountBarMax: project.amountBarMax,
            amountMax: project.amountMax,
            location: project.location,
            name: project.name,
            price: project.price,
            priceTax: project.priceTax,
            uid: project.uid,
            view: true,
          };
          if (parseInt(project.amountBar) > 0)
            logs.push(data1);
        });
      }
      setMenuData(logs);
      setLoading1(false);
    });
  }, []);

  const handleChange = (i) => {
    menuData[i].view = false;

    const x = menuData.map(item => {
      return item.view
    })
    var y = x.every(element => element === false)
    console.log(y)
    if (y == true) {
      props.ok()
    }
  };

  return (
    <>
      <br />
      {loading1 === true || menuData.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#a57733"} />
        </center>
      ) : (<>

        <div className="storage_layout">
          {menuData.map((products, i) => (
            products.name.includes(search) &&
            <>
            {props.kind === "bar" &&
              <InventoryBarControl
                location={products.location}
                key={products.uid}
                id={products.uid}
                name={products.name}
                amount={products.amount}
                amountBar={products.amountBar}
                view={products.view}
                onchange={handleChange}
                index={i}
              />}
               {props.kind === "storage" &&
              <InventoryStorageControl
                location={products.location}
                key={products.uid}
                id={products.uid}
                name={products.name}
                amount={products.amount}
                amountMax={products.amountMax}
                amountBar={products.amountBar}
                amountBarMax={products.amountBarMax}
                view={products.view}
                index={i}
              />}
            </>
          ))}
        </div>
      </>
      )}
    </>
  );
}
export default StorageBarCheck