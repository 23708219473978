


import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { deletaFromRealTimeDb } from "../../../../firebase";

const TicketCardManagment = (props) => {
  console.log(props)
  const history = useHistory();
  const [click, setClick] = useState(false);

  const remove = async () => {
    deletaFromRealTimeDb(props.uid, "Tickets")
    props.onChange()
  };

  return (
    <>
      <div id={props.status !== 0 ? ("product-master-new-red") : ("product-master-new")} className={click ? 'fade_out' : ''}
        onAnimationEnd={() => setClick(false)}
      >
        <div className={props.status === 0 ? ("") : ("backgrond")}>

          <span className="product_p1 text_black text2 w600">{props.EName}</span>
          <br />
          <span className="product_p1 text_black text1_5 w600">{String(props.Edate).split("-").reverse().join("/")}</span>
          <div className="split_div">
            <div className="right_div p2 height30">
              <span className="text_black text2 w700">{props.name}</span>
              <span className="text_black text1_5 w700">{props.email}</span>
              <span className="text_red black_shadow text2 w700">{String(props.note)}</span>
              <button disabled={props.name === ""} className="product_button_new" onClick={remove} >מחיקת כרטיס</button>

            </div>
            <div className="right_div p1">
              <span className="text_black text2 w700">סה"כ {props.total} ₪</span>
              <span className="text_black text1_5 w700">תשלום: {props.payment} </span>
              {props.ETicket &&
                <>
                  <span className="text_black text2 w700">כרטיסים</span>
                  {props.ETicket.map(x => {
                    return (
                      <>
                        {x.amount != 0 &&
                          <span className="text_black text1_5 w600">{x.name} : {x.amount}</span>}
                      </>
                    )
                  })}
                </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketCardManagment;
