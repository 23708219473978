import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, database, db, updateUserAccount, updateUserAccountBalance, updateUserDiscount, updateUserKind, updateUserPayBox, updateUserTax, uploadPaymentApprove } from "../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import OrderCard from "./orderCard";
import UserCard from "./userCard";
import { onValue, ref } from "firebase/database";
import MUIDataTable from "mui-datatables";
import { Button, DialogContent, TableCell, TableRow } from "@mui/material";
import { Block, Check, Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";


const AllUsersTable = (props) => {

  const [loading1, setLoading1] = useState(true);
  const [comingOrders, setComingOrders] = useState([]);
  const [row, setRow] = useState();
  const [user, loading, error] = useAuthState(auth);

  // const [columns, setColumns] = useState([]);
  const columns = [
    {
      label: "סוג חשבון",
      name: "account",
      options: {
        filter: false,
      }
    },
    {
      label: "שם",
      name: "name",
      options: {
        filter: false,
      }
    },
    {
      label: "טלפון",
      name: "phone",
      options: {
        filter: false,
      }
    },
    {
      label: "כתובת",
      name: "adress",
      options: {
        filter: false,
      }
    },
    {
      label: "אימייל",
      name: "email",
      options: {
        filter: false,
      }
    },
    {
      label: "יתרת חשבון",
      name: "accountBalance",
      options: {
        filter: false,
        customBodyRender: (value) => {
          console.log(value)
          return (value ? value.toFixed(2) : 0)
        },
      }
    },
    {
      label: "תשלום יתרה",
      name: "payBox",
      options: {
        filter: false,
        customBodyRender: (value) => {
          console.log(value)
          return (value == "true" ? <Check true /> : value == "false" ? <Close false /> : <Block block />)
        },
      }
    },
    {
      label: "תשלום מיסים",
      name: "tax",
      options: {
        filter: false,
        customBodyRender: (value) => {
          console.log(value)
          return (value == "true" ? <Check true /> : value == "false" ? <Close false /> : <Block block />)
        },
      }
    },
    {
      label: "uid",
      name: "uid",
      options: {
        filter: false,
        display: false,
        download: false,
        print: false,
      }
    },
    {
      label: "הנחה",
      name: "discount",
      options: {
        filter: true,
      }
    },
    {
      label: "סוג חשבון",
      name: "kind",
      options: {
        filter: true,
      }
    },
  ]

  useEffect(() => {
    console.log("******useEffect-allUsers************")
    const query = ref(database, "Account");
    return onValue(query, (snapshot) => {
      setComingOrders([])
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          setComingOrders((projects) => [...projects, project]);
        });
        console.log(comingOrders)
        setLoading1(false);
      }
    });
  }, []);

  const options = {
    setRowProps: (row, dataIndex, rowIndex) => {
      // החשבון בחוב
      if (row[5] < 0)
        return {
          //red color
          style: { backgroundColor: '#ffe6e6' },
        };
      console.log(row[9])
      //תשלום מיסים ואין הנחה
      if (row[7].props.true && row[9] == 0)
        return {
          //red color
          style: { backgroundColor: '#ffe6e6' },
        };

      //תשלום מיסים ויש הנחה
      if (row[7].props.true && row[9] > 0)
        return {
          //green color
          style: { backgroundColor: '#ccffe6' },
        };
      // else
      // return {
      //   //yellow color
      //   style: { backgroundColor: '#ffffe6' },
      // };
    },
    filter: true,
    filterType: "multiselect",
    // responsive: "standard",
    // expandableRows:true,
    expandableRowsOnClick: true,
    responsive: "simple",
    tableBodyHeight: "600px",
    selectableRows: "none",
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData)
      return (
        <TableRow>
          <TableCell classname="space" colSpan={rowData.length}>
            {(rowData[5] ? rowData[5] : 0 < 0 && user) || props.manager &&
              <button className="button" onClick={() => handleClickOpen(rowData)}>
                הכנס סכום ליתרה אישית
              </button>}
            <span className="product_price" >סוג חשבון: </span>
            <select
              onChange={(e) => updateUserKind(rowData[8], e.target.value)}
              className="__text"
              value={rowData[10] ? rowData[10] : ""}
            >
              <option value={1}>תושב/חבר</option>
              <option value={0}>אורח</option>
              <option value="">ללא</option>
            </select>
            <span className="product_price" >תשלום מיסים: </span>
            <select
              onChange={(e) => updateUserTax(rowData[8], e.target.value)}
              className="__text"
              value={rowData[7].props.true ? "true" : rowData[7].props.false ? "false" : "block"}
            >
              <option value="true">כן</option>
              <option value="false">לא</option>
              <option value="block">חסום</option>


            </select>
            <span className="product_price" >תשלום פייבוקס: </span>
            <select
              onChange={(e) => updateUserPayBox(rowData[8], e.target.value)}
              className="__text"
              value={rowData[6].props.true ? "true" : rowData[6].props.false ? "false" : "block"}
            >
              <option value="true">כן</option>
              <option value="false">לא</option>
              <option value="block">חסום</option>

            </select>
            {props.manager && <>

              <span className="product_price" >סוג חשבון: </span>

              <select
                onChange={(e) => updateUserAccount(rowData[8], e.target.value)}
                className="__text"
                value={rowData[0]}
              >
                <option value="">תקלה</option>
                <option value="User">רגיל</option>
                <option value="manager">מנהל</option>
                <option value="office">משרד</option>
                <option value="bar">בר</option>
                <option value="worker">מלצר</option>
                <option value="event">אירוע</option>
              </select>

              <span className="product_price" >הנחה: </span>
              <select
                onChange={(e) => updateUserDiscount(rowData[8], e.target.value)}
                className="__text"
                value={rowData[9]}
              >
                <option value={""}>כלום</option>
                <option value={0}>0%</option>
                <option value={20}>הנחה 20%</option>
                <option value={40}>הנחה 40%</option>
              </select>
            </>}
            {props.phonesCustomer == true && <>

              <span className="text text1 w500">טלפונים נוספים: </span>
              {rowData[7] ? (<>{
                rowData[7].map(x => {
                  return (
                    <><br /><span className="text text1 w500">{x.name + " : " + x.phone}</span></>)
                })}
                <br /></>
              ) : (<><span className="text text1 w500"> אין טלפונים נוספים</span><br /></>)}
              {/* <span className="text text1_5 w500">{String(rowData[7]).join(",")}</span> */}
            </>}
            {props.phonesSupplier == true && <>

              <span className="text text1 w500">טלפונים נוספים: </span>
              {rowData[7] ? (<>{
                rowData[7].map(x => {
                  return (
                    <><br /><span className="text text1 w500">{x.name + " : " + x.phone}</span></>)
                })}
                <br /></>
              ) : (<><span className="text text1 w500"> אין טלפונים נוספים</span><br /></>)}
              {/* <span className="text text1_5 w500">{String(rowData[7]).join(",")}</span> */}
            </>}
            {props.government == true && <>
              {typeof rowData[4] == "object" ? (<>
                <br />{console.log(rowData[4])}
                {Object.values(rowData[4]).forEach(x => {
                  //console.log(x)
                })}
                <br /></>
              ) : (<><span className="text text1 w500">פרטי רכב: לא נמצא</span><br /></>)}
              {/* <span className="text text1_5 w500">{String(rowData[7]).join(",")}</span> */}
            </>}
            {props.edit &&
              <button className="button" onClick={() => props.edit(rowData[0])}> עריכה</button>}

            {props.delete &&
              <button className="button red" onClick={() => props.delete(rowData[0])}> מחיקת שורה</button>}

            {props.invoice &&
              <button className="button" onClick={"() => handleClickOpen1(rowData)"}> העתק חשבונית</button>}

            {/* Custom expandable row option. Data: {JSON.stringify(rowData)} */}
          </TableCell>
        </TableRow>
      )
    },
    onDownload: (buildHead, buildBody, columns, data) => {

      // buildHead = () => {
      //   return columns
      // }
      // buildBody = () => {
      //   return data;
      // }

      return "\uFEFF" + buildHead(columns) + buildBody(data)

    },
    rowsPerPage: [100],
    rowsPerPageOptions: [10, 50, 100, 200],
    jumpToPage: true,
    textLabels: {
      pagination: {
        next: "הבא >",
        previous: "< הקודם",
        rowsPerPage: "פריטים בעמוד",
        displayRows: "מתוך",
        jumpToPage: "עמוד"
      },
      toolbar: {
        search: "חיפוש",
        downloadCsv: "הורדה לקובץ",
        print: "הדפסה",
        viewColumns: "בחירת עמודות",
        filterTable: "סינון",
      },
      filter: {
        all: "הכל",
        title: "פילטר",
        reset: "איפוס",
      },
    },
    onChangePage(currentPage) {
      //console.log({ currentPage });
    },
    onChangeRowsPerPage(numberOfRows) {
      //console.log({ numberOfRows });
    }
  };

  const [newamountToCheck, setnewBalance] = useState(0);
  const [open, setOpen] = useState(false);

  const onApproval = () => {
    console.log(row[8])
    handleClose()
    updateUserAccountBalance(row[8], -1 * newamountToCheck).then(
      uploadPaymentApprove(row[8], row[1], row[4], newamountToCheck, user)
    )
  }

  const handleClickOpen = (row) => {
    setRow(row)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (<>
    <MUIDataTable
      title={
        <span className="text text2 w500">לקוחות</span>
      }
      data={comingOrders}
      columns={columns}
      options={options}
    />
    {/* <Dialog
      open={open1}
      onClose={handleClose1}
      aria-labelledby="form-dialog-title"
    >
      <br />
      {open1 &&
      // console.log(row)}

    </Dialog> */}
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <br />
      <div className='text_rtl text1_5 w300'>

        <div className='text_center text2 w300'>איזה סכום תרצה לאשר? </div>

      </div>
      <DialogContent>
        <div className='text_center'>
          <input
            type="number"
            className="__textBoxamount"
            value={newamountToCheck}
            onChange={(e) => setnewBalance(parseInt(e.target.value))}
            placeholder={newamountToCheck}
          /> ₪
        </div>
      </DialogContent>

      <Button
        className='text_center'
        style={{ marginLeft: "10px", marginRight: "10px" }}
        variant="outlined"
        onClick={onApproval}
        color="primary"
      >
        אישור
      </Button>
    </Dialog>
  </>

  );
};


export default AllUsersTable