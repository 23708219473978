import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { database, storage, uploadProduct } from '../../../../firebase';
import { Label } from 'semantic-ui-react';
import Select from "react-select";
import Navbar from '../../../sidebar/sidebar';
import Choices from './Choices';
import { useEffect } from 'react';
import { onValue, ref as ref1 } from "firebase/database";
import InventoryChoices from './InventoryChoices';


function NewProductForm() {

  const [pname, setPName] = useState("");
  const [pcat, setPCat] = useState("");
  const [percent, setPercent] = useState(0);
  const [pimg, setPimg] = useState("");
  const [pdes, setPdes] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState("");
  const [choices, setChoices] = useState([]);
  const [storageX, setStorageX] = useState([]);
  const [items, setItems] = useState([]);
  const [primary, setPrimary] = useState("");
  const history = useHistory();


  const [categories, setCategories] = useState([]);




  useEffect(() => {
    const query = ref1(database, "Category");
    return onValue(query, (snapshot) => {
      setCategories([]);
      const data = snapshot.val();
      const logs = [];
      console.log("******Category************")
      console.log(snapshot)
      console.log(data)

      if (snapshot.exists()) {

        Object.values(data).map((project) => {
          console.log(project)
          const data1 = {

            value: project.uid,
            label: project.title,

          };
          logs.push(data1);

          // if (String(project.category).includes(id))

        });
        setCategories(logs);

      }

    });
  }, []);

  useEffect(() => {
    const query = ref1(database, "Storage");
    return onValue(query, (snapshot) => {
      setStorageX([]);
      const data = snapshot.val();
      const logs = [];

      if (snapshot.exists()) {

        Object.values(data).map((project) => {
          const data1 = {

            value: project.uid,
            label: project.name,

          };
          logs.push(data1);

          // if (String(project.category).includes(id))

        });

        console.log("Storage", logs)
        setStorageX(logs);

      }

    });
  }, []);


  const handleCategoryChange = (newValue1) => {
    setPCat(newValue1.value);
    console.log(newValue1.label);
  };

  const handleImg = (e) => {
    const file = e.target.files[0];
    uploadImg(file);
  };

  const uploadImg = (file) => {
    if (!file) {
      alert("Empty Image");
    } else {
      const storageRef = ref(storage, "/menu_images/" + file.name);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on("state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(upload.snapshot.ref).then((url) => {
            setPimg(url);
          });
        });
    }
  };



  // function to update state of name with
  // value enter by user in form

  const managment = () => {
    history.push("/xmanagment")
  }

  // below function will be called when user
  // click on submit button .
  const Submit = async (e) => {
    e.preventDefault();
    if (
      pname === "" ||
      price === "" ||
      pdes === "" ||
      pcat === "" ||
      pimg === "" ||
      discount === "" ||
      amount === ""
    ) alert("מידע חסר");
    else {
      uploadProduct(pimg, pcat, pname, pdes, price, amount, choices, primary, items, discount)
    }
  };

  const handleSubmitarray = (choices, answer) => e => {
    e.preventDefault();
    setChoices(choices)
    setPrimary(answer)
    console.log(choices, answer);
  };

  const handleSubmitarrayItems = (choices) => e => {
    e.preventDefault();
    setItems(choices)
    console.log(choices);
  };


  return (
    <>

        <div id={"page-wrap"} >
          <div id="page">
            <h3> הוספת מוצר </h3>
            <Label>בחירת תמונה</Label>
            <input
              className="__textBox"
              type="file"
              placeholder="Enter Product Image"
              name="pimg"
              defaultValue={pimg}
              onChange={handleImg}
            /><br />העלאת קובץ: {percent}%

            <Label>תצוגת תמונה</Label>
            <img src={pimg} width={300} />

            <Label>בחירת קטגוריה</Label>
            <Select
              value={pcat}
              onChange={handleCategoryChange}
              options={categories}
              placeholder="הקלד או בחר קטגוריה"
            />
            <br />
            <Label>שם מוצר</Label>
            <input
              type="text"
              className="__textBox"
              value={pname}
              onChange={(e) => setPName(e.target.value)}
              placeholder="שם מוצר"
            />
            <br />
            <Label>פירוט</Label>
            <textarea
              placeholder="Product Description"

              className="__textBox"
              value={pdes}
              onChange={(e) => setPdes(e.target.value)}
            /><br />
            <Label>האם זה מבצע?</Label>
            <input
              type="number"
              placeholder="מה מספר הפריטים שיש לבחור"
              className="__textBox"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            /><br />
            {choices.length > 0 &&
              <Label>סך כל האפשרויות לבחירה בפריט זה: {choices.length}</Label>
            }
            <Choices
              choices={[]}
              answer={0}
              handleSubmit={handleSubmitarray}
            />
            <br />
            {items.length !== undefined && items.length > 0 &&
              <Label>סך כל המרכיבים לבחירה בפריט זה: {items.length}</Label>
            }

            <InventoryChoices
              choices={items}
              handleSubmit={handleSubmitarrayItems}
              items={storageX}
            />

            <Label>מחיר</Label>
            <input
              type="number"
              placeholder="Enter Price"

              className="__textBox"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            /><br />

            <Label>כמות במלאי</Label>
            <input
              type="number"
              placeholder="Enter amount"

              className="__textBox"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <br />
            <input className='button' type="Button" value="הוסף מוצר" onClick={Submit} />
            <br />

            <input className='button' type="Button" value="חזור לתפריט מנהל" onClick={managment} />
          </div>
        </div>
    </>
  );
}

export default NewProductForm
