

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { memo, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiBasket } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { Input, Label } from "semantic-ui-react";
import { auth, storage, updateCategory } from "../../../../firebase";
import MenuFood from "../../../menu/components/menuFood";

const CategoryCardEdit = (props) => {
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [click, setClick] = useState(false);

  const [title, setTitle] = useState(props.title);
  const [pimg, setPimg] = useState(props.img);
  const [sub, setSub] = useState(props.subtitle);
  const [location, setLocation] = useState(props.location);


  useEffect(() => {
    if (loading) return;
  }, [user, loading]);


  const watch = () => {
    setClick(!click)
  }
  const update = () => {
    updateCategory(props.id, title, pimg, sub, location)
  }
  const handleImg = (e) => {
    const file = e.target.files[0];
    uploadImg(file);
  };

  const uploadImg = (file) => {
    if (!file) {
      alert.error("Empty Image");
    } else {
      const storageRef = ref(storage, "/category_images/" + file.name);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on("state_changed", (snapshot) => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setPimg(url);

        });
      });
    }
  };

  return (
    <>
      <div id="product-master"
      >
        <div>
          <Input
            type="text"
            className="__textBox"
            value={location}
            label={{ content: 'מיקום' }}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="מיקום"
          />
          <br />
          <img className="product_pic" src={pimg} />


          <Input
            label={{ content: 'בחירת תמונת נושא אחרת' }}
            className="__textBox"
            type="file"
            placeholder="Enter Product Image"
            name="pimg"
            onChange={handleImg}
          /><br />
          <br />

          <Input
            type="text"
            className="__textBox"
            value={title}
            label={{ content: 'כותרת' }}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="כותרת"
          />
          <Input
            type="text"
            className="__textBox"
            value={sub}
            label={{ content: 'כותרת משנית' }}
            onChange={(e) => setSub(e.target.value)}
            placeholder="כותרת משנית"
          />
          <button className="product_button" onClick={update}>עדכון כותרת </button>

          <button className="product_button" onClick={watch}>צפייה בפריטים תחת נושא זה </button>


        </div>

      </div>
      {click &&
        <div className="menu-layout-color">

          <MenuFood id={props.id} close={watch} />

        </div>}


    </>
  );
};

export default memo(CategoryCardEdit);
