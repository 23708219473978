import React from "react";

export const formatChoices = choices => {
    return choices;
};

class ListChoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: props.choices
        };
        console.log(" constructor(props",props.choices)
    }

    handleChoiceChange = index => e => {
        const newChoices = this.state.choices.map((choice, sidx) => {
            if (index !== sidx) return choice;
            console.log(e.target.value)
            return { ...choice, name: e.target.value };
        });
        this.setState({ choices: newChoices });
    };

    handleRemoveChoice = index => () => {
        this.setState({
            choices: this.state.choices.filter((choice, sidx) => index !== sidx)
        });
    };

    handleAddChoice = () => {
        this.setState({
            choices: this.state.choices.concat([{ name: ""}])
        });
    };

    render() {
        const { handleSubmit } = this.props;
        const { choices } = this.state;

        return (
            <form
                onSubmit={handleSubmit(
                    formatChoices(this.state.choices)
                )}
            >
                {choices.map((choice, index) => (
                    <div key={["choice", index].join("__")}>

                        <input
                            type="text"
                            className="__textBoxSmall"
                            value={choice.name}
                            onChange={this.handleChoiceChange(index)}
                            onMouseLeave={handleSubmit(
                                formatChoices(this.state.choices)
                            )}
                            placeholder="הקלד משימה"
                        />
                        <button
                            className="button"
                            onClick={this.handleRemoveChoice(index)}
                            type="submit" >
                            מחיקה
                        </button>
                    </div>
                ))}
                <button className="button" type="button" onClick={this.handleAddChoice}>
                    הוסף משימה
                </button>
                <button className="button_b" type="submit">רענן</button>
            </form>
        );
    }
}

export default ListChoices;
