

import {  useState } from "react";
import { BiBasket } from "react-icons/bi";
import { addToCart, auth } from "../../../../firebase";

const ProductCard = (props) => {

  const [click, setClick] = useState(false);
  const [details, setDetails] = useState("");

  
  const update = () => {
      addToCart(props.id,props.name, 1 * props.price, 1, props.uid, props.email, details,props.img,"Products", props.id)
  }

  return (
    <>
      <div id="product-master" className={click ? 'fade' : ''}
        onAnimationEnd={() => setClick(false)}
      >
        <img className="product_pic" src={props.img}  />
        <br />
        <span className="product_name">{props.name}</span>
        <br />
        <span className="product_price" >מחיר: {props.price} ₪</span>
        <div id="title1">הערות:</div>
            <input
              type="text"
              className="__textBox"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              placeholder="הערות"
            />
        <div className="btns">
          <>
            <button disabled={click} className="product_button" onClick={update} >הוסף <BiBasket /></button>
          </>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
