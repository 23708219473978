import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

export const FormMessage = ({ onSubmit }) => {

  const [type, setType] = useState("message");
  return (

    <form onSubmit={onSubmit} className="message_modal">

      <label className="__text" htmlFor="messagetitle">נושא:</label>
      <TextField
        className="__textBox"
        id="messagetitle"
        placeholder="נושא"
      />
      <br />
      <br />
      <label className="__text" htmlFor="messagetext">הודעה:</label>
      <br />
      <textarea
        type="text"
        className="__textBox"
        id="messagetext"
        placeholder="הודעה"
      />

      <div className="form-group">
        <button className="button" type="submit">
          שלח
        </button>
      </div>
    </form>
  );
};
export default FormMessage;
