import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, createTheme, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { collection, getDocs } from "firebase/firestore";
import { CreateMessage, database, db, updateTip0, updateTipBalance } from "../../firebase";
import ComingMessages from "./components/comingMessages";
import Container from "../aboutUs/components/container";
import { useEffect } from 'react';
import WorkerFormEdit from './components/work/barCheckListEdit';
import ManageEvents from './mangmentEvents';
import { onValue, ref } from 'firebase/database';
import BarTips from '../bar/barTips';
import LuckListManage from '../weelOfLuck/managment/editWheelOfLuck';
import ManagmentLuckWheel from './managmentWheelLuck';
import ComingEventOrders from './components/comingEventOrders';

function ManagmentActions() {

  // const fetchUsers = async () => {

  //   try {
  //     const q = collection(db, "Users");
  //     const doc = await getDocs(q);
  //     const logs = [];

  //     doc.forEach(data => {

  //       const data1 = {

  //         value: data.data().uid,
  //         label: data.data().name,

  //       };
  //       logs.push(data1);

  //     });
  //     setUsers(logs);

  //   } catch (err) {
  //     console.log(err);
  //     alert(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  // useEffect(() => {
  //   console.log("******useEffect-managmentView************")
  //   const query = ref(database, "Account");
  //   return onValue(query, (snapshot) => {
  //     setUsers([])
  //     const data = snapshot.val();
  //     const logs = [];
  //     if (snapshot.exists()) {
  //       Object.keys(data).map((key, i) => {
  //         const data1 = {
  //           value: Object.values(data)[i].uid,
  //           label: Object.values(data)[i].name,
  //         };
  //         logs.push(data1);
  //         console.log(logs)
  //       });
  //       setUsers(logs);
  //     }
  //   });
  // }, []);





  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: { main: "#a57733" },
      secondary: { main: "#f8e7ce" },
    },
  });
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const newProduct = () => {
    history.push("/xmanagment/newproduct")
  }
  const newItem = () => {
    history.push("/xmanagment/newitem")
  }
  const newCategory = () => {
    history.push("/xmanagment/newcategory")
  }
  const newEvent = () => {
    history.push("/xmanagment/newevent/")
  }


  const menuEdit = () => {
    history.push("/xmanagment/menuedit")
  }

  const storageEdit = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'all' }
    });
  }
  const storageEdit1 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'bar' }
    });
  }
  const storageEdit2 = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storage' }
    });
  }
  const storageToBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storagetobar' }
    });
  }
  const storageAdd = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageadd' }
    });
  }
  const storageviewBar = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'barView' }
    });
  }
  const storageView = () => {
    history.push({
      pathname: '/xmanagment/storageedit',
      state: { kind: 'storageView' }
    });
  }
  const categoryEdit = () => {
    history.push("/xmanagment/categoryedit")
  }


  const barFunction = (type) => {
    history.push("/worker/" + type)
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "00px" }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="פעולות"{...a11yProps(0)} />
          <Tab label="מחסן ומלאי"{...a11yProps(1)} />
          <Tab label="גלגל המזל" {...a11yProps(2)} />

        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <div id="product-title">פעולות חדשות</div>
        <button className="button_sp"
          onClick={() => barFunction("openbar")}> פתיחת בר
        </button>
        <button className="button_sp"
          onClick={() => barFunction("closebar")}> סגירת בר
        </button>

        <div className="split_div">
          <div className="right_div2_3">
            <button className="button_sp"
              onClick={() => updateTip0()}>איפוס טיפים
            </button>
          </div>
          <div className="right_div_3_right">
            <BarTips admin="null" />
          </div>
        </div>

        <div id="product-title">תפריט ומוצרים</div>
        <button className="button_sp"
          onClick={newCategory}> הוסף קטגוריה
        </button>
        <button className="button_sp"
          onClick={categoryEdit}> עריכת קטגוריות
        </button>
        <br /> <br />

        <button className="button_sp"
          onClick={newProduct}> הוסף מוצר חדש למערכת
        </button>

        <button className="button_sp"
          onClick={menuEdit}> עריכת תפריט
        </button>
        <br /> <br />
        <br /><br />
        <div id="product-title">הזמנות אירועים</div>
        <ComingEventOrders />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div id="product-title">מחסן ומלאי</div>

        <button className="button_sp"
          onClick={newItem}> הוסף פריט למלאי
        </button>
        <button className="button_sp"
          onClick={storageEdit}> עריכת מחסן
        </button>

        <br /> <br />
        <button className="button_sp"
          onClick={storageEdit1}> עריכת מלאי בר
        </button>
        <button className="button_sp"
          onClick={storageEdit2}> עריכת מלאי מחסן
        </button>
        <br /> <br />
        <button className="button_sp"
          onClick={storageToBar}> הוצאה מהמחסן
        </button>
        <button className="button_sp"
          onClick={storageAdd}> הוספה למחסן
        </button>
        <br /> <br />
        <button className="button_sp"
          onClick={storageviewBar}> חוסרים בר
        </button>
        <button className="button_sp"
          onClick={storageView}> חוסרים מחסן
        </button>
        <br /> <br />
        <br /><br />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className='split_div'>
          <div className='left_div'>
            <ManagmentLuckWheel />
          </div>
          {/* <div className='right_div'>
            <LuckListManage type={"close"} />
          </div> */}
        </div>
      </TabPanel>
    </ThemeProvider>
  );
}

export default ManagmentActions
