import { useEffect, useState } from "react";
import { database } from "../../../../firebase";
import RingLoader from "react-spinners/RingLoader";
import { onValue, ref } from "firebase/database";
import { Table } from "semantic-ui-react";
import BalanceRow from "./balanceRow";


const AllUsersBalance = () => {

  const [loading1, setLoading1] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [changed, setChanged] = useState(true);
  const onChange = () => { setChanged(!changed) }


  useEffect(() => {
    console.log("******useEffect-AllUsersBalance************")
    const query = ref(database, "Account");
    return onValue(query, (snapshot) => {
      setAccounts([])
      const data = snapshot.val();
      const logs = [];
      if (snapshot.exists()) {
        Object.values(data).sort((a, b) => parseInt(a.accountBalance) - parseInt(b.accountBalance)).map((user, i,) => {
          setAccounts((projects) => [...projects, { user, key: Object.keys(data)[i] }]);
          // console.log(key, i)
          // console.log(Object.keys(data)[i])
        });
        // Object.keys(data).map((key, i) => {
        //   const data1 = {
        //     key: key,
        //     name: Object.values(data)[i].name,
        //     phone: Object.values(data)[i].phone,
        //     email: Object.values(data)[i].email,
        //     accountBalance: Object.values(data)[i].accountBalance
        //   };
        //   // if (accountBalance !== 0 && accountBalance !== undefined)
        //   logs.push(data1);
        //   // console.log(logs)
        // });
        // setAccounts(logs);
        setLoading1(false);
      }
    }, {
      onlyOnce: true
    });
  }, [changed]);




  return (
    <>

      {loading1 === true || accounts.length === 0 ? (
        <center id="loading" >
          <RingLoader id="loading" color={"#914d03"} />
        </center>
      ) : (
        <div id="page-menu">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>אימייל</Table.HeaderCell>
                <Table.HeaderCell>שם</Table.HeaderCell>
                <Table.HeaderCell>טלפון</Table.HeaderCell>
                <Table.HeaderCell>סכום</Table.HeaderCell>
                <Table.HeaderCell>פעולות</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {accounts.map((account) => (
                <BalanceRow row={account} onChange={onChange} />
              ))}

            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );



}
export default AllUsersBalance